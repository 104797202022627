import { FC, useState } from "react";
import Box from "@mui/material/Box";
import { HomeworkTemplate } from "common/dist/types/Homework";
import { useHomeworks } from "../../../../hooks/useHomeworks";
import {
  updateTemplate,
  deleteTemplate,
  sendTemplate,
} from "../../../../services/homeworks";
import Icon from "../../../../components/Icon";
import Button from "../../../../components/Button";
import HomeworkForm, {
  HomeworkEdit,
} from "../../../../components/HomeworkForm";
import Snackbar, { SnackbarStatus } from "../../../../components/Snackbar";
import { checkTemplateExists } from "./utils";

interface HomeworkTemplateEditViewProps {
  template: HomeworkTemplate;
  assigneeId: string;
  close: () => void;
}

export const HomeworkTemplateEditView: FC<HomeworkTemplateEditViewProps> = ({
  template,
  assigneeId,
  close,
}) => {
  const [snackStatus, setSnackStatus] = useState<SnackbarStatus | undefined>(
    undefined
  );

  const { homeworkTemplates } = useHomeworks();

  const initHomework: HomeworkEdit = {
    title: template.title ?? "",
    description: template.description,
    images: template.images ?? [],
    labels: template.labels ?? [],
    public: template.public,
  };

  const handleUpdateTemplate = async (updatedTemplate: HomeworkEdit) => {
    const templateExists = checkTemplateExists(
      homeworkTemplates ?? [],
      updatedTemplate.title ?? "",
      updatedTemplate.description,
      updatedTemplate.images ?? [],
      updatedTemplate.labels ?? []
    );

    if (templateExists) {
      alert("Identical template already exists.");
      return;
    }

    const res = await updateTemplate(
      template.id,
      updatedTemplate.title ?? "",
      updatedTemplate.description,
      updatedTemplate.public ?? false,
      updatedTemplate.images ?? [],
      updatedTemplate.labels ?? []
    );
    setSnackStatus(res);
  };

  const handleSendTemplate = async () => {
    const res = await sendTemplate(template.id, assigneeId);
    if (res.severity === "success") {
      close();
      return;
    }
    setSnackStatus(res);
  };

  const handleDeleteTemplate = async (images: string[]) => {
    const res = await deleteTemplate(template.id, images);
    if (res.severity === "success") {
      close();
      return;
    }
    setSnackStatus(res);
  };

  return (
    <>
      <Box>
        <Button
          label="back"
          variant="tertiary"
          onClick={close}
          leftIcon={<Icon variant="arrowLeft" />}
        />
      </Box>
      <h2>Homework template</h2>
      <HomeworkForm
        initHomework={initHomework}
        onSend={handleSendTemplate}
        onDelete={handleDeleteTemplate}
        onUpdate={handleUpdateTemplate}
        type="homework template"
      />
      <Snackbar
        message={snackStatus?.message}
        setVisibility={() => setSnackStatus(undefined)}
        duration={6000}
        custom={true}
        severity={snackStatus?.severity}
      />
    </>
  );
};
