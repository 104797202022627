import { useContext } from "react";
import { AuthContext } from "../stores/AuthContext";

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined || context.userAuth === null) {
    throw Error("Attempted to use auth data before initialization");
  }
  if (!context || context.userAuth === undefined) {
    throw Error("User need to be signed in to use auth");
  }
  const userAuth = context.userAuth;
  return { userAuth: userAuth, setUserAuth: context.setUserAuth };
};
