import {
  collection,
  query,
  where,
  onSnapshot,
  Query,
  orderBy,
  doc,
  setDoc,
  addDoc,
  CollectionReference,
  updateDoc,
  QuerySnapshot,
  runTransaction,
} from "firebase/firestore";
import {
  deleteObject,
  ref,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { isPresent } from "common/dist/feat/util";
import { auth, db, functionsClient, storage } from "../../features/firebase";
import {
  DbHomework,
  Homework,
  HomeworkDraft,
  HomeworkTemplate,
  DbHomeworkTemplate,
} from "common/dist/types/Homework";
import { getCurrentUser } from "../auth";
import { SnackSeverity } from "../../components/Snackbar";
import { INTERNAL_ERROR } from "../constants";

export async function uploadImages(imageFiles: File[]) {
  const uid = auth.currentUser!.uid;
  const urls = await imageFiles.reduce<Promise<string[]>>(
    (acc, image) =>
      acc.then((acc) => uploadImage(uid, image).then((url) => acc.concat(url))),
    Promise.resolve([])
  );

  return urls.filter((url) => url) as string[];
}

async function uploadImage(uid: string, imageFile: File) {
  const imageId = new Date().getTime();
  const uploadTask = await uploadBytesResumable(
    ref(storage, `therapists/${uid}/homework/${imageId}`),
    imageFile
  );
  const downloadURL = await getDownloadURL(uploadTask.ref);

  return downloadURL;
}

export async function deleteImage(downloadURL: string) {
  await deleteObject(ref(storage, downloadURL));
}

export const subscribeToHomework = (
  onUpdate: (homework: Homework[]) => void,
  assigneeId: string
) => {
  const q = query(
    collection(db, "homework"),
    where("therapist", "==", auth.currentUser?.uid),
    orderBy("timestamp", "desc")
  ) as Query<DbHomework>;

  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const homework = querySnapshot.docs
        .map((doc) => {
          const data = doc.data();
          return {
            ...data,
            timestamp: data.timestamp.toDate(),
            lastSeenByPatient: data.lastSeenByPatient?.toDate(),
            lastSeenByTherapist: data.lastSeenByTherapist?.toDate(),
            id: doc.id,
            answers: data.answers.map((a) => ({
              ...a,
              timestamp: a.timestamp.toDate(),
            })),
          };
        })
        .filter((h) => isPresent(h) && h.patient === assigneeId);
      onUpdate(homework);
    },
    (err) => console.error("Error subscribing to homework", err)
  );
  return unsubscribe;
};

export const subscribeToHomeworkDrafts = (
  receiverUid: string,
  onUpdate: (drafts: { draft: HomeworkDraft; id: string }[]) => void
) => {
  const q = query(
    collection(db, "therapists", auth.currentUser!.uid, "homeworkDrafts"),
    where("receiver", "==", receiverUid)
  ) as Query<HomeworkDraft>;

  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const drafts = querySnapshot.docs
        .map((doc) => {
          const draft = doc.data();
          if (!draft.receiver || typeof draft.description !== "string") {
            console.error("Invalid draft", JSON.stringify(draft));
            return undefined;
          }
          return {
            draft: draft,
            id: doc.id,
          };
        })
        .filter(isPresent);
      onUpdate(drafts);
    },
    (err) => console.error("Error subscribing to drafts", err)
  );
  return unsubscribe;
};

export async function createHomeworkDraft(receiver: string): Promise<string> {
  const uid = auth.currentUser!.uid;

  const draft: HomeworkDraft = {
    receiver,
    description: "",
  };

  const docRef = await addDoc<HomeworkDraft>(
    collection(
      db,
      "therapists",
      uid,
      "homeworkDrafts"
    ) as CollectionReference<HomeworkDraft>,
    draft
  );
  return docRef.id;
}

export async function updateHomeworkDraft(
  draftId: string,
  receiver: string,
  title: string,
  description: string,
  images: string[],
  labels: string[]
): Promise<{ severity: SnackSeverity; message: string }> {
  try {
    const uid = auth.currentUser!.uid;

    const draft =
      images.length > 0
        ? { receiver, description, title: title ?? "", images, labels }
        : { receiver, description, title: title ?? "", labels };

    await setDoc(doc(db, "therapists", uid, "homeworkDrafts", draftId), draft);
    return { severity: "success", message: "Homework draft updated" };
  } catch (error) {
    return { severity: "error", message: INTERNAL_ERROR };
  }
}

export async function deleteHomeworkDraft(
  draftId: string,
  images: string[]
): Promise<{ severity: SnackSeverity; message: string }> {
  try {
    const uid = getCurrentUser().uid;
    await runTransaction(db, async (transaction) => {
      try {
        images.forEach(async (i) => await deleteImage(i));
      } catch (error) {
        throw new Error("could not delete image");
      }
      transaction.delete(doc(db, "therapists", uid, "homeworkDrafts", draftId));
    });
    return { severity: "success", message: "Homework draft deleted" };
  } catch (error) {
    return { severity: "error", message: INTERNAL_ERROR };
  }
}

export async function sendHomeworkDraft(
  draftId: string
): Promise<{ severity: SnackSeverity; message: string }> {
  try {
    await functionsClient.sendHomework({
      draftId: draftId,
    });
    return { severity: "success", message: "New homework send" };
  } catch (e) {
    return { severity: "error", message: INTERNAL_ERROR };
  }
}

export function updateHomeworkAnswersSeen(homeworkId: string) {
  updateDoc(doc(db, "homework", homeworkId), {
    lastSeenByTherapist: new Date(),
  });
}

const onNextHomeworkTemplateSubscription = (
  onUpdate: (templates: HomeworkTemplate[]) => void
) => {
  return (querySnapshot: QuerySnapshot<DbHomeworkTemplate>) => {
    const templates = querySnapshot.docs
      .map((doc) => {
        const template = doc.data();
        if (
          typeof template.description !== "string" ||
          typeof template.title !== "string" ||
          typeof template.createdBy !== "string" ||
          typeof template.public !== "boolean" ||
          !template.createdAt ||
          !template.updatedAt
        ) {
          console.error("Invalid homework template", JSON.stringify(template));
          return undefined;
        }
        return {
          ...template,
          createdAt: template.createdAt.toDate(),
          updatedAt: template.updatedAt.toDate(),
          id: doc.id,
        };
      })
      .filter(isPresent);
    onUpdate(templates);
  };
};

export const subscribeToOwnHomeworkTemplates = (
  onUpdate: (templates: HomeworkTemplate[]) => void
) => {
  const q = query(
    collection(db, "homeworkTemplates"),
    where("createdBy", "==", auth.currentUser?.uid)
  ) as Query<DbHomeworkTemplate>;

  const unsubscribe = onSnapshot(
    q,
    onNextHomeworkTemplateSubscription(onUpdate),
    (err) => console.error("Error subscribing to own homework templates", err)
  );
  return unsubscribe;
};

export const subscribeToPublicHomeworkTemplates = (
  onUpdate: (templates: HomeworkTemplate[]) => void
) => {
  const q = query(
    collection(db, "homeworkTemplates"),
    where("createdBy", "!=", auth.currentUser?.uid),
    where("public", "==", true)
  ) as Query<DbHomeworkTemplate>;

  const unsubscribe = onSnapshot(
    q,
    onNextHomeworkTemplateSubscription(onUpdate),
    (err) =>
      console.error("Error subscribing to public homework templates", err)
  );
  return unsubscribe;
};

export async function createTemplate(
  title: string,
  description: string,
  publicFlag: boolean,
  images?: string[],
  labels?: string[]
): Promise<{ severity: SnackSeverity; message: string }> {
  try {
    const uid = getCurrentUser().uid;

    const homeworkTemplate = {
      title: title,
      description: description,
      images: images ?? [],
      labels: labels ?? [],
      public: publicFlag,
      createdBy: uid,
      createdAt: new Date(),
      updatedAt: new Date(),
    };
    await addDoc(collection(db, "homeworkTemplates"), homeworkTemplate);
    return { severity: "success", message: "New homework templated created" };
  } catch (error) {
    return { severity: "error", message: INTERNAL_ERROR };
  }
}

export async function sendTemplate(
  templateId: string,
  assigneeId: string
): Promise<{ severity: SnackSeverity; message: string }> {
  try {
    await functionsClient.sendHomeworkFromTemplate({
      templateId: templateId,
      patientId: assigneeId,
    });
    return { severity: "success", message: "homework send" };
  } catch (e) {
    return { severity: "error", message: INTERNAL_ERROR };
  }
}

export async function updateTemplate(
  templateId: string,
  title: string,
  description: string,
  publicFlag: boolean,
  images?: string[],
  labels?: string[]
): Promise<{ severity: SnackSeverity; message: string }> {
  try {
    const template = {
      title,
      description,
      images: images ?? [],
      labels: labels ?? [],
      public: publicFlag,
      updatedAt: new Date(),
    };

    await updateDoc(doc(db, "homeworkTemplates", templateId), template);
    return { severity: "success", message: "Template updated" };
  } catch (e) {
    return { severity: "error", message: INTERNAL_ERROR };
  }
}

export async function deleteTemplate(
  templateId: string,
  images: string[]
): Promise<{ severity: SnackSeverity; message: string }> {
  try {
    await runTransaction(db, async (transaction) => {
      try {
        images.forEach(async (i) => await deleteImage(i));
      } catch (error) {
        throw new Error("could not delete image");
      }
      transaction.delete(doc(db, "homeworkTemplates", templateId));
    });
    return { severity: "success", message: "Homework template deleted" };
  } catch (error) {
    return { severity: "error", message: INTERNAL_ERROR };
  }
}
