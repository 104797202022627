const commonColorPalette = {
  // deprecated
  darkBlue: "#1F275B",
  lightBlue: "#E8F7FF",
  linkBlue: "#0000FF",
  calm: "#66C2A5",
  happy: "#FC8D62",
  energy: "#8DA0CB",
  content: "#E78AC3",
  passion: "#A6D854",
  confidence: "#FFD92F",
  lightGray: "#CCC",
  opaqueGrey: "rgba(0,0,0,0.5)",
  red: "#EC4A4A",
  lightestGrey: "#E5E5E5",
  ////

  purple200: "#CCB4EC",
  purple300: "#BB98E8",
  purple500: "#8265CB",
  purple550: "#4533B0",
  purple600: "#845794",
  purple700: "#51398B",
  purple800: "rgba(35,0,48,0.90)",
  purple900: "#230030",
  purple950: "#15031C",

  grey000: "#FFFFFF",
  grey050: "#F5F5F5",
  grey100: "#DDDDDD",
  grey200: "#EEEEEE",
  grey300: "#A4A4A4",
  grey500: "#ACACAC",
  grey600: "#707070",
  grey700: "#686868",
  grey900: "#222",

  black: "#000",
  black700: "rgba(0,0,0,0.70)",

  transparent: "#FFFFFF00",
  transparent100: "rgba(255, 255, 255, 0.07)",
  transparent300: "rgba(255, 255, 255, 0.20)",
  transparent500: "rgba(255, 255, 255, 0.50)",
  transparent900: "rgba(255, 255, 255, 0.90)",

  blue100: "#88E6F2",
  blue150: "#A4D3FF",
  blue200: "#8B92D6",
  blue300: "#2898FF",
  blue400: "#3787FF",
  blue500: "#606DE8",
  blue600: "#0766BE",

  green300: "#97C999",
  green500: "#51A654",
  pink300: "#D79DD1",
  pink500: "#C747BA",

  red300: "#DD8A8A",
  red400: "#FF6B6B",
  red500: "#EC4A4A",

  yellow300: "#F1AD8F",
  yellow500: "#E7964C",
};

export const commonColors = {
  ...commonColorPalette,
  light: {
    happinessCategory: commonColorPalette.green300,
    fearCategory: commonColorPalette.pink300,
    confusionCategory: commonColorPalette.yellow300,
    sadnessCategory: commonColorPalette.blue200,
    neutralCategory: commonColorPalette.grey600,
    angerCategory: commonColorPalette.red300,
  },
  dark: {
    happinessCategory: commonColorPalette.green500,
    fearCategory: commonColorPalette.pink500,
    confusionCategory: commonColorPalette.yellow500,
    sadnessCategory: commonColorPalette.blue500,
    neutralCategory: commonColorPalette.grey500,
    angerCategory: commonColorPalette.red500,
  },

  // common colors aliases
};

export type CommonThemeColors = keyof typeof commonColors;
