import { FC, ReactNode } from "react";
import LoadingButton, { LoadingButtonProps } from "@mui/lab/LoadingButton";

type ButtonSizeVariant = "small" | "medium" | "large";
export type ButtonVariant =
  | "primary"
  | "secondary"
  | "tertiary"
  | "danger"
  | "success";

const getMuiVariant = (variant: ButtonVariant) => {
  switch (variant) {
    case "primary":
      return "contained";
    case "secondary":
      return "outlined";
    case "tertiary":
      return "text";
    case "danger":
      return "contained";
    case "success":
      return "contained";
    default:
      return "contained";
  }
};

const getColor = (variant: ButtonVariant) => {
  switch (variant) {
    case "primary":
      return "primary";
    case "secondary":
      return "primary";
    case "tertiary":
      return "primary";
    case "danger":
      return "error";
    case "success":
      return "success";
    default:
      return "primary";
  }
};

interface ButtonProps {
  label: string;
  loading?: boolean;
  size?: ButtonSizeVariant;
  onClick?:
    | (() => void)
    | ((event: React.MouseEvent<HTMLButtonElement>) => void);
  disabled?: boolean;
  variant: ButtonVariant;
  colorVariant?: ButtonVariant;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  type?: "submit" | "button" | undefined;
  fullWidth?: boolean;
  color?: string;
  autoFocus?: LoadingButtonProps["autoFocus"];
}

const Button: FC<ButtonProps> = ({
  label,
  loading,
  size,
  onClick,
  disabled,
  variant,
  leftIcon,
  rightIcon,
  type,
  fullWidth,
  color,
  colorVariant,
  autoFocus,
}) => {
  return (
    <LoadingButton
      loading={loading}
      onClick={onClick}
      size={size ? size : "medium"}
      disabled={disabled}
      variant={getMuiVariant(variant)}
      color={colorVariant ? getColor(colorVariant) : getColor(variant)}
      startIcon={leftIcon}
      endIcon={rightIcon}
      type={type ? type : "button"}
      fullWidth={fullWidth}
      sx={{ color: color ? color : undefined }}
      autoFocus={autoFocus}
    >
      {label}
    </LoadingButton>
  );
};

export default Button;
