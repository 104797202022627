import {
  createContext,
  FC,
  useEffect,
  useState,
  PropsWithChildren,
} from "react";
import { PrivateProfessionalProfileV2 } from "common/dist/types/Therapist";
import { Provider } from "common/dist/types/User";
import { subscribeToProfessionalProfile } from "../services/user";
import { useAuth } from "../hooks/useAuth";
import { auth } from "../features/firebase";

interface ProfileContextType {
  profile: PrivateProfessionalProfileV2 | undefined;
  providers: Provider[];
  setProviders: (values: Provider[]) => void;
}

export const ProfileContext = createContext<ProfileContextType | undefined>(
  undefined
);

export const ProfileProvider: FC<PropsWithChildren> = ({ children }) => {
  const { userAuth } = useAuth();
  const [providers, setProviders] = useState<Provider[]>(
    auth.currentUser?.providerData.map((p) => ({
      providerId: p.providerId,
      email: p.email ?? "",
    })) ?? []
  );
  const [profile, setProfile] = useState<
    PrivateProfessionalProfileV2 | undefined
  >(undefined);

  useEffect(() => {
    if (userAuth === undefined) {
      setProfile(undefined);
    }
  }, [userAuth]);

  useEffect(
    () => subscribeToProfessionalProfile(setProfile, userAuth.uid),
    [setProfile, userAuth.uid]
  );

  const context: ProfileContextType = {
    profile: profile,
    providers: providers,
    setProviders: setProviders,
  };
  return (
    <ProfileContext.Provider value={context}>
      {children}
    </ProfileContext.Provider>
  );
};
