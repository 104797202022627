import { FC } from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import { TreeItem, TreeView } from "@mui/lab";
import { THEME } from "../../../../../theme";
import Icon from "../../../../../components/Icon";

interface CategoryCheckboxTreeViewProps {
  category: string;
  options: { value: string; displayName: string }[];
  selected: { value: string; displayName: string }[];
  setSelected: (selected: { value: string; displayName: string }[]) => void;
}

const CategoryCheckboxTreeView: FC<CategoryCheckboxTreeViewProps> = ({
  category,
  options,
  selected,
  setSelected,
}) => {
  return (
    <TreeItem
      onClick={(event) => event.stopPropagation()}
      nodeId={`category-${category}`}
      label={
        <FormControlLabel
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            setSelected(selected.length === options.length ? [] : options);
          }}
          control={
            <Checkbox
              checked={selected.length > 0}
              indeterminate={
                selected.length > 0 && selected.length < options.length
              }
            />
          }
          label={`${category} (${selected.length})`}
        />
      }
    >
      {options.map((option) => (
        <TreeItem
          key={`category-${category}-${option.displayName}${option.value}`}
          nodeId={`category-${category}-${option.displayName}${option.value}`}
          label={
            <FormControlLabel
              control={
                <Checkbox
                  checked={
                    !!selected.find(
                      (o) =>
                        option.displayName === o.displayName &&
                        option.value === o.value
                    )
                  }
                  onClick={() =>
                    setSelected(
                      !!selected.find(
                        (o) =>
                          option.displayName === o.displayName &&
                          option.value === o.value
                      )
                        ? selected.filter(
                            (s) =>
                              s.displayName !== option.displayName ||
                              s.value !== option.value
                          )
                        : selected.concat(option)
                    )
                  }
                />
              }
              label={option.displayName.replace(/^\w/, (c) => c.toUpperCase())}
            />
          }
        />
      ))}
    </TreeItem>
  );
};

export const CorrelationVariableSelection: FC<{
  selections: {
    category: string;
    options: { value: string; displayName: string }[];
    selected: { value: string; displayName: string }[];
    setSelected: (selected: { value: string; displayName: string }[]) => void;
  }[];
}> = ({ selections }) => (
  <TreeView
    defaultCollapseIcon={<Icon variant="arrowDown" />}
    defaultExpandIcon={<Icon variant="arrowRight" />}
    sx={{ width: 280, color: THEME.colors.grey600, mt: 3 }}
  >
    {selections.map((s, i) => (
      <CategoryCheckboxTreeView
        key={`category-${s.category}-${i}`}
        category={s.category}
        options={s.options}
        selected={s.selected}
        setSelected={s.setSelected}
      />
    ))}
  </TreeView>
);
