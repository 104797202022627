import { Divider } from "@mui/material";
import { FC } from "react";
import { EmailInvitation } from "./EmailInvitation";
import { InvitationList } from "./InvitationList";

export const ConnectTab: FC = () => {
  return (
    <>
      <h1>Connect</h1>
      <Divider sx={{ mt: 1, mb: 2 }} />
      <EmailInvitation />
      <InvitationList />
    </>
  );
};
