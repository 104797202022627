import React, { FC } from "react";
import { TextField, Box, TextFieldProps } from "@mui/material";

interface TextInputProps {
  label?: string;
  placeholder?: string;
  defaultValue?: string;
  value?: string;
  onChange?: (v: string) => void;
  disabled?: boolean;
  required?: boolean;
  type?: TextFieldProps["type"];
  id?: string;
  maxWidth?: string | number;
  minWidth?: string | number;
  error?: string;
  onBlur?: () => void;
  name?: string;
  onKeyDown?: TextFieldProps["onKeyDown"];
  autoComplete?: TextFieldProps["autoComplete"];
  autoFocus?: TextFieldProps["autoFocus"];
  multiline?: TextFieldProps["multiline"];
  size?: TextFieldProps["size"];
}

const TextInput: FC<TextInputProps> = ({
  label,
  placeholder,
  defaultValue,
  onChange,
  disabled,
  required,
  type,
  id,
  value,
  maxWidth,
  minWidth,
  error,
  onBlur,
  name,
  onKeyDown,
  autoComplete,
  autoFocus,
  multiline,
  size,
}) => {
  return (
    <Box
      sx={{
        paddingY: 1,
        width: "100%",
        minWidth: minWidth,
        maxWidth: maxWidth,
      }}
    >
      <TextField
        id={id}
        name={name}
        required={required}
        sx={{
          maxWidth: maxWidth,
          minWidth: minWidth,
          width: "100%",
        }}
        label={label}
        color="primary"
        placeholder={placeholder ?? label}
        value={value}
        defaultValue={defaultValue}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (onChange) {
            onChange(event.target.value);
          }
        }}
        disabled={disabled}
        type={type}
        variant="outlined"
        error={error ? true : undefined}
        helperText={error}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        multiline={multiline}
        size={size}
      />
    </Box>
  );
};

export default TextInput;
