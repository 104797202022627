import { FC, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { LabelCircle } from "../../../../../../components/LabelCircle";
import { THEME } from "../../../../../../theme";
import Button from "../../../../../../components/Button";
import Icon from "../../../../../../components/Icon";
import { styles } from "../../styles";

interface MostLoggedEmotionsProps {
  emotionsWithCount: { emotion: string; count: number; color: string }[];
}

const MostLoggedEmotions: FC<MostLoggedEmotionsProps> = ({
  emotionsWithCount,
}) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <Box>
      <Typography sx={styles.statisticHeader} mb={1}>
        most logged emotions
      </Typography>

      <Grid container spacing={1} columns={10} mb={1}>
        {emotionsWithCount
          .sort((a, b) => b.count - a.count)
          .slice(0, showAll ? undefined : 10)
          .map((el) => (
            <Grid item xs={2} key={`emotion-circle-${el.emotion}-${el.color}`}>
              <LabelCircle
                label={el.emotion}
                count={el.count}
                color={el.color ?? ""}
                textStyle={{ ...styles.statisticLabelSmall }}
              />
            </Grid>
          ))}
      </Grid>

      {emotionsWithCount.length > 10 && (
        <Button
          label={showAll ? "Show less" : "Show all"}
          size="medium"
          onClick={() => setShowAll(!showAll)}
          variant="tertiary"
          leftIcon={
            <Icon variant={showAll ? "arrowUpCircle" : "arrowDownCircle"} />
          }
          color={THEME.colors.grey600}
        />
      )}
    </Box>
  );
};

export default MostLoggedEmotions;
