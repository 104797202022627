import React, { FC } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Provider } from "common/dist/types/User";

import Icon from "../../../components/Icon";
import FormCard, { contentContainerStyles } from "../../../components/FormCard";
import { linkWithGoogle, unlinkSignInMethod } from "../../../services/user";
import { useProfile } from "../../../hooks/useProfile";
import { listStyles } from "../styles";

import ConnectionRow from "./ConnectionRow";

interface ConnectionsProps {
  providers: Provider[];
  primaryEmail: string;
}

const Connections: FC<ConnectionsProps> = () => {
  const { setProviders } = useProfile();

  const unlinkGoogle = async () => {
    try {
      const res = await unlinkSignInMethod("google.com");
      if (res) {
        setProviders(
          res.map((p) => ({ providerId: p.providerId, email: p.email ?? "" }))
        );
      }
    } catch (error) {}
  };

  const externalProviders = [
    { providerId: "google.com", link: linkWithGoogle, unlink: unlinkGoogle },
  ];

  return (
    <FormCard component="section">
      <Box sx={contentContainerStyles}>
        <Box display="flex" flexDirection="row" gap={1} alignItems="center">
          <Icon variant="key" size="large" />
          <h2>Connections</h2>
        </Box>
        <Typography color="gray">
          Link your account with third party services. Keep your connections
          up-to-date.
        </Typography>
        <Box component="ul" sx={listStyles}>
          {externalProviders.map((p) => (
            <ConnectionRow key={p.providerId} provider={p} />
          ))}
        </Box>
      </Box>
    </FormCard>
  );
};

export default Connections;
