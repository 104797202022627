import { FC, Fragment } from "react";
import { HeatmapCellProps } from "reaviz/dist/src/Heatmap";
import { motion } from "framer-motion";
import { correlationColors } from "./correlationUtils";
import { THEME } from "../../../../../theme";

export const CorrelationHeatmapCell: FC<Partial<HeatmapCellProps>> = ({
  cursor,
  x,
  y,
  data,
  width,
  height,
}) => {
  return (
    <Fragment>
      <g>
        <motion.rect
          width={width}
          height={height}
          fill={
            Number.isNaN(data?.value)
              ? THEME.colors.grey000
              : correlationColors[
                  Math.min(
                    Math.floor((((data?.value ?? 0) as number) + 1) * 5),
                    9
                  )
                ]
          }
          x={x}
          y={y}
          rx={10}
          style={{ cursor }}
        />
        <text
          x={(x ?? 0) + (width ?? 0) / 2}
          y={(y ?? 0) + (height ?? 0) / 2 + 5}
          fill={
            Number.isNaN(data?.value) ||
            Math.abs((data?.value as number) ?? 0) <= 0.4
              ? THEME.colors.grey600
              : THEME.colors.grey000
          }
        >
          <tspan textAnchor="middle">
            {Number.isNaN(data?.value) ? "N/A" : `${data?.value}`}
          </tspan>
        </text>
      </g>
    </Fragment>
  );
};
