import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material";
import { THEME } from "../../theme";

interface StatsLegendProps {
  label: string | number;
  color: string;
}
const legendChip: SxProps = {
  width: "41px",
  height: "9px",
  borderRadius: "20px",
  mr: 1,
};
const statisticDescription: SxProps = {
  color: THEME.colors.grey600,
  fontSize: "14px",
  display: "flex",
  flexDirection: "row",
  alignItems: "baseline",
};

const StatsLegend: FC<StatsLegendProps> = ({ label, color }) => {
  return (
    <Box display="flex" flexDirection="row" gap="2px" alignItems="center">
      <Box
        sx={{ ...legendChip, background: color, border: "1px solid #CDCDCD" }}
      />
      <Typography sx={statisticDescription} maxWidth={200}>
        {label}
      </Typography>
    </Box>
  );
};

export default StatsLegend;
