import { FC, useEffect, useState } from "react";
import { useParams, useLocation } from "wouter";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { FeedbackCore, FeedbackDraft } from "common/types/Feedback";
import { useAssignee } from "../../../../hooks/useAssignee";
import InfoBox from "../../../../components/InfoBox";
import Button from "../../../../components/Button";
import Icon from "../../../../components/Icon";
import Snackbar, { SnackbarStatus } from "../../../../components/Snackbar";
import FeedbackForm from "../../../../components/FeedbackForm";
import {
  sendDraft,
  deleteDraft,
  updateDraft,
  createTemplate,
  checkTemplateExists,
  subscribeToDraft,
} from "../../../../services/feedback";
import { useTemplates } from "../../../../hooks/useAppData";

export const NewFeedback: FC = () => {
  const params = useParams();
  const draftId = params.draftId ?? "";
  const [, setLocation] = useLocation();
  const { assignee } = useAssignee();
  const assigneeId = assignee?.uid ?? "";
  const { templates } = useTemplates();
  const [draft, setDraft] = useState<
    { draft: FeedbackDraft; id: string } | undefined | null
  >(null);
  const [snackStatus, setSnackStatus] = useState<SnackbarStatus | undefined>(
    undefined
  );

  useEffect(() => subscribeToDraft(draftId, setDraft), [draftId]);

  const handleSendDraft = async () => {
    const res = await sendDraft(draftId);
    setSnackStatus(res);
    if (res.severity === "success") {
      setLocation("/", { replace: true });
    }
  };

  const handleDeleteDraft = async () => {
    const res = await deleteDraft(draftId);
    setSnackStatus(res);
    if (res.severity === "success") {
      setLocation("/", { replace: true });
    }
  };

  const handleUpdateDraft = async (updatedFeedback: FeedbackCore) => {
    const res = await updateDraft(
      draftId,
      assigneeId,
      updatedFeedback.description.trim(),
      updatedFeedback.questions
    );
    setSnackStatus(res);
  };

  const handleCreateTemplate = async (newTemplate: FeedbackCore) => {
    if (
      checkTemplateExists(
        templates,
        newTemplate.description,
        newTemplate.questions
      )
    ) {
      setSnackStatus({
        severity: "error",
        message: "Identical template already exists.",
      });
      return;
    }

    const res = await createTemplate(
      newTemplate.description,
      newTemplate.questions
    );
    setSnackStatus(res);
  };

  if (draft === null) {
    return (
      <Box>
        <CircularProgress size={100} />
      </Box>
    );
  }

  if (draft === undefined) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        paddingTop={10}
        alignItems="center"
        justifyContent="center"
      >
        <Typography>Feedback draft not found</Typography>
        <Box>
          <Button
            label="go back to feedbacks"
            onClick={() => setLocation("/", { replace: true })}
            size="medium"
            variant="primary"
            leftIcon={<Icon variant="arrowLeft" />}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap={1} paddingTop={1}>
      <Box>
        <Button
          label="back to feedbacks"
          variant="tertiary"
          size="medium"
          onClick={() => setLocation("/", { replace: true })}
          leftIcon={<Icon variant="arrowLeft" />}
        />
      </Box>
      <h2>New Feedback Request</h2>
      <FeedbackForm
        onSend={handleSendDraft}
        onDelete={handleDeleteDraft}
        onUpdate={handleUpdateDraft}
        onTemplate={handleCreateTemplate}
        feedback={draft.draft}
        type="draft"
      />
      <InfoBox
        content="Saving a template will make it available for you to use across client
        profiles. Clients will not see these templates, only the feedback
        requests that you send."
      />
      <Snackbar
        message={snackStatus?.message}
        setVisibility={() => setSnackStatus(undefined)}
        duration={6000}
        custom={true}
        severity={snackStatus?.severity}
      />
    </Box>
  );
};
