import { FC, PropsWithChildren, ReactNode, useState } from "react";
import { Box, Tooltip } from "@mui/material";
import { ConfirmationDialog } from "./ConfirmationDialog";
import Button from "./Button";
import FormCard, {
  contentContainerStyles,
  actionContainerStyles,
} from "./FormCard";
import Icon from "./Icon";

interface EditorProps extends PropsWithChildren {
  close: () => void;
  send: () => Promise<void>;
  delete: () => Promise<void>;
  save: () => void;
  saving: boolean;
  changes: boolean;
  sendDisabled: boolean;
  sendButtonTooltip: string;
  type: "message" | "homework" | "feedback request" | "feedback template";
  customButton?: ReactNode;
}

export const EditView: FC<EditorProps> = (props) => {
  const [showSendConfirmation, setSendConfirmation] = useState(false);
  const [showDiscardConfirmation, setDiscardConfirmation] = useState(false);

  const handleApprove = async () => {
    await props.send();
    setSendConfirmation(false);
    props.close();
  };

  const handleDelete = async () => {
    await props.delete();
    props.close();
  };

  return (
    <>
      <Box p={1}>
        <Button
          variant="tertiary"
          onClick={props.close}
          leftIcon={<Icon variant="arrowLeft" />}
          label="Back"
          size="medium"
        />
      </Box>
      <FormCard>
        <ConfirmationDialog
          title={`Send ${props.type}?`}
          content="Send as a message to user?"
          approveText="Send"
          open={showSendConfirmation}
          handleClose={() => {
            setSendConfirmation(false);
          }}
          approve={handleApprove}
        />
        <ConfirmationDialog
          title={`Discard ${props.type}?`}
          content={`Discard ${props.type} and its contents?`}
          approveText="Discard"
          open={showDiscardConfirmation}
          handleClose={() => {
            setDiscardConfirmation(false);
          }}
          approve={handleDelete}
        />

        <Box sx={contentContainerStyles}>{props.children}</Box>
        <Box sx={actionContainerStyles}>
          {props.customButton}
          <Button
            variant="success"
            onClick={props.save}
            loading={props.saving}
            disabled={!props.changes}
            leftIcon={<Icon variant="save" />}
            label="Save"
            size="medium"
          />
          <Button
            variant="danger"
            onClick={() => setDiscardConfirmation(true)}
            leftIcon={<Icon variant="delete" />}
            disabled={props.saving}
            size="medium"
            label="Delete"
          />
          <Tooltip title={props.sendButtonTooltip}>
            <span>
              <Button
                variant="primary"
                onClick={() => setSendConfirmation(true)}
                leftIcon={<Icon variant="send" />}
                disabled={props.sendDisabled}
                label="Send"
                size="medium"
              />
            </span>
          </Tooltip>
        </Box>
      </FormCard>
    </>
  );
};
