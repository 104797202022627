import React, {
  FC,
  useState,
  useEffect,
  useMemo,
  useCallback,
  PropsWithChildren,
} from "react";
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Badge,
  CircularProgress,
} from "@mui/material";
import { useLocation, useParams } from "wouter";
import { useAssignee } from "../../../hooks/useAssignee";
import { useEntries } from "../../../hooks/useEntries";
import { useHomeworks } from "../../../hooks/useHomeworks";
import {
  useAssigneeFeedbacks,
  useAssigneeConversations,
} from "../../../hooks/useAppData";
import { getAssignee } from "../../../services/assignees";
import { updateFeedbackAnswersSeen } from "../../../services/feedback";
import { updateConversationsSeen } from "../../../services/messages";
import { updateEntriesAsSeenByTherapist } from "../../../services/entries";
import { ConfirmationDialog } from "../../../components/ConfirmationDialog";
import Icon from "../../../components/Icon";
import Button from "../../../components/Button";
import { functionsClient } from "../../firebase";
import ProfileSummary from "./ProfileSummary";

const getTabsInitState = (location: string) => {
  switch (location) {
    case location.match("/entries*")?.input: {
      return 1;
    }
    case location.match("/conversation*")?.input: {
      return 2;
    }
    case location.match("/homeworks*")?.input: {
      return 3;
    }
    case location.match("/feedbacks*")?.input: {
      return 4;
    }
    case location.match("/timeline*")?.input: {
      return 5;
    }
    case "/": {
      return 0;
    }
    default: {
      return 0;
    }
  }
};

export const Assignee: FC<PropsWithChildren> = ({ children }) => {
  const params = useParams();
  const assigneeId = params.id ?? "";
  const [location, setLocation] = useLocation();
  const { assignee, setAssignee } = useAssignee();
  const { entries } = useEntries();
  const { homeworks } = useHomeworks();
  const { feedbacks } = useAssigneeFeedbacks(assigneeId);
  const { conversations } = useAssigneeConversations(assigneeId);
  const [value, setValue] = useState(getTabsInitState(location));
  const [showDisconnectConfirmation, setDisconnectConfirmation] =
    useState(false);
  const [loading, setLoading] = useState<boolean>(false);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    if (value === 3 && unseenFeedbacks.length > 0) {
      updateFeedbackAnswersSeen(unseenFeedbacks.map((f) => f.id));
    }
    if (value === 2 && unseenMessages.length > 0) {
      updateConversationsSeen(unseenMessages.map((c) => c.id));
    }
    if (value === 1 && unseenLogEntries.length > 0) {
      updateEntriesAsSeenByTherapist(
        assignee?.uid ?? "",
        unseenLogEntries.map((c) => c.id)
      );
    }
    setValue(newValue);
  };

  const fetchAssignee = useCallback(async () => {
    if (!assignee || assignee.uid !== assigneeId) {
      setLoading(true);
      const data = await getAssignee(assigneeId);
      if (data) {
        setAssignee(data);
      }
      setLoading(false);
    }
  }, [assigneeId, assignee, setAssignee]);

  const disconnectAssignee = async () => {
    await functionsClient.disconnectFromPatient({ patientId: assigneeId });
    setAssignee(undefined);
    setLocation("~/review");
  };

  useEffect(() => {
    fetchAssignee();
  }, [fetchAssignee]);

  const unseenLogEntries = useMemo(
    () => entries?.filter((e) => !e.seenByTherapist) ?? [],
    [entries]
  );

  const unseenHomework = useMemo(
    () =>
      homeworks?.find(
        (homework) =>
          !!homework.answers.find(
            (answer) =>
              !homework.lastSeenByTherapist ||
              answer.timestamp > homework.lastSeenByTherapist
          )
      ),
    [homeworks]
  );

  const unseenFeedbacks = useMemo(
    () =>
      feedbacks?.filter(
        (f) =>
          f.feedback.lastUpdatedByPatient &&
          (!f.feedback.lastSeenByTherapist ||
            f.feedback.lastUpdatedByPatient > f.feedback.lastSeenByTherapist)
      ) ?? [],
    [feedbacks]
  );

  const unseenMessages = useMemo(
    () =>
      conversations?.filter(
        (c) =>
          !!c.conversation.messages.find(
            (m) =>
              m.sender === c.conversation.user &&
              (!c.conversation.lastSeenByTherapist ||
                m.timestamp > c.conversation.lastSeenByTherapist)
          )
      ) ?? [],
    [conversations]
  );

  return (
    <>
      <ProfileSummary
        assigneeId={assigneeId}
        assigneeName={assignee?.name}
        picture={assignee?.picture}
      />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} role="navigation">
          <Tab
            component="a"
            onClick={() => setLocation("/")}
            label="Analytics"
          />
          <Tab
            component="a"
            onClick={() => setLocation("/entries")}
            label={
              <Badge
                badgeContent=" "
                color="error"
                invisible={unseenLogEntries.length === 0}
                variant="dot"
              >
                Entries
              </Badge>
            }
          />
          <Tab
            component="a"
            onClick={() => setLocation("/conversation")}
            label={
              <Badge
                badgeContent=" "
                color="error"
                invisible={unseenMessages.length === 0}
                variant="dot"
              >
                Messages
              </Badge>
            }
          />
          <Tab
            component="a"
            onClick={() => setLocation("/homeworks")}
            label={
              <Badge
                badgeContent=" "
                color="error"
                invisible={unseenHomework === undefined}
                variant="dot"
              >
                Homework
              </Badge>
            }
          />
          <Tab
            component="a"
            onClick={() => setLocation("/feedbacks")}
            label={
              <Badge
                badgeContent=" "
                color="error"
                invisible={unseenFeedbacks.length === 0}
                variant="dot"
              >
                Feedback
              </Badge>
            }
          />
          <Tab
            component="a"
            onClick={() => setLocation("/timeline")}
            label="Timeline"
          />
          {assignee && (
            <Box
              flexGrow={1}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Button
                leftIcon={<Icon size="small" variant="circleMinus" />}
                onClick={() => setDisconnectConfirmation(true)}
                label="disconnect from user"
                variant="tertiary"
                size="large"
                colorVariant="danger"
              />
            </Box>
          )}
        </Tabs>
      </Box>

      {loading ? (
        <CircularProgress size={100} />
      ) : (
        <>
          {assignee ? (
            <>{children}</>
          ) : (
            <>
              <Typography>
                Sorry, could not fetch Assignee, please refetch.
              </Typography>
              <Button
                variant="primary"
                label="refetch"
                size="large"
                onClick={fetchAssignee}
                disabled={loading}
                loading={loading}
              />
            </>
          )}
        </>
      )}
      <ConfirmationDialog
        title={`Disconnect from ${assignee?.name}?`}
        content={`Are you sure you want to disconnect from ${assignee?.name}? You will not be able to see any of their data after this operation.`}
        approveText="Disconnect"
        open={showDisconnectConfirmation}
        handleClose={() => setDisconnectConfirmation(false)}
        approve={disconnectAssignee}
      />
    </>
  );
};
