import {
  doc,
  DocumentReference,
  getDoc,
  FirestoreError,
  runTransaction,
  onSnapshot,
} from "firebase/firestore";
import {
  updateEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  GoogleAuthProvider,
  updatePassword,
  AuthError,
  linkWithCredential,
  unlink,
  linkWithRedirect,
  UserInfo,
  reauthenticateWithPopup,
} from "firebase/auth";
import {
  PrivateProfessionalProfileV2,
  DbPublicProfessionalProfileV2,
  ProfessionalAttributes,
} from "common/dist/types/Therapist";
import { UserAuth } from "common/dist/types/User";
import { getCurrentUser, sendEmailVerificationLink } from "./auth";
import { db, functionsClient } from "../features/firebase";
import { WEAK_PASSWORD, INTERNAL_ERROR } from "./constants";
import { SnackSeverity } from "../components/Snackbar";

export async function changePwd(
  oldPwd: string,
  newPwd: string
): Promise<{ status: boolean; message: string }> {
  try {
    const currentUser = getCurrentUser();

    if (!currentUser.email) {
      throw new Error("User missing email");
    }

    const pwdProvider = currentUser.providerData.find(
      (p) => p.providerId === "password"
    );

    if (pwdProvider !== undefined) {
      const cred = EmailAuthProvider.credential(
        currentUser.email,
        oldPwd ?? ""
      );
      await reauthenticateWithCredential(currentUser, cred).then(
        async () => await updatePassword(currentUser, newPwd)
      );
    } else {
      await reauthenticateWithPopup(currentUser, new GoogleAuthProvider()).then(
        async () => {
          await linkWithCredential(
            currentUser,
            EmailAuthProvider.credential(currentUser.email ?? "", newPwd)
          );
        }
      );
    }

    return { status: true, message: "Password changed successfully" };
  } catch (error) {
    const err = error as AuthError;
    switch (err.code) {
      case "auth/wrong-password": {
        return {
          status: false,
          message:
            "Sorry could not update password, current password is invalid",
        };
      }
      case "auth/weak-password":
      case "auth/empty-password":
        return {
          status: false,
          message: WEAK_PASSWORD,
        };
      default: {
        return {
          status: false,
          message: INTERNAL_ERROR,
        };
      }
    }
  }
}

export async function changeEmail(
  newEmail: string,
  pwd: string
): Promise<{ severity: SnackSeverity; message: string }> {
  try {
    await runTransaction(db, async (transaction) => {
      const currentUser = getCurrentUser();
      transaction.set(
        doc(db, "therapists", currentUser.uid, "profile", "private"),
        { email: newEmail },
        {
          merge: true,
        }
      );

      try {
        if (!currentUser.email) {
          throw new Error("User missing email");
        }

        const pwdProvider = currentUser.providerData.find(
          (p) => p.providerId === "password"
        );

        if (pwdProvider !== undefined) {
          const cred = EmailAuthProvider.credential(currentUser.email, pwd);
          await reauthenticateWithCredential(currentUser, cred).then(
            async () => {
              // https://github.com/firebase/firebase-tools/issues/3424
              // auth emulator do not have this feature yet.
              // verifyBeforeUpdateEmail do not update email straight.
              await updateEmail(currentUser, newEmail);
            }
          );
        } else {
          await reauthenticateWithPopup(
            currentUser,
            new GoogleAuthProvider()
          ).then(async () => {
            await linkWithCredential(
              currentUser,
              EmailAuthProvider.credential(newEmail ?? "", pwd)
            );
          });
        }

        // send email verification link
        const sendVerifiRes = await sendEmailVerificationLink();
        if (!sendVerifiRes) {
          return {
            status: "warning",
            message:
              "Email changed successfully, could not send email verification link, please try to send the link again",
          };
        }
      } catch (emailError) {
        console.debug("Error changing email", emailError);
        const err = emailError as AuthError;
        throw new Error(err.code);
      }
    });
    return {
      severity: "success",
      message: "Email updated and verification link send successfully",
    };
  } catch (error) {
    const err = error as Error;
    switch (err.message) {
      case "auth/wrong-password":
        return {
          severity: "error",
          message: "Invalid password, email is not updated",
        };

      default:
        return {
          severity: "error",
          message: INTERNAL_ERROR,
        };
    }
  }
}

export async function saveProfile(profile: PrivateProfessionalProfileV2) {
  try {
    await runTransaction(db, async (transaction) => {
      const currentUser = getCurrentUser();
      transaction.set(
        doc(db, "therapists", currentUser.uid, "profile", "private"),
        profile,
        {
          merge: true,
        }
      );
      const publicProfile: DbPublicProfessionalProfileV2 = {
        name: profile.name,
      };
      transaction.set(
        doc(db, "therapists", currentUser.uid, "profile", "public"),
        publicProfile,
        {
          merge: true,
        }
      );
    });
    return true;
  } catch (error) {
    console.debug(error);
    return false;
  }
}

export async function createProfile(
  newProfile: Partial<PrivateProfessionalProfileV2>,
  user: UserAuth
) {
  try {
    await runTransaction(db, async (transaction) => {
      const profile: PrivateProfessionalProfileV2 = {
        name: newProfile.name?.trim() ?? "",
        email: newProfile.email?.trim() ?? "",
        number: "",
        title: newProfile.title?.trim() ?? "",
        education: [],
        experience: [],
        showEmail: false,
        showNumber: false,
      };
      const publicProfile: DbPublicProfessionalProfileV2 = {
        name: profile.name,
      };
      const metadata: ProfessionalAttributes = {
        assignees: [],
        approved: false,
      };

      transaction.set(doc(db, "therapists", user.uid), metadata);
      transaction.set(
        doc(db, "therapists", user.uid, "profile", "private"),
        profile
      );
      transaction.set(
        doc(db, "therapists", user.uid, "profile", "public"),
        publicProfile
      );
      try {
        await functionsClient.setCustomClaims({ professional: true });
      } catch (error) {
        console.debug(error);
        throw new Error("Could not set customs claims");
      }
    });
    return true;
  } catch (error) {
    console.debug(error);
    return false;
  }
}

export function subscribeToProfessionalProfile(
  onUpdate: (profile: PrivateProfessionalProfileV2 | undefined) => void,
  professionalUid: string
) {
  const docRef = doc(
    db,
    "therapists",
    professionalUid,
    "profile",
    "private"
  ) as DocumentReference<PrivateProfessionalProfileV2>;

  const unsubscribe = onSnapshot(
    docRef,
    (doc) => {
      const data = doc.data();
      if (!doc.exists()) {
        onUpdate({
          name: "",
          title: "",
          number: "",
          experience: [],
          education: [],
          showNumber: false,
          showEmail: false,
          email: "",
        });
      }

      if (doc.exists() && data) {
        onUpdate(data);
      }
    },
    (err) => console.debug("Error subscribing professional profile", err)
  );

  return unsubscribe;
}

export function subscribeToProfessionalAttributes(
  onUpdate: (attributes: ProfessionalAttributes | undefined) => void,
  professionalUid: string
) {
  const docRef = doc(
    db,
    "therapists",
    professionalUid
  ) as DocumentReference<ProfessionalAttributes>;

  const unsubscribe = onSnapshot(
    docRef,
    (doc) => {
      const data = doc.data();
      if (!doc.exists()) {
        onUpdate(undefined);
      }

      if (doc.exists() && data) {
        onUpdate(data);
      }
    },
    (err) => console.debug("Error subscribing professional attributes", err)
  );

  return unsubscribe;
}

export async function getProfile(
  firebaseUser: UserAuth
): Promise<PrivateProfessionalProfileV2 | null> {
  console.debug("Fetching therapist profile");
  const docRef = doc(
    db,
    "therapists",
    firebaseUser.uid,
    "profile",
    "private"
  ) as DocumentReference<PrivateProfessionalProfileV2>;
  try {
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      console.debug(`Profile missing for user ${firebaseUser.uid}`);
      return null;
    }
  } catch (err) {
    if ((err as FirestoreError).code === "permission-denied") {
      return null;
    } else {
      throw err;
    }
  }
}

export async function linkWithEmailAndPwd(
  newEmail: string,
  newPwd: string
): Promise<UserInfo[] | undefined> {
  try {
    const currentUser = getCurrentUser();
    const newCredentials = EmailAuthProvider.credential(newEmail, newPwd);
    const res = await reauthenticateWithPopup(
      currentUser,
      new GoogleAuthProvider()
    ).then(async () => {
      return await linkWithCredential(currentUser, newCredentials);
    });
    return res.user.providerData;
  } catch (error) {
    console.debug("Error adding a new loging method", error);
    return undefined;
  }
}

export async function linkWithGoogle() {
  try {
    const currentUser = getCurrentUser();
    const newCredentials = new GoogleAuthProvider();
    await linkWithRedirect(currentUser, newCredentials);
    return true;
  } catch (error) {
    console.debug("Error adding google loging", error);
    return false;
  }
}

export async function unlinkSignInMethod(
  providerId: string
): Promise<UserInfo[] | undefined> {
  try {
    const currentUser = getCurrentUser();
    if (
      currentUser.providerData.length === 1 &&
      providerId === currentUser.providerData[0].providerId
    ) {
      throw new Error("User need to have at least one provider");
    }
    const res = await unlink(currentUser, providerId);
    return res.providerData;
  } catch (error) {
    console.debug("Error unlinking loging method", error);
    return undefined;
  }
}
