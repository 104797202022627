import { FC, MouseEvent } from "react";
import IconButtonMUI from "@mui/material/IconButton";
import Icon, { IconProps } from "../Icon";

interface IconButtonProps extends IconProps {
  onClick: (() => void) | ((event: MouseEvent<HTMLButtonElement>) => void);
  id?: string;
}

const IconButton: FC<IconButtonProps> = ({ variant, onClick, id }) => {
  return (
    <IconButtonMUI onClick={onClick} id={id}>
      <Icon variant={variant} />
    </IconButtonMUI>
  );
};

export default IconButton;
