import { FC, useState } from "react";
import { GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography, { TypographyTypeMap } from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DefaultComponentProps } from "@mui/material/OverridableComponent";
import { auth } from "../firebase";
import Icon from "../../components/Icon";
import Button from "../../components/Button";
import { LogInForm } from "./LogInForm";
import { SignUpForm } from "./SignUpForm";
import { ResetPasswordForm } from "./ResetPasswordForm";

const Copyright: FC<DefaultComponentProps<TypographyTypeMap>> = (props) => (
  <Typography variant="body2" color="text.secondary" align="center" {...props}>
    {"Copyright © "}
    <Link color="inherit" href="https://kasvulabs.com/">
      Kasvu Labs Oy
    </Link>{" "}
    {new Date().getFullYear()}
    {"."}
    <br />
    Photo by{" "}
    <a href="https://unsplash.com/@matthardy?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
      Matt Hardy
    </a>{" "}
    on{" "}
    <a href="https://unsplash.com/?utm_source=maind&utm_medium=referral&utm_content=creditCopyText">
      Unsplash
    </a>
  </Typography>
);

const theme = createTheme();

const provider = new GoogleAuthProvider();

type FormType = "main" | "logIn" | "signUp" | "resetPassword";

export const SignInSide: FC = () => {
  const [form, setForm] = useState<FormType>("main");

  const handleGoogleSubmit = async () => {
    await signInWithRedirect(auth, provider);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={8}
          sx={{
            backgroundImage:
              "url(https://source.unsplash.com/6ArTTluciuA/1920×1280)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              //flex: 1,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <Icon variant="lock" />
            </Avatar>
            <Box width="100%">
              {form === "main" ? (
                <>
                  <Typography
                    variant="h5"
                    sx={{ textAlign: "center", paddingBottom: 2 }}
                  >
                    Log in to maind
                  </Typography>
                  <Button
                    fullWidth
                    variant="primary"
                    onClick={() => setForm("logIn")}
                    label="log in"
                  />

                  <Typography
                    variant="h5"
                    sx={{ textAlign: "center", paddingY: 2 }}
                  >
                    Don't have an account?
                  </Typography>
                  <Button
                    fullWidth
                    variant="secondary"
                    onClick={() => setForm("signUp")}
                    label="sign up"
                  />
                </>
              ) : form === "resetPassword" ? (
                <ResetPasswordForm onReturn={() => setForm("logIn")} />
              ) : (
                <>
                  <Typography
                    component="h1"
                    variant="h5"
                    sx={{ textAlign: "center" }}
                  >
                    {form === "logIn" ? "Log in to maind" : "Sign up for maind"}
                  </Typography>
                  <Box>
                    {form === "logIn" ? <LogInForm /> : <SignUpForm />}
                    <Typography
                      variant="h6"
                      sx={{ textAlign: "center", paddingY: 1 }}
                    >
                      OR
                    </Typography>
                    <Button
                      type="button"
                      fullWidth
                      variant="secondary"
                      onClick={handleGoogleSubmit}
                      leftIcon={<Icon variant="google" />}
                      label={`${
                        form === "logIn" ? "Log In " : "Sign Up "
                      } with Google`}
                    />

                    <Grid container>
                      <Grid item xs>
                        {form === "logIn" && (
                          <Link
                            component="button"
                            variant="body2"
                            onClick={(e) => {
                              e.preventDefault();
                              setForm("resetPassword");
                            }}
                          >
                            Forgot password?
                          </Link>
                        )}
                      </Grid>
                      <Grid item>
                        <Link
                          component="button"
                          onClick={() =>
                            setForm(form === "logIn" ? "signUp" : "logIn")
                          }
                          variant="body2"
                        >
                          {form === "logIn"
                            ? "Don't have an account? Sign Up"
                            : "Already have an account? Sign In"}
                        </Link>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </Box>
            <Copyright sx={{ mt: 5 }} />
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
