import React, { FC } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useProfile } from "../hooks/useAppData";
import { useAppSettings } from "../hooks/useAppSettings";
import { Home } from "./Home";
import UnderMaintenance from "../components/UnderMaintenance";
import Onboarding from "./Onboarding";

export const Main: FC = () => {
  const { userAttributes } = useProfile();
  const appSettings = useAppSettings();

  if (appSettings === undefined || userAttributes === null) {
    return (
      <Box
        component="main"
        display="flex"
        flex={1}
        justifyContent="center"
        alignItems="center"
        height="100%"
        paddingBottom={20}
      >
        <CircularProgress size={100} />
      </Box>
    );
  }

  if (appSettings.underMaintenance) {
    return <UnderMaintenance />;
  }

  if (userAttributes === undefined) {
    return (
      <Box component="main" flex={1} paddingY={2} paddingX={3} maxWidth={1440}>
        <Onboarding />
      </Box>
    );
  }

  if (!userAttributes.approved) {
    return (
      <Box component="main" flex={1} paddingY={2} paddingX={3} maxWidth={1440}>
        <Pending />
      </Box>
    );
  }

  return <Home />;
};

const Pending: FC = () => {
  return (
    <Box
      component="main"
      display="flex"
      flex={1}
      justifyContent="center"
      alignItems="center"
      height="100%"
      paddingBottom={20}
    >
      <Typography>Waiting for approval</Typography>
    </Box>
  );
};
