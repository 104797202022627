import { FC } from "react";
import ChipMUI from "@mui/material/Chip";
import { THEME } from "../../theme";

type ChipVariant = "primary" | "secondary" | "custom";

const getMuiVariant = (variant: ChipVariant) => {
  switch (variant) {
    case "primary":
      return "filled";
    case "secondary":
      return "outlined";
    default:
      return "filled";
  }
};

const getColor = (variant: ChipVariant) => {
  switch (variant) {
    case "primary":
      return "primary";
    case "secondary":
      return "default";
    default:
      return "default";
  }
};

interface ChipProps {
  label: string;
  variant: ChipVariant;
  backgroundColor?: string;
  onClick?: () => void;
  size: "small" | "medium";
}

const Chip: FC<ChipProps> = ({
  label,
  backgroundColor,
  variant,
  onClick,
  size,
}) => {
  return (
    <ChipMUI
      sx={{
        backgroundColor:
          variant === "custom" ? backgroundColor : getColor(variant),
        minWidth: "120px",
        textTransform: "capitalize",
        color: variant === "custom" ? THEME.colors.grey000 : undefined,
      }}
      variant={getMuiVariant(variant)}
      color={getColor(variant)}
      label={label}
      onClick={onClick}
      size={size}
    />
  );
};

export default Chip;
