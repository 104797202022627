import { FC, useEffect, useState, useMemo } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import { FeedbackQuestion, FeedbackCore } from "common/types/Feedback";
import Button from "../Button";
import Icon from "../Icon";
import TextInput from "../TextInput";
import { ConfirmationDialog } from "../ConfirmationDialog";
import FormCard, {
  contentContainerStyles,
  actionContainerStyles,
} from "../FormCard";
import { QuestionPicker } from "./QuestionPicker";

interface FeedbackFormProps {
  onSend: () => Promise<void>;
  onDelete: () => Promise<void>;
  onUpdate: (feedback: FeedbackCore) => Promise<void>;
  onTemplate?: (feedback: FeedbackCore) => Promise<void>;
  feedback: FeedbackCore;
  type: "draft" | "template";
}

const FeedbackForm: FC<FeedbackFormProps> = ({
  onSend,
  onDelete,
  onUpdate,
  onTemplate,
  feedback,
  type,
}) => {
  const [description, setDescription] = useState<string>(feedback.description);
  const [questions, setQuestions] = useState<FeedbackQuestion[]>(
    feedback.questions
  );
  const [saving, setSaving] = useState<boolean>(false);
  const [savingTemplate, setSavingTemplate] = useState<boolean>(false);
  const [showSendConfirmation, setSendConfirmation] = useState<boolean>(false);
  const [showDiscardConfirmation, setDiscardConfirmation] =
    useState<boolean>(false);

  const [changes, setChanges] = useState<boolean>(false);

  const handleApprove = async () => {
    setSaving(true);
    await onSend();
    setSaving(false);
  };

  const handleDelete = async () => {
    setSaving(true);
    await onDelete();
    setSaving(false);
  };

  const handleTemplate = async () => {
    setSavingTemplate(true);
    const newTemplate: FeedbackCore = {
      description: description,
      questions: questions,
    };
    if (onTemplate) {
      await onTemplate(newTemplate);
    }
    setSavingTemplate(false);
  };

  const handleUpdate = async () => {
    setSaving(true);
    const updatedFeedback: FeedbackCore = {
      description: description,
      questions: questions,
    };
    await onUpdate(updatedFeedback);
    setSaving(false);
  };

  const questionChanges = useMemo(() => {
    const updates =
      feedback.questions.find((q) =>
        questions.find(
          (qu) =>
            qu.id === q.id &&
            (qu.binary !== q.binary || qu.question !== q.question)
        )
      ) !== undefined;
    const newQuestions =
      questions.find(
        (q) => feedback.questions.find((qu) => qu.id === q.id) === undefined
      ) !== undefined;
    return updates || newQuestions;
  }, [feedback.questions, questions]);

  useEffect(() => {
    setChanges(feedback.description !== description || questionChanges);
  }, [feedback.description, description, questionChanges]);

  return (
    <>
      <FormCard>
        <Box sx={contentContainerStyles}>
          <Box pb={1} mt={2}>
            <TextInput
              label="Title"
              placeholder="What are these questions about?"
              required
              value={description}
              onChange={setDescription}
            />
          </Box>
          <QuestionPicker
            questions={questions}
            onChange={(qs: FeedbackQuestion[]) => {
              setQuestions(qs);
            }}
          />
        </Box>
        <Box sx={actionContainerStyles}>
          {type === "draft" && (
            <Button
              label="create new template"
              size="medium"
              variant="primary"
              leftIcon={<Icon variant="addBookmark" />}
              onClick={handleTemplate}
              disabled={saving || savingTemplate}
              loading={savingTemplate}
            />
          )}
          <Button
            label={`save ${type}`}
            size="medium"
            variant="success"
            leftIcon={<Icon variant="save" />}
            onClick={handleUpdate}
            disabled={saving || savingTemplate || !changes}
          />
          <Button
            label="delete"
            size="medium"
            variant="danger"
            leftIcon={<Icon variant="delete" />}
            disabled={saving || savingTemplate}
            onClick={() => setDiscardConfirmation(true)}
          />
          <Tooltip
            title={
              changes
                ? `Please save ${type} before sending`
                : questions.length === 0
                ? "Please add at least one question before sending"
                : !!questions.find((q) => q.question === "")
                ? "Please make sure the questions are not empty before sending"
                : ""
            }
          >
            <span>
              <Button
                label="send"
                size="medium"
                variant="primary"
                leftIcon={<Icon variant="send" />}
                disabled={
                  saving ||
                  savingTemplate ||
                  changes ||
                  questions.length === 0 ||
                  questions.find((q) => q.question === "") !== undefined ||
                  description === ""
                }
                onClick={() => setSendConfirmation(true)}
              />
            </span>
          </Tooltip>
        </Box>
      </FormCard>
      <ConfirmationDialog
        title={`Send feedback request?`}
        content="Send as a message to user?"
        approveText="Send"
        open={showSendConfirmation}
        handleClose={() => {
          setSendConfirmation(false);
        }}
        approve={handleApprove}
      />
      <ConfirmationDialog
        title={`Discard feedback request?`}
        content={`Discard feedback request and its contents?`}
        approveText="Discard"
        open={showDiscardConfirmation}
        handleClose={() => {
          setDiscardConfirmation(false);
        }}
        approve={handleDelete}
        buttonVariant="danger"
      />
    </>
  );
};

export default FeedbackForm;
