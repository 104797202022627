import React, { FC, useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import { changeEmail } from "../../services/user";
import { useProfile } from "../../hooks/useProfile";
import Snackbar, { SnackbarStatus } from "../../components/Snackbar";
import Icon from "../../components/Icon";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { auth } from "../firebase";
import { haveProvider } from "./utils";

const content = {
  create: {
    title: "Create Password",
    intro:
      "Create a new password to update email. You have to create a new email because you are only connected with google at the moment. After creation you can use this email/password pair to login into the app.",
    buttonLabel: "create password and update email",
  },
  confirm: {
    title: "Corfirm Password",
    intro: "Type your current password to confirm email update.",
    buttonLabel: "confirm",
  },
};

interface ConfirmPwdDialogProps {
  open: boolean;
  closeDialog: () => void;
  newEmail: string;
}

const ConfirmPwdDialog: FC<ConfirmPwdDialogProps> = ({
  open,
  closeDialog,
  newEmail,
}) => {
  const { setProviders, providers } = useProfile();
  const [pwd, setPwd] = useState<string>("");
  const [confirmPwd, setConfirmPwd] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [snackStatus, setSnackStatus] = useState<SnackbarStatus | undefined>(
    undefined
  );

  const contentKey =
    haveProvider("password", providers) !== undefined ? "confirm" : "create";

  const handleEmailChange = async () => {
    setLoading(true);
    const res = await changeEmail(newEmail, pwd);
    setSnackStatus({ severity: res.severity, message: res.message });
    const updatedUser = auth.currentUser;
    if (res.severity && updatedUser) {
      setProviders(
        updatedUser.providerData.map((p) => ({
          providerId: p.providerId,
          email: p.email ?? "",
        }))
      );
      setPwd("");
      closeDialog();
    }
    setLoading(false);
  };

  return (
    <>
      <Dialog open={open} onClose={closeDialog} fullWidth>
        <Box
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
          marginX={2}
        >
          <Icon variant="lock" size="large" />
          <h3>{content[contentKey].title}</h3>
        </Box>
        <Box component="form">
          <DialogContent>
            <DialogContentText>{content[contentKey].intro}</DialogContentText>
            <TextInput
              label="Password"
              onChange={setPwd}
              value={pwd}
              disabled={loading}
              type="password"
              required
            />
            {contentKey === "create" && (
              <TextInput
                label="Confirm password"
                onChange={setConfirmPwd}
                value={confirmPwd}
                disabled={loading}
                type="password"
                required
              />
            )}
            <Box marginTop={2} display="flex" justifyContent="flex-end"></Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant="tertiary"
              onClick={closeDialog}
              disabled={loading}
              label="cancel"
              size="medium"
            />
            <Button
              variant="primary"
              onClick={handleEmailChange}
              loading={loading}
              disabled={!pwd || loading}
              label={content[contentKey].buttonLabel}
              size="medium"
              type="submit"
            />
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        message={snackStatus?.message}
        custom={true}
        duration={10000}
        severity={snackStatus?.severity}
        setVisibility={() => setSnackStatus(undefined)}
      />
    </>
  );
};

export default ConfirmPwdDialog;
