import { getEmotionCategories } from "common/dist/types/LogEntry";
import { HeatmapDataPoint, HeatmapUnit } from "./HeatmapTypes";
import { THEME } from "../../../../../theme";

export const emotionColors = [THEME.colors.grey200];
emotionColors.push(...getEmotionCategories("light").flatMap((e) => e.color));
emotionColors.push(THEME.colors.blue400);

export const emotionToColorIndex = [
  {
    displayName: "No value",
    index: 0,
  },
];
emotionToColorIndex.push(
  ...getEmotionCategories("light").map((e) => ({
    displayName: e.category,
    index: emotionColors.indexOf(e.color),
  }))
);

const singleColorScheme = [
  THEME.colors.grey200,
  THEME.colors.blue150,
  THEME.colors.blue300,
  THEME.colors.blue600,
];

const getDiscreteColorScheme = (maxValue: number) => {
  const colors = [singleColorScheme[0]];

  while (colors.length <= maxValue) {
    colors.push(
      singleColorScheme[
        Math.ceil(colors.length / (maxValue / (singleColorScheme.length - 1)))
      ]
    );
  }

  return colors;
};

export const getColorSchemeForData = (
  data: HeatmapDataPoint[],
  unit: HeatmapUnit
) =>
  unit === HeatmapUnit.LoggedEmotionCategories
    ? emotionColors.slice(
        Math.min(...data.map((d) => d.data)),
        Math.max(...data.map((d) => d.data)) + 1
      )
    : getDiscreteColorScheme(Math.max(...data.map((d) => d.data)));
