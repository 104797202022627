import { FC } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { HomeworkDraft } from "common/types/Homework";
import { ItemCard } from "../../../../../components/ItemCard";
import Icon from "../../../../../components/Icon";

interface DraftCardProps {
  draft: HomeworkDraft;
}

const DraftCard: FC<DraftCardProps> = ({ draft }) => {
  const theme = useTheme();
  return (
    <ItemCard color={theme.palette.success.light}>
      <Box display="flex" flexDirection="row">
        <Typography
          variant="caption"
          pb={1}
          color={theme.palette.error.contrastText}
        >
          [Draft]
        </Typography>
        <Box flexGrow={1} />
        <Icon size="small" variant="edit" />
      </Box>
      {draft.title && (
        <Typography variant="subtitle2">{draft.title}</Typography>
      )}
      <Typography whiteSpace="pre-line">{draft.description}</Typography>
    </ItemCard>
  );
};

export default DraftCard;
