import { FC, useState } from "react";
import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import { PrivateProfessionalProfileV2 } from "common/types/Therapist";

import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import AddRemoveButtonGroup from "../../components/AddRemoveButtonGroup";
import Snackbar, { SnackbarStatus } from "../../components/Snackbar";
import Icon from "../../components/Icon";
import FormCard, {
  actionContainerStyles,
  contentContainerStyles,
} from "../../components/FormCard";
import { saveProfile } from "../../services/user";
import { validateProfile } from "../../utils";

import { listStyles } from "./styles";

interface PersonalInfoProps {
  initProfile: PrivateProfessionalProfileV2;
}

const PersonalInfo: FC<PersonalInfoProps> = ({ initProfile }) => {
  const [localProfile, setLocalProfile] =
    useState<Partial<PrivateProfessionalProfileV2>>(initProfile);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackStatus, setSnackStatus] = useState<SnackbarStatus | undefined>(
    undefined
  );

  const handleUpdate = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const newProfile = validateProfile(localProfile);
    if (newProfile) {
      try {
        const res = await saveProfile(newProfile);
        if (res) {
          setSnackStatus({
            status: true,
            message: "Profile saved successfully",
          });
        } else {
          setSnackStatus({
            status: false,
            message: "Sorry could not save profile, please try again",
          });
        }
      } catch (err) {
        console.debug(err);
      } finally {
        setLoading(false);
      }
    }
  };

  const setValue =
    (prop: keyof PrivateProfessionalProfileV2) =>
    (value: PrivateProfessionalProfileV2[typeof prop]) =>
      setLocalProfile((profile) => ({ ...profile, [prop]: value }));

  const setExperience =
    (
      index: number,
      prop: keyof PrivateProfessionalProfileV2["experience"][0]
    ) =>
    (value: PrivateProfessionalProfileV2["experience"]["0"][typeof prop]) =>
      setLocalProfile((profile) => ({
        ...profile,
        experience: profile.experience?.map((e, i) =>
          i === index ? { ...e, [prop]: value } : e
        ),
      }));

  const removeExperience = () =>
    setLocalProfile((profile) => ({
      ...profile,
      experience: profile.experience?.slice(0, profile.experience.length - 1),
    }));

  const addExperience = () =>
    setLocalProfile((profile) => ({
      ...profile,
      experience: [
        ...(profile.experience ?? []),
        { company: "", years: "", title: "" },
      ],
    }));

  const setEducation =
    (index: number, prop: keyof PrivateProfessionalProfileV2["education"][0]) =>
    (value: PrivateProfessionalProfileV2["education"]["0"][typeof prop]) =>
      setLocalProfile((profile) => ({
        ...profile,
        education: profile.education?.map((e, i) =>
          i === index ? { ...e, [prop]: value } : e
        ),
      }));

  const removeEducation = () =>
    setLocalProfile((profile) => ({
      ...profile,
      education: profile.education?.slice(0, profile.education.length - 1),
    }));

  const addEducation = () =>
    setLocalProfile((profile) => ({
      ...profile,
      education: [
        ...(profile.education ?? []),
        { institution: "", degree: "" },
      ],
    }));
  return (
    <FormCard component="form" onSubmit={handleUpdate}>
      <Box sx={contentContainerStyles}>
        <Box display="flex" flexDirection="row" gap={1} alignItems="center">
          <Icon variant="userCircle" size="large" />
          <h2>Personal info</h2>
        </Box>
        <TextInput
          label="First name last name"
          value={localProfile.name ?? ""}
          onChange={setValue("name")}
          disabled={loading}
          required
          id="name"
        />
        <Tooltip title="This will add your email to your professional profile, available to your clients in their app.">
          <FormControlLabel
            label="Make email available to my clients"
            control={
              <Checkbox
                checked={!!localProfile.showEmail || false}
                onChange={(_, checked) => setValue("showEmail")(checked)}
              />
            }
          />
        </Tooltip>
        <TextInput
          label="Telephone"
          value={localProfile.number ?? ""}
          onChange={setValue("number")}
          disabled={loading}
        />
        <Tooltip title="This will add your number to your professional profile, available to your clients in their app.">
          <FormControlLabel
            label="Make number available to my clients"
            control={
              <Checkbox
                checked={!!localProfile.showNumber || false}
                onChange={(_, checked) => setValue("showNumber")(checked)}
              />
            }
          />
        </Tooltip>
        <TextInput
          label="Title, training, etc."
          value={localProfile.title ?? ""}
          onChange={setValue("title")}
          disabled={loading}
          required
        />
        <Box
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
          paddingTop={2}
        >
          <Icon variant="work" size="large" />
          <h2>Experience</h2>
        </Box>
        <Box component="ul" sx={listStyles}>
          {localProfile.experience?.map((e, i) => (
            <Box
              key={`experience-${i}`}
              component="li"
              sx={{ paddingTop: i !== 0 ? 3 : 0 }}
            >
              <TextInput
                label="Company"
                value={e?.company ?? ""}
                onChange={setExperience(i, "company")}
                disabled={loading}
                required
              />
              <TextInput
                label="Time spent"
                value={e?.years ?? ""}
                placeholder="For example 6 months"
                onChange={setExperience(i, "years")}
                disabled={loading}
                required
              />
              <TextInput
                label="Title, training, etc."
                value={e?.title ?? ""}
                onChange={setExperience(i, "title")}
                disabled={loading}
                required
                name={`experience-title${i}`}
              />
            </Box>
          ))}
        </Box>
        <AddRemoveButtonGroup
          label="experience"
          add={addExperience}
          remove={
            localProfile.experience && localProfile.experience.length > 0
              ? removeExperience
              : undefined
          }
        />
        <Box
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
          paddingTop={2}
        >
          <Icon variant="edu" size="large" />
          <h2>Education</h2>
        </Box>
        <Box component="ul" sx={listStyles}>
          {localProfile.education?.map((e, i) => (
            <Box
              key={`education-${i}`}
              component="li"
              sx={{ paddingTop: i !== 0 ? 3 : 0 }}
            >
              <TextInput
                label="Institution"
                placeholder="Name of the institution"
                value={e?.institution ?? ""}
                onChange={setEducation(i, "institution")}
                disabled={loading}
                required
              />
              <TextInput
                label="Degree"
                value={e?.degree ?? ""}
                onChange={setEducation(i, "degree")}
                disabled={loading}
                required
              />
            </Box>
          ))}
        </Box>
        <AddRemoveButtonGroup
          label="education"
          add={addEducation}
          remove={
            localProfile.education && localProfile.education.length > 0
              ? removeEducation
              : undefined
          }
        />
      </Box>
      <Box sx={actionContainerStyles}>
        <Button
          variant="primary"
          loading={loading}
          disabled={!validateProfile(localProfile)}
          label="save"
          size="medium"
          type="submit"
        />
      </Box>
      <Snackbar
        message={snackStatus?.message}
        custom={true}
        duration={5000}
        severity={snackStatus?.status ? "success" : "error"}
        setVisibility={() => setSnackStatus(undefined)}
      />
    </FormCard>
  );
};

export default PersonalInfo;
