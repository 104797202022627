import { FC, useState } from "react";
import Box from "@mui/material/Box";

import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import Snackbar, { SnackbarStatus } from "../../components/Snackbar";
import Icon from "../../components/Icon";
import Chip from "../../components/Chip";
import FormCard, {
  contentContainerStyles,
  actionContainerStyles,
} from "../../components/FormCard";
import { THEME } from "../../theme";
import { sendEmailVerificationLink } from "../../services/auth";
import { INTERNAL_ERROR } from "../../services/constants";
import ConfirmPwdDialog from "./ConfirmPwdDialog";

interface ChangePwdProps {
  primaryEmail: string;
  emailVerified: boolean;
}

const ChangeEmail: FC<ChangePwdProps> = ({ primaryEmail, emailVerified }) => {
  const [newEmail, setNewEmail] = useState<string>(primaryEmail);
  const [showPwdConfirm, setShowPwdCofirm] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);
  const [snackStatus, setSnackStatus] = useState<SnackbarStatus | undefined>(
    undefined
  );

  const mode =
    !emailVerified && primaryEmail === newEmail ? "verify" : "update";

  const handleEmailChange = async (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    if (mode === "verify") {
      setLoading(true);
      const res = await sendEmailVerificationLink();
      if (res) {
        setSnackStatus({
          status: true,
          message: "New verification email send",
        });
      } else {
        setSnackStatus({ status: false, message: INTERNAL_ERROR });
      }
      setLoading(false);
    } else {
      setShowPwdCofirm(true);
    }
  };

  return (
    <>
      <FormCard>
        <Box sx={contentContainerStyles}>
          <Box display="flex" flexDirection="row" gap={1} alignItems="center">
            <Icon variant="email" size="large" />
            <h2>Email</h2>
          </Box>
          <TextInput
            label=""
            placeholder="Type your new email"
            onChange={setNewEmail}
            value={newEmail}
            disabled={loading}
            type="text"
            required
          />
          <Box display="flex" flexDirection="row" gap={1} alignItems="center">
            <Chip
              label={emailVerified ? "Email verified" : "Please verify email"}
              variant="custom"
              backgroundColor={
                emailVerified ? THEME.colors.green500 : THEME.colors.yellow500
              }
              size="small"
            />
          </Box>
        </Box>
        <Box sx={actionContainerStyles}>
          <Button
            onClick={handleEmailChange}
            variant="primary"
            loading={loading}
            disabled={(newEmail === primaryEmail && emailVerified) || loading}
            label={
              mode === "verify" ? "Resend Verification Email" : "update email"
            }
            size="medium"
          />
        </Box>
        <Snackbar
          message={snackStatus?.message}
          custom={true}
          duration={5000}
          severity={snackStatus?.status ? "success" : "error"}
          setVisibility={() => setSnackStatus(undefined)}
        />
      </FormCard>
      <ConfirmPwdDialog
        open={showPwdConfirm && !loading}
        closeDialog={() => setShowPwdCofirm(false)}
        newEmail={newEmail}
      />
    </>
  );
};

export default ChangeEmail;
