import { Box, SxProps, Typography } from "@mui/material";
import { FC } from "react";

interface LabelCircleProps {
  label: string;
  count: number;
  color: string;
  textStyle?: SxProps;
}

export const LabelCircle: FC<LabelCircleProps> = ({
  label,
  count,
  color,
  textStyle,
}) => (
  <Box display="flex" flexDirection="row" flexWrap="nowrap" alignItems="center">
    <Box
      style={{
        width: 36,
        height: 36,
        background: color,

        borderWidth: 4,
        borderStyle: "solid",
        borderColor: `${color}90`,
        borderRadius: 50,
        padding: 5,
        backgroundClip: "padding-box",

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography color="white">{count}</Typography>
    </Box>

    <Typography textTransform="capitalize" sx={textStyle}>
      {label}
    </Typography>
  </Box>
);
