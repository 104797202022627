import { FC, useState } from "react";
import { Box, Chip, Typography, useTheme } from "@mui/material";
import { HomeworkTemplate } from "common/dist/types/Homework";
import { ThumbnailGroup } from "../../../../components/ThumbnailGroup";
import { ConfirmationDialog } from "../../../../components/ConfirmationDialog";
import Button from "../../../../components/Button";
import Icon from "../../../../components/Icon";
import { functionsClient } from "../../../firebase";
import { styles } from "./styles";

interface HomeworkTemplateDetailsProps {
  template: HomeworkTemplate;
  assigneeId: string;
  close: () => void;
}

export const HomeworkTemplateDetails: FC<HomeworkTemplateDetailsProps> = ({
  template,
  assigneeId,
  close,
}) => {
  const theme = useTheme();
  const [showSendConfirmation, setSendConfirmation] = useState(false);

  const handleSendTemplate = async () => {
    await functionsClient.sendHomeworkFromTemplate({
      templateId: template.id,
      patientId: assigneeId,
    });
    setSendConfirmation(false);
    close();
  };

  return (
    <Box p={1} pt={2}>
      <ConfirmationDialog
        title={"Send homework?"}
        content="Send as a message to user?"
        approveText="Send"
        open={showSendConfirmation}
        handleClose={() => setSendConfirmation(false)}
        approve={handleSendTemplate}
        onError={
          "Error sending homework, please try again later." +
          "\n\nIf the problem persists, you can request assistance " +
          "through the feedback form available in the left navigation menu."
        }
      />
      <Button
        variant="tertiary"
        onClick={close}
        leftIcon={<Icon variant="arrowLeft" />}
        size="medium"
        label="back"
      />
      <Box mb={2}>
        <Typography variant="caption" pb={1}>
          BY {template.createdBy}
        </Typography>
        <Typography variant="h6">{template.title}</Typography>
        <Typography whiteSpace="pre-line">{template.description}</Typography>
        {template.images && <ThumbnailGroup images={template.images} />}
      </Box>

      <Box mb={template.labels && template.labels.length > 0 ? 4 : 0}>
        {template.labels?.map((label) => (
          <Chip
            key={label}
            label={label}
            variant="outlined"
            sx={{ ...styles.chip, color: theme.palette.grey[700] }}
          />
        ))}
      </Box>

      <Button
        variant="primary"
        color="info"
        onClick={() => setSendConfirmation(true)}
        leftIcon={<Icon variant="send" />}
        size="medium"
        label="send"
      />
    </Box>
  );
};
