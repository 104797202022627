import {
  createContext,
  FC,
  useEffect,
  useState,
  PropsWithChildren,
} from "react";
import { onAuthStateChanged } from "firebase/auth";
import { Homework, HomeworkTemplate } from "common/dist/types/Homework";
import { auth } from "../features/firebase";
import {
  subscribeToHomework,
  subscribeToOwnHomeworkTemplates,
} from "../services/homeworks";
import { useAssignee } from "../hooks/useAssignee";

interface HomeworksContextType {
  homeworks: Homework[] | undefined;
  homeworkTemplates: HomeworkTemplate[] | undefined;
}

export const HomeworksContext = createContext<HomeworksContextType | undefined>(
  undefined
);

export const HomeworksProvider: FC<PropsWithChildren> = ({ children }) => {
  const [homeworks, setHomeworks] = useState<Homework[] | undefined>(undefined);
  const [homeworkTemplates, setHomeworkTemplates] = useState<
    HomeworkTemplate[] | undefined
  >(undefined);
  const { assignee } = useAssignee();

  onAuthStateChanged(auth, async (user) => {
    if (user === null) {
      setHomeworks(undefined);
    }
  });

  useEffect(() => {
    if (assignee) {
      return subscribeToHomework(setHomeworks, assignee.uid);
    } else {
      setHomeworks(undefined);
    }
  }, [assignee]);

  useEffect(
    () => subscribeToOwnHomeworkTemplates(setHomeworkTemplates),
    [assignee]
  );

  const context: HomeworksContextType = {
    homeworks: homeworks,
    homeworkTemplates: homeworkTemplates,
  };
  return (
    <HomeworksContext.Provider value={context}>
      {children}
    </HomeworksContext.Provider>
  );
};
