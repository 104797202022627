export type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

export function isPresent<T>(t: T | undefined | null): t is T {
  return t !== undefined && t !== null;
}

export function allFieldsPresent<T extends object>(
  t: T | RequiredNonNull<T>
): t is RequiredNonNull<T> {
  return typeof t === "object"
    ? Boolean(Object.values(t).every(isPresent))
    : isPresent(t);
}

export function fieldIsDefined<T, K extends keyof T>(
  f: K
): (o: T) => o is T & { [P in K]: NonNullable<T[K]> } {
  return ((t: T) => t[f] !== null) as (
    t: T
  ) => t is T & { [P in K]: NonNullable<T[K]> };
}

export type RequiredNonNull<T> = {
  [P in keyof T]-?: NonNullable<T[P]>;
};

export function assertNever(x: never): never {
  throw new Error("Unexpected object: " + x);
}

// Random ingeter that is >= min and < max
export const randomBetween = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min) + min);

export function isKeyOfObject<T extends object>(
  key: string | number | symbol,
  obj: T
): key is keyof T {
  return key in obj;
}

export const sleep = (wait: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, wait));
