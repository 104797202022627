import { FC, useEffect, useMemo, useState } from "react";
import { Box, MenuItem, Select, Typography } from "@mui/material";
import { isSameDay } from "date-fns";
import { getStringArrayCounts } from "common/dist/feat/logEntry";
import { useEntries } from "../../../../../hooks/useEntries";
import { RadioButtonGroup } from "../../../../../components/RadioButtonGroup";
import Button from "../../../../../components/Button";
import Icon from "../../../../../components/Icon";
import { THEME } from "../../../../../theme";
import {
  getDataForHeatmapUnit,
  getDatesForPreviousYear,
} from "./heatmapDataUtils";
import { HeatmapLegend } from "./HeatmapLegend";
import { Heatmap } from "./Heatmap";
import { HeatmapDataPoint, HeatmapUnit, heatmapUnits } from "./HeatmapTypes";
import { getColorSchemeForData } from "./heatmapColorUtils";

interface HeatmapViewProps {
  unit: HeatmapUnit;
  setUnit: (unit: HeatmapUnit) => void;
  selectedSymptom?: string;
  setSelectedSymptom: (symptom: string) => void;
  selectedCategories: string[];
  setSelectedCategories: (categories: string[]) => void;
}

export const HeatmapView: FC<HeatmapViewProps> = ({
  unit,
  setUnit,
  selectedSymptom,
  setSelectedSymptom,
  selectedCategories,
  setSelectedCategories,
}) => {
  const { entries } = useEntries();
  const [width, setWidth] = useState(Math.max(400, window.innerWidth - 288));
  const [data, setData] = useState<HeatmapDataPoint[]>();
  const [colorScheme, setColorScheme] = useState<string[]>();
  const [showAllSymptoms, setShowAllSymptoms] = useState(false);

  const updateSize = () => {
    setWidth(Math.max(400, window.innerWidth - 288));
  };

  const rawData = useMemo(
    () =>
      getDatesForPreviousYear().map((d) => ({
        key: d,
        date: d,
        data: entries
          ? entries
              .filter((e) => isSameDay(d, e.timestamp))
              .map((e) => ({
                ...e,
                symptoms:
                  e.symptoms.length > 0
                    ? e.symptoms
                    : [{ label: "no symptoms" }],
              }))
          : [],
      })),
    [entries]
  );

  const symptomOptions = useMemo(
    () =>
      getStringArrayCounts(
        entries
          ? entries.flatMap((e) =>
              e.symptoms.length > 0
                ? e.symptoms.map((s) => s.label)
                : ["no symptoms"]
            )
          : []
      ).map((s) => ({
        value: s.element,
        label: `${s.element.replace(/^\w/, (c) => c.toUpperCase())} (${
          s.count
        })`,
      })),
    [entries]
  );

  useEffect(() => {
    const heatmapData = getDataForHeatmapUnit(
      rawData,
      unit,
      selectedCategories,
      selectedSymptom ?? symptomOptions.at(0)?.value
    );

    setColorScheme(getColorSchemeForData(heatmapData, unit));
    setData(heatmapData);
  }, [rawData, unit, selectedSymptom, selectedCategories, symptomOptions]);

  useEffect(() => {
    window.addEventListener("resize", updateSize);

    return () => {
      window.removeEventListener("resize", updateSize);
    };
  }, []);

  if (!entries) {
    return (
      <Box>
        <Typography>No data</Typography>
      </Box>
    );
  }

  return (
    <Box>
      <Select
        sx={{ width: 300, alignSelf: "flex-start", mb: 2 }}
        value={unit}
        onChange={(event) => setUnit(Number(event.target.value))}
        inputProps={{ "aria-label": "Without label" }}
      >
        {Object.entries(heatmapUnits).map(([value, text]) => (
          <MenuItem key={value} value={value}>
            {text}
          </MenuItem>
        ))}
      </Select>
      {unit === HeatmapUnit.PhysicalSymptoms ? (
        <>
          <RadioButtonGroup
            values={symptomOptions.slice(0, showAllSymptoms ? undefined : 10)}
            selectedValue={selectedSymptom ?? symptomOptions.at(0)?.value}
            onChange={setSelectedSymptom}
          />
          {symptomOptions.length > 10 && (
            <Button
              label={showAllSymptoms ? "Show less" : "Show all"}
              size="medium"
              onClick={() => setShowAllSymptoms(!showAllSymptoms)}
              variant="tertiary"
              leftIcon={
                <Icon
                  variant={
                    showAllSymptoms ? "arrowUpCircle" : "arrowDownCircle"
                  }
                />
              }
              color={THEME.colors.grey600}
            />
          )}
        </>
      ) : (
        <></>
      )}
      {data && (
        <Box>
          <Heatmap
            data={data}
            unit={unit}
            colorScheme={colorScheme}
            width={width}
          />
          <HeatmapLegend
            data={data}
            unit={unit}
            colorScheme={colorScheme}
            width={width}
            selectedCategories={selectedCategories}
            onClick={(displayName: string) =>
              setSelectedCategories(
                selectedCategories.includes(displayName)
                  ? selectedCategories.filter((e) => e !== displayName)
                  : selectedCategories.concat(displayName)
              )
            }
          />
        </Box>
      )}
    </Box>
  );
};
