import { FC, useEffect, useMemo, useState } from "react";
import { useLocation } from "wouter";
import Box from "@mui/material/Box";
import { FeedbackDraft } from "common/dist/types/Feedback";
import { useAssigneeFeedbacks } from "../../../../hooks/useAppData";
import { useAssignee } from "../../../../hooks/useAssignee";
import Button from "../../../../components/Button";
import Snackbar, { SnackbarStatus } from "../../../../components/Snackbar";
import FeedbackCard from "../../../../components/FeedbackCard";
import { createDraft, subscribeToDrafts } from "../../../../services/feedback";
import { INTERNAL_ERROR } from "../../../../services/constants";

export const Feedbacks: FC = () => {
  const [, setLocation] = useLocation();
  const { assignee } = useAssignee();
  const assigneeId = assignee?.uid ?? "";
  const { feedbacks } = useAssigneeFeedbacks(assigneeId);
  const [snackStatus, setSnackStatus] = useState<SnackbarStatus | undefined>(
    undefined
  );

  const feedbacksWithUnseen = useMemo(
    () =>
      feedbacks?.map((f) => ({
        ...f,
        unseen:
          !!f.feedback.lastUpdatedByPatient &&
          (!f.feedback.lastSeenByTherapist ||
            f.feedback.lastUpdatedByPatient > f.feedback.lastSeenByTherapist),
      })),
    [feedbacks]
  );

  const [loading, setLoading] = useState(false);

  const [drafts, setDrafts] =
    useState<{ draft: FeedbackDraft; id: string }[]>();

  useEffect(
    () => subscribeToDrafts(assigneeId, setDrafts),
    [assigneeId, setDrafts]
  );

  const createNewDraft = async () => {
    setLoading(true);
    const draftId = await createDraft(assigneeId);
    if (draftId) {
      setLocation(`/new/${draftId}`);
    } else {
      setSnackStatus({ status: false, message: INTERNAL_ERROR });
    }
    setLoading(false);
  };

  return (
    <Box pt={1} display="flex" flexDirection="column" gap={1}>
      <Box py={1} display="flex" gap={2}>
        <Button
          onClick={createNewDraft}
          variant="primary"
          loading={loading}
          label="New feedback request"
          size="medium"
        />
        <Button
          onClick={() => setLocation("/templates")}
          variant="secondary"
          label="Saved templates"
          size="medium"
        />
      </Box>
      {drafts?.map(({ draft, id }) => (
        <Box
          key={id}
          onClick={() => setLocation(`/new/${id}`)}
          sx={{ cursor: "pointer" }}
        >
          <FeedbackCard id={id} feedback={draft} variant="draft" />
        </Box>
      ))}
      {feedbacksWithUnseen?.map((f) => (
        <FeedbackCard
          key={f.id}
          id={f.id}
          feedback={f.feedback}
          variant="send"
          answers={f.feedback.answers}
          timestamp={f.feedback.timestamp}
        />
      ))}
      <Snackbar
        message={snackStatus?.message}
        custom={true}
        duration={5000}
        severity={snackStatus?.status ? "success" : "error"}
        setVisibility={() => setSnackStatus(undefined)}
      />
    </Box>
  );
};
