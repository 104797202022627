import { EmotionDescription } from "./LogEntry";

interface ClassifierEmotions {
  anger: number;
  sadness: number;
  fear: number;
  joy: number;
}

export interface ClassifierEmotionResult {
  topEmotion: {
    emotion: EmotionDescription;
    value: number;
  };
  topClassifiedEmotion: {
    emotion: ClassifierEmotion;
    value: number;
  };
}

export type ClassifierEmotion = keyof ClassifierEmotions;

export const ClassifierEmotionDescriptions: Record<
  ClassifierEmotion,
  { displayName: string; color: string }
> = {
  anger: {
    displayName: "Anger",
    color: "#E78AC3",
  },
  fear: {
    displayName: "Fear",
    color: "#66C2A5",
  },
  sadness: {
    displayName: "Sadness",
    color: "#8DA0CB",
  },
  joy: {
    displayName: "Joy",
    color: "#FC8D62",
  },
};
