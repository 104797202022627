import { FC } from "react";
import Box from "@mui/material/Box";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../firebase";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";

export const ResetPasswordForm: FC<{ onReturn: () => void }> = ({
  onReturn,
}) => {
  const handleCancel = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();
    onReturn();
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    sendPasswordResetEmail(auth, target.email.value)
      .catch(console.error)
      .finally(() => {
        alert(
          "If the provided email address is linked to an existing account, an email with a link to reset your password has been sent to that address. Please check your inbox to reset your password."
        );
        onReturn();
      });
  };

  return (
    <>
      <Typography component="h1" variant="h5">
        Reset password
      </Typography>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit}
        sx={{ mt: 1 }}
        style={{ display: "contents" }}
      >
        <TextInput
          required
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
        />
        <Button type="submit" fullWidth variant="primary" label="send" />
        <Link component="button" variant="body2" onClick={handleCancel}>
          Return
        </Link>
      </Box>
    </>
  );
};
