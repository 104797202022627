import { FC, useState } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Icon from "../../components/Icon";
import Button from "../../components/Button";
import BugReportForm from "./BugReportForm";

export const BugReportTab: FC = () => {
  const [reportSent, setReportSent] = useState(false);

  return (
    <>
      <h1>Send feedback to maind team</h1>
      <Divider sx={{ mt: 1, mb: 2 }} />
      {reportSent ? (
        <>
          <Box display="flex" flexDirection="row" gap={1} mb={3}>
            <Icon variant="chatComplete" />
            <Typography>Thank you! Your feedback has been received.</Typography>
          </Box>
          <Button
            variant="primary"
            onClick={() => setReportSent(false)}
            size="medium"
            label="send a new feedback"
          />
        </>
      ) : (
        <BugReportForm setReportSent={() => setReportSent(true)} />
      )}
    </>
  );
};
