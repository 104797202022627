import { FC } from "react";
import { useLocation } from "wouter";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTemplates } from "../../../../hooks/useAppData";
import Button from "../../../../components/Button";
import InfoBox from "../../../../components/InfoBox";
import Icon from "../../../../components/Icon";
import FeedbackCard from "../../../../components/FeedbackCard";

export const FeedbackTemplates: FC = () => {
  const [, setLocation] = useLocation();
  const { templates } = useTemplates();

  return (
    <Box pt={1} display="flex" flexDirection="column" gap={1}>
      <Box>
        <Button
          variant="tertiary"
          leftIcon={<Icon variant="arrowLeft" />}
          size="medium"
          label="Go back"
          onClick={() => setLocation("/", { replace: true })}
        />
      </Box>
      <h2>Feedback templates</h2>
      <InfoBox content="Clients will not see these templates, only the feedback requests that you send." />
      {templates?.map((template) => {
        return (
          <Box
            key={template.id}
            onClick={() => setLocation(`/templates/${template.id}`)}
            sx={{ cursor: "pointer" }}
          >
            <FeedbackCard
              id={template.id}
              feedback={template}
              variant="template"
            />
          </Box>
        );
      })}
      {templates?.length === 0 && (
        <Box>
          <Typography>
            No feedback templates, go back and create one.
          </Typography>
        </Box>
      )}
    </Box>
  );
};
