import { FC, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { MessageDraft } from "common/types/Conversations";
import { useAssigneeConversations } from "../../../../hooks/useAppData";
import { useAssignee } from "../../../../hooks/useAssignee";
import { DraftCard } from "../../../../components/ItemCard";
import MessageCard from "../../../../components/MessageCard";
import Button from "../../../../components/Button";
import { createDraft, subscribeToDrafts } from "../../../../services/messages";
import { NewMessage } from "./NewMessage";

export const Conversations: FC = () => {
  const { assignee } = useAssignee();
  const assigneeId = assignee?.uid ?? "";
  const [newMessageId, setNewMessageId] = useState<string>();
  const { conversations } = useAssigneeConversations(assigneeId);

  const [loading, setLoading] = useState(false);

  const [drafts, setDrafts] = useState<{ draft: MessageDraft; id: string }[]>();

  useEffect(
    () => subscribeToDrafts(assigneeId, setDrafts),
    [setDrafts, assigneeId]
  );

  const messages = useMemo(
    () =>
      conversations
        ?.flatMap((c) =>
          c.conversation.messages.map((message, i) => ({
            message,
            id: `${c.id}-${i}`,
            sentByUser: message.sender === assigneeId,
            feedback: c.conversation.feedback,
            unseen:
              message.sender === assigneeId &&
              (!c.conversation.lastSeenByTherapist ||
                message.timestamp > c.conversation.lastSeenByTherapist),
          }))
        )
        .sort(
          (a, b) =>
            b.message.timestamp.getTime() - a.message.timestamp.getTime()
        ),
    [conversations, assigneeId]
  );

  return newMessageId ? (
    <NewMessage
      assigneeId={assigneeId}
      draftId={newMessageId}
      messageText={
        drafts?.find((d) => d.id === newMessageId)?.draft.messageText
      }
      close={() => setNewMessageId(undefined)}
    />
  ) : (
    <Box pt={1}>
      <Box py={1}>
        <Button
          onClick={() => {
            setLoading(true);
            createDraft(assigneeId)
              .then(setNewMessageId)
              .finally(() => setLoading(false));
          }}
          variant="primary"
          loading={loading}
          label="New message"
          size="medium"
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        {drafts?.map(({ draft, id }) => (
          <Box
            key={id}
            onClick={() => setNewMessageId(id)}
            sx={{ cursor: "pointer" }}
            marginLeft="auto"
            width="80%"
          >
            <DraftCard text={draft.messageText} />
          </Box>
        ))}
        {messages?.map(({ message, id, sentByUser, feedback, unseen }) => (
          <MessageCard
            key={id}
            message={message}
            feedback={feedback}
            unseen={unseen}
            sentByUser={sentByUser}
          />
        ))}
      </Box>
    </Box>
  );
};
