import { FC } from "react";
import { Badge, Typography, Box } from "@mui/material";
import { Message, MessageFeedback } from "common/types/Conversations";
import { timestampToDateTimeString } from "common/dist/feat/date";
import Icon from "../Icon";

import { THEME } from "../../theme";
import { ItemCard } from "../ItemCard";

interface FeedbackQuestionProps {
  question: string;
  value: boolean;
}

const FeedbackQuestion: FC<FeedbackQuestionProps> = ({ question, value }) => {
  return (
    <Typography
      style={{ display: "flex", alignItems: "center", gap: 1 }}
      pb={1}
    >
      {question.replace(/^\w/, (c) => c.toUpperCase())}
      {value ? <Icon variant="thumbUp" /> : <Icon variant="thumbDown" />}
    </Typography>
  );
};

interface FeedbackProps {
  id: string;
  questions: [string, boolean][];
  comment: string | undefined;
}

const Feedback: FC<FeedbackProps> = ({ id, questions, comment }) => {
  return (
    <Box pt={2}>
      <Typography variant="caption">Answer</Typography>
      {questions.map((el: [string, boolean]) => (
        <FeedbackQuestion
          key={`${id}.${el[0]}`}
          question={el[0]}
          value={el[1]!}
        />
      ))}
      {comment && <Typography>Comments: {comment}</Typography>}
    </Box>
  );
};

interface MessageCardProps {
  message: Message;
  feedback?: MessageFeedback[];
  unseen?: boolean;
  sentByUser?: boolean;
}

const MessageCard: FC<MessageCardProps> = ({
  message,
  feedback,
  unseen,
  sentByUser,
}) => {
  const { text, timestamp } = message;
  const questions = feedback
    ? (Object.entries(feedback[0].feedback).filter(
        (el: [string, boolean | null]) => el[1] !== null
      ) as [string, boolean][])
    : [];
  const comment = feedback?.[0].comment;

  const textColor = sentByUser ? THEME.colors.grey700 : THEME.colors.grey000;

  return (
    <Box marginLeft={sentByUser ? 0 : "auto"} width="80%">
      <ItemCard
        color={sentByUser ? THEME.colors.grey100 : THEME.colors.blue300}
      >
        <Typography variant="caption" pb={1} color={textColor}>
          {timestampToDateTimeString(timestamp)}
          <Badge
            badgeContent=" "
            color="error"
            variant="dot"
            overlap="circular"
            invisible={!unseen}
            sx={{ ml: 1, mb: 1 }}
          ></Badge>
        </Typography>
        <Typography whiteSpace="pre-line" color={textColor}>
          {text}
        </Typography>
        {(comment || questions.length > 0) && (
          <Feedback
            id={timestampToDateTimeString(timestamp)}
            questions={questions}
            comment={comment}
          />
        )}
      </ItemCard>
    </Box>
  );
};

export default MessageCard;
