import { useContext } from "react";
import "./App.css";
import {
  AppBar,
  Box,
  CircularProgress,
  CssBaseline,
  Toolbar,
  Typography,
} from "@mui/material";
import { SignInSide } from "./features/auth/SignInSide";
import { Logo } from "./components/Logo";
import Button from "./components/Button";
import { AppDataProvider } from "./stores/AppContext";
import { AuthContext } from "./stores/AuthContext";
import { AppSettingsProvider } from "./stores/AppSettingsContext";
import { logout } from "./services/auth";
import { Main } from "./features/Main";
import { THEME } from "./theme";

function App() {
  const userAuth = useContext(AuthContext);

  return (
    <Box
      sx={{
        height: "100vh",
        minHeight: "100vh",
        overflow: "scroll",
        backgroundColor: "#fafafa",
      }}
    >
      <CssBaseline />
      {userAuth?.userAuth && (
        <AppBar
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Logo />
            <Button
              variant="tertiary"
              onClick={logout}
              label="sign out"
              size="medium"
              color={THEME.colors.grey000}
            />
          </Toolbar>
        </AppBar>
      )}
      {userAuth &&
        userAuth.userAuth &&
        (userAuth.userAuth.professional ||
          userAuth.userAuth.professional === undefined) && (
          <Box
            sx={{
              display: "flex",
              marginTop: 8,
              height: "100%",
              width: "100%",
            }}
          >
            <AppSettingsProvider>
              <AppDataProvider>
                <Main />
              </AppDataProvider>
            </AppSettingsProvider>
          </Box>
        )}
      {userAuth &&
        userAuth.userAuth &&
        userAuth.userAuth.professional === false && (
          <Box
            sx={{
              display: "flex",
              marginTop: 8,
              height: "100%",
              width: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography>
              Hi there! Maind app for desktop is under construction ⚙️ Rest
              assured we will let you know once it’s up and running!
            </Typography>
          </Box>
        )}
      {(userAuth === undefined || userAuth.userAuth === null) && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
          paddingBottom={20}
        >
          <CircularProgress size={100} />
        </Box>
      )}
      {userAuth?.userAuth === undefined && <SignInSide />}
    </Box>
  );
}

export default App;
