import { useContext } from "react";
import { ProfileContext } from "../stores/ProfileContext";

export const useProfile = () => {
  const context = useContext(ProfileContext);
  if (context === undefined) {
    throw Error("Attempted to use profile before initialization");
  }
  return context;
};
