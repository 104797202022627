import { FC, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Button from "./Button";
import InfoBox from "./InfoBox";

interface MultipleChoiceDialogProps {
  title: string;
  options: { displayValue: string; value: string; infoText?: string }[];
  selectedByDefault: number;
  approveText: string;
  open: boolean;
  handleClose: () => void;
  approve: (option: string) => Promise<void>;
}

export const MultipleChoiceDialog: FC<MultipleChoiceDialogProps> = (props) => {
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(props.selectedByDefault);

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="select-dialog-title"
      aria-describedby="select-dialog-description"
      fullWidth
    >
      <DialogTitle id="select-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <RadioGroup
          name="selection"
          value={selected}
          onChange={(_, value) => setSelected(Number(value))}
        >
          {props.options.map((option, index) => (
            <FormControlLabel
              value={index}
              key={option.displayValue}
              control={<Radio />}
              label={option.displayValue}
            />
          ))}
        </RadioGroup>
        {props.options[selected].infoText && (
          <InfoBox content={` ${props.options[selected].infoText}`} />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={props.handleClose}
          autoFocus
          color="error"
          label="cancel"
          size="medium"
          variant="tertiary"
          colorVariant="danger"
        />
        <Button
          onClick={() => {
            setLoading(true);
            props.approve(props.options[selected].value).finally(() => {
              setSelected(props.selectedByDefault);
              setLoading(false);
              props.handleClose();
            });
          }}
          loading={loading}
          label={props.approveText}
          size="medium"
          variant="primary"
        />
      </DialogActions>
    </Dialog>
  );
};
