export const styles = {
  chip: {
    marginRight: "10px",
    marginTop: "10px",
    fontSize: "16px",
    paddingRight: "10px",
    paddingLeft: "10px",
    height: "30px",
  },
};
