import {
  signInWithEmailAndPassword,
  AuthError,
  signOut,
  sendEmailVerification,
  User,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../features/firebase";
import { INTERNAL_ERROR, WEAK_PASSWORD } from "./constants";

export function getCurrentUser(): User {
  const currentUser = auth.currentUser;
  if (currentUser === null) {
    throw new Error("Current user is null");
  }
  return currentUser;
}

export async function signup(
  email: string,
  pwd: string
): Promise<{ status: boolean; message: string }> {
  try {
    await createUserWithEmailAndPassword(auth, email.trim(), pwd.trim());
    return { status: true, message: "Account created successfully" };
  } catch (error) {
    const err = error as AuthError;
    switch (err.code) {
      case "auth/invalid-email":
        return { status: false, message: "Please provide a valid email" };
      case "auth/weak-password":
      case "auth/empty-password":
        return {
          status: false,
          message: WEAK_PASSWORD,
        };
      default:
        console.debug(err.code);
        return { status: false, message: INTERNAL_ERROR };
    }
  }
}

export async function login(
  email: string,
  pwd: string
): Promise<{ status: boolean; message: string }> {
  try {
    await signInWithEmailAndPassword(auth, email.trim(), pwd);
    return { status: true, message: "login successfully" };
  } catch (error) {
    const err = error as AuthError;
    switch (err.code) {
      case "auth/user-not-found":
      case "auth/empty-password":
      case "auth/wrong-password":
      case "auth/invalid-email":
        return { status: false, message: "Incorrect email or password" };
      default:
        console.debug(err.code);
        return { status: false, message: "Unexpected error, please try again" };
    }
  }
}

export async function logout(): Promise<void> {
  try {
    await signOut(auth);
  } catch (error) {
    console.debug("Error loggin out", error);
  }
}

export async function sendEmailVerificationLink(): Promise<boolean> {
  try {
    const currentUser = getCurrentUser();
    await sendEmailVerification(currentUser);
    return true;
  } catch (error) {
    console.debug("Error sending a new email verification mail", error);
    return false;
  }
}
