import {
  collection,
  query,
  where,
  onSnapshot,
  Query,
  orderBy,
  doc,
  setDoc,
  addDoc,
  deleteDoc,
  documentId,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import {
  Conversation,
  DbConversation,
  MessageDraft,
} from "common/dist/types/Conversations";
import { isPresent } from "common/dist/feat/util";
import { auth, db, functionsClient } from "../features/firebase";

export const subscribeToConversations = (
  onUpdate: (
    conversations: { conversation: Conversation; id: string }[]
  ) => void
) => {
  const q = query(
    collection(db, "conversations"),
    where("therapist", "==", auth.currentUser?.uid),
    orderBy("updatedAt", "desc")
  ) as Query<DbConversation>;

  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const conversations = querySnapshot.docs
        .map((doc) => {
          const data = doc.data();
          if (!data.messages || data.messages.length === 0) {
            console.error("Invalid conversation", JSON.stringify(data));
            return undefined;
          }
          return {
            conversation: {
              ...data,
              createdAt: data.createdAt.toDate(),
              updatedAt: data.updatedAt.toDate(),
              lastSeenByUser: data.lastSeenByUser?.toDate(),
              lastSeenByTherapist: data.lastSeenByTherapist?.toDate(),
              messages: data.messages.map((m) => ({
                ...m,
                timestamp: m.timestamp.toDate(),
              })),
            },
            id: doc.id,
          };
        })
        .filter(isPresent);
      onUpdate(conversations);
    },
    (err) => console.error("Error subscribing to conversations", err)
  );
  return unsubscribe;
};

export const subscribeToDrafts = (
  receiverUid: string,
  onUpdate: (drafts: { draft: MessageDraft; id: string }[]) => void
) => {
  const q = query(
    collection(db, "therapists", auth.currentUser!.uid, "drafts"),
    where("receiver", "==", receiverUid)
  ) as Query<MessageDraft>;

  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const drafts = querySnapshot.docs
        .map((doc) => {
          const draft = doc.data();
          if (!draft.receiver || typeof draft.messageText !== "string") {
            console.error("Invalid draft", JSON.stringify(draft));
            return undefined;
          }
          return {
            draft: draft,
            id: doc.id,
          };
        })
        .filter(isPresent);
      console.debug("Updating drafts");
      onUpdate(drafts);
    },
    (err) => console.error("Error subscribing to drafts", err)
  );
  return unsubscribe;
};

export async function createDraft(receiver: string): Promise<string> {
  const uid = auth.currentUser!.uid;

  const draft = {
    receiver,
    messageText: "",
  };

  const docRef = await addDoc(
    collection(db, "therapists", uid, "drafts"),
    draft
  );
  return docRef.id;
}

export async function updateDraft(
  draftId: string,
  receiver: string,
  messageText: string
): Promise<void> {
  const uid = auth.currentUser!.uid;

  const draft = {
    receiver,
    messageText,
  };

  await setDoc(doc(db, "therapists", uid, "drafts", draftId), draft);
}

export async function deleteDraft(draftId: string): Promise<void> {
  const uid = auth.currentUser!.uid;

  await deleteDoc(doc(db, "therapists", uid, "drafts", draftId));
}

export async function sendDraft(draftId: string): Promise<void> {
  await functionsClient.sendApprovedDraft({
    draftId: draftId,
  });
}

export function updateConversationsSeen(conversationIds: string[]) {
  getDocs(
    query(
      collection(db, "conversations"),
      where(documentId(), "in", conversationIds)
    ) as Query<DbConversation>
  ).then((conversationDocs) =>
    conversationDocs.forEach((doc) =>
      updateDoc(doc.ref, { lastSeenByTherapist: new Date() })
    )
  );
}
