import {
  createContext,
  FC,
  PropsWithChildren,
  useState,
  useEffect,
  useCallback,
} from "react";
import { onAuthStateChanged } from "firebase/auth";
import { UserAuth } from "common/dist/types/User";
import { auth } from "../features/firebase";

export type AuthContextType = {
  userAuth: UserAuth | null | undefined;
  setUserAuth: (user: UserAuth) => void;
};

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined
);

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [userAuth, setUserAuth] = useState<UserAuth | undefined | null>(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user !== null) {
        const tokenResult = await user.getIdTokenResult();
        if (!tokenResult.claims.professional) {
          user.getIdToken(true);
        }
        const emailVerified =
          user.emailVerified === false
            ? user.providerData.find((p) => p.providerId === "google.com") !==
              undefined
            : user.emailVerified;
        const firebaseUser: UserAuth = {
          emailVerified: emailVerified,
          professional: tokenResult.claims.professional,
          uid: user.uid,
          email: "",
        };
        if (
          userAuth === undefined ||
          JSON.stringify(userAuth) !== JSON.stringify(firebaseUser)
        ) {
          setUserAuth(firebaseUser);
        }
      } else {
        setUserAuth(undefined);
      }
    });
    return unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setUserAuthCallBack = useCallback(setUserAuth, []);

  const context: AuthContextType = {
    userAuth: userAuth,
    setUserAuth: setUserAuthCallBack,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
