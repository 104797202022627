import React, { FC, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

import Icon from "../../../components/Icon";
import Button from "../../../components/Button";
import { useProfile } from "../../../hooks/useProfile";
import { getProviderData, haveProvider } from "../utils";
import { THEME } from "../../../theme";

interface ConnectionRowProps {
  provider: {
    providerId: string;
    link: () => Promise<void | boolean>;
    unlink: () => Promise<void>;
  };
}

const ConnectionRow: FC<ConnectionRowProps> = ({ provider }) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { providers } = useProfile();
  const providerData = getProviderData(provider.providerId);

  const handleClick = async () => {
    setLoading(true);
    if (externalProvider === undefined) {
      await provider.link();
    } else {
      await provider.unlink();
    }
    setLoading(false);
  };
  const externalProvider = haveProvider(provider.providerId, providers);
  const email = externalProvider ? externalProvider.email : "Not connected";

  return (
    <Box
      component="li"
      display="flex"
      flexDirection="row"
      gap={1}
      alignItems="center"
      borderTop={1}
      borderColor={THEME.colors.grey300}
      padding={2}
    >
      <Icon size="small" variant={providerData?.icon} />
      <Typography>
        <Typography component="span" fontWeight="bold">
          {providerData?.name}:
        </Typography>{" "}
        {email}
      </Typography>

      <Box
        display="flex"
        flex={1}
        alignSelf="flex-end"
        justifyContent="flex-end"
      >
        <Button
          onClick={handleClick}
          variant="secondary"
          size="small"
          label={externalProvider === undefined ? "connect" : "disconnect"}
          loading={loading}
          disabled={
            loading || (providers.length < 2 && externalProvider !== undefined)
          }
        />
      </Box>
    </Box>
  );
};

export default ConnectionRow;
