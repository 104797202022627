type AppVariant = "development" | "production" | "localDev" | "test";

type ENV_VARIABLES_TYPE = {
  appVariant: AppVariant;
  useEmulator: string;
};

export const ENV_VARIABLES: ENV_VARIABLES_TYPE = {
  appVariant: (process.env.REACT_APP_APP_VARIANT as AppVariant) || "localDev",
  useEmulator: process.env.REACT_APP_USE_EMULATOR || "false",
};
