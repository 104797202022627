import { FC, useMemo } from "react";
import { Stack, Box, Typography } from "@mui/material";
import { getStringArrayCounts } from "common/dist/feat/logEntry";
import { useEntries } from "../../../../../hooks/useEntries";
import { CorrelationVariableSelection } from "./CorrelationVariableSelection";
import { calcCorrelationCoefficients } from "./correlationUtils";
import { CorrelationHeatmap } from "./CorrelationHeatmap";
import { CorrelationLegend } from "./CorrelationLegend";
import { classifierEmotions } from "../dataUtils";

interface CorrelationViewProps {
  selectedCategories?: { value: string; displayName: string }[];
  setSelectedCategories: (
    categories: { value: string; displayName: string }[]
  ) => void;
  selectedEmotions?: { value: string; displayName: string }[];
  setSelectedEmotions: (
    emotions: { value: string; displayName: string }[]
  ) => void;
  selectedSymptoms?: { value: string; displayName: string }[];
  setSelectedSymptoms: (
    symptoms: { value: string; displayName: string }[]
  ) => void;
  selectedClassifierEmotions: { value: string; displayName: string }[];
  setSelectedClassifierEmotions: (
    emotions: { value: string; displayName: string }[]
  ) => void;
}

export const CorrelationView: FC<CorrelationViewProps> = ({
  selectedCategories,
  setSelectedCategories,
  selectedEmotions,
  setSelectedEmotions,
  selectedSymptoms,
  setSelectedSymptoms,
  selectedClassifierEmotions,
  setSelectedClassifierEmotions,
}) => {
  const { entries } = useEntries();

  const categoryOptions = getStringArrayCounts(
    entries ? entries.flatMap((e) => e.emotions.map((em) => em.category)) : []
  ).map((e) => ({ value: e.element, displayName: e.element }));

  const emotionOptions = getStringArrayCounts(
    entries
      ? entries.flatMap((e) => e.emotions.map((em) => em.displayName))
      : []
  )
    .slice(0, 10)
    .map((e) => ({ value: e.element, displayName: e.element }));

  const symptomOptions = getStringArrayCounts(
    entries
      ? entries.flatMap((e) =>
          e.symptoms.length > 0
            ? e.symptoms.map((s) => s.label)
            : ["No symptoms"]
        )
      : []
  )
    .slice(0, 10)
    .map((s) => ({ value: s.element, displayName: s.element }));

  const data = useMemo(
    () =>
      entries
        ? entries.map((e) =>
            Object.assign(
              {},
              ...(selectedCategories ?? categoryOptions.slice(0, 2)).map(
                (category) => ({
                  [category.displayName]: e.emotions.filter(
                    (em) => em.category === category.value
                  ).length,
                })
              ),
              ...(selectedEmotions ?? emotionOptions.slice(0, 2)).map(
                (emotion) => ({
                  [emotion.displayName]: e.emotions.find(
                    (em) => em.displayName === emotion.value
                  )
                    ? 1
                    : 0,
                })
              ),
              ...(selectedSymptoms ?? symptomOptions.slice(0, 2)).map(
                (symptom) => ({
                  [symptom.displayName]:
                    e.symptoms.find((s) => symptom.value === s.label) ||
                    (e.symptoms.length === 0 && symptom.value === "No symptoms")
                      ? 1
                      : 0,
                })
              ),
              ...selectedClassifierEmotions.map((emotion) => ({
                [emotion.displayName]:
                  e.topClassifiedEmotion === emotion.value ? 1 : 0,
              }))
            )
          )
        : [],
    [
      entries,
      selectedCategories,
      selectedEmotions,
      selectedSymptoms,
      selectedClassifierEmotions,
      categoryOptions,
      emotionOptions,
      symptomOptions,
    ]
  );

  const corrCoefs = calcCorrelationCoefficients(
    data.map((d) => Object.values(d) as number[])
  );

  if (!entries) {
    return (
      <Box>
        <Typography>No data</Typography>
      </Box>
    );
  }

  return (
    <Stack direction="row">
      <Stack direction="column">
        <CorrelationLegend />

        <CorrelationVariableSelection
          selections={[
            {
              category: "Most logged emotions",
              options: emotionOptions,
              selected: selectedEmotions ?? emotionOptions.slice(0, 2),
              setSelected: setSelectedEmotions,
            },
            {
              category: "Emotion categories",
              options: categoryOptions,
              selected: selectedCategories ?? categoryOptions.slice(0, 2),
              setSelected: setSelectedCategories,
            },
            {
              category: "Symptoms",
              options: symptomOptions,
              selected: selectedSymptoms ?? symptomOptions.slice(0, 2),
              setSelected: setSelectedSymptoms,
            },
            {
              category: "Classified emotions",
              options: classifierEmotions,
              selected: selectedClassifierEmotions,
              setSelected: setSelectedClassifierEmotions,
            },
          ]}
        />
      </Stack>
      {corrCoefs && (
        <CorrelationHeatmap
          correlationCoefficients={corrCoefs}
          variableNames={Object.keys(data[0]).map((v) =>
            v.replace(/^\w/, (c) => c.toUpperCase())
          )}
        />
      )}
    </Stack>
  );
};
