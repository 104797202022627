import { FC } from "react";
import { HomeworkTemplate } from "common/dist/types/Homework";
import { useAssignee } from "../../../../../hooks/useAssignee";
import { useAuth } from "../../../../../hooks/useAuth";
import { HomeworkTemplateEditView } from "../HomeworkTemplateEditView";
import { HomeworkTemplateDetails } from "../HomeworkTemplateDetails";

interface HomeworkTemplatePageProps {
  template: HomeworkTemplate;
  close: () => void;
}

const HomeworkTemplatePage: FC<HomeworkTemplatePageProps> = ({
  template,
  close,
}) => {
  const { userAuth } = useAuth();
  const { assignee } = useAssignee();

  if (userAuth.uid === template?.createdBy) {
    return (
      <HomeworkTemplateEditView
        template={template}
        assigneeId={assignee?.uid ?? ""}
        close={close}
      />
    );
  }
  return (
    <HomeworkTemplateDetails
      template={template}
      assigneeId={assignee?.uid ?? ""}
      close={close}
    />
  );
};

export default HomeworkTemplatePage;
