import { FC } from "react";
import Box from "@mui/material/Box";
import { login } from "../../services/auth";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";

export const LogInForm: FC = () => {
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const target = event.target as HTMLFormElement;
    const loginRes = await login(target.email.value, target.password.value);
    if (!loginRes.status) {
      alert(loginRes.message);
    }
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <TextInput
        required
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
      />
      <TextInput
        required
        name="password"
        label="Password"
        type="password"
        id="password"
        autoComplete="current-password"
      />
      <Button
        type="submit"
        variant="primary"
        label="log in"
        size="medium"
        fullWidth
      />
    </Box>
  );
};
