import { FC } from "react";
import Box from "@mui/material/Box";
import { Message } from "common/dist/types/Conversations";
import { EntryLog } from "common/dist/types/LogEntry";
import { Feedback } from "common/dist/types/Feedback";
import { Homework } from "common/dist/types/Homework";
import MessageCard from "../../../../components/MessageCard";
import FeedbackCard from "../../../../components/FeedbackCard";
import Entry from "../Entries/EntryLog";
import HomeworkCard from "../HomeworkTab/HomeworkCard";

export type TimelineItem = {
  id: string;
  timestamp: Date;
  item:
    | { type: "entryLog"; entry: EntryLog }
    | { type: "feedback"; feedback: Feedback }
    | { type: "homework"; homework: Homework }
    | { type: "message"; message: Message; sentByUser: boolean };
};

interface TimelineBoxProps {
  timelineItem: TimelineItem;
  onClick?: () => void;
}

const TimelineBox: FC<TimelineBoxProps> = ({ timelineItem, onClick }) => {
  switch (timelineItem.item.type) {
    case "entryLog": {
      return (
        <Box width="80%">
          <Entry entry={timelineItem.item.entry} />
        </Box>
      );
    }
    case "feedback": {
      return (
        <Box marginLeft="auto" width="80%">
          <FeedbackCard
            feedback={timelineItem.item.feedback}
            variant="send"
            id={timelineItem.id}
            answers={timelineItem.item.feedback.answers}
            timestamp={timelineItem.item.feedback.timestamp}
          />
        </Box>
      );
    }
    case "message": {
      return (
        <MessageCard
          message={timelineItem.item.message}
          sentByUser={timelineItem.item.sentByUser}
        />
      );
    }
    case "homework": {
      return (
        <Box
          onClick={onClick}
          sx={{ cursor: "pointer" }}
          marginLeft="auto"
          width="80%"
        >
          <HomeworkCard homework={timelineItem.item.homework} />
        </Box>
      );
    }
    default:
      return null;
  }
};

export default TimelineBox;
