import { useContext } from "react";
import { AppContext, AppContextType } from "../stores/AppContext";

function useInitializedContext(): AppContextType {
  const context = useContext(AppContext);
  if (context === undefined) {
    throw Error("Attempted to use AppStore before initialization");
  }
  return context;
}

export const useProfile = () => {
  const context = useInitializedContext();
  const { userAttributes } = context;
  return { userAttributes };
};

export const useAssigneeConversations = (assigneeId: string) => {
  const context = useInitializedContext();
  return {
    conversations: context.conversations?.filter(
      (c) => c.conversation.user === assigneeId
    ),
  };
};

export const useFeedbacks = () => {
  const context = useInitializedContext();
  return { feedbacks: context.feedbacks };
};

export const useAssigneeFeedbacks = (assigneeId: string) => {
  const context = useInitializedContext();
  return {
    feedbacks: context.feedbacks?.filter(
      (f) => f.feedback.patient === assigneeId
    ),
  };
};

export const useTemplates = () => {
  const { templates } = useInitializedContext();
  return { templates };
};

export const useHomeworkTemplates = () => {
  const { publicHomeworkTemplates } = useInitializedContext();

  return { publicHomeworkTemplates: publicHomeworkTemplates ?? [] };
};
