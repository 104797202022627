export interface HeatmapDataPoint {
  key: Date;
  date: Date;
  data: number;
}

export enum HeatmapUnit {
  NumberOfJournalEntries,
  LoggedEmotionCategories,
  PhysicalSymptoms,
}

export const heatmapUnits = {
  [HeatmapUnit.NumberOfJournalEntries]: "# of logs",
  [HeatmapUnit.LoggedEmotionCategories]: "Logged categories",
  [HeatmapUnit.PhysicalSymptoms]: "Physical symptoms",
};
