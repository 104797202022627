import { Provider } from "common/dist/types/User";
import { IconVariant } from "../../components/Icon";

export interface ProviderData {
  icon: IconVariant;
  name: string;
}

export function getProviderData(provider: string): ProviderData | undefined {
  switch (provider) {
    case "google.com":
      return { icon: "google", name: "Google" };
    case "password":
      return { icon: "email", name: "Email" };
    default:
      return undefined;
  }
}

export function haveProvider(provider: string, providers: Provider[]) {
  return providers.find((p) => p.providerId === provider);
}
