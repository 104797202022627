import { FC, useState } from "react";
import Box from "@mui/material/Box";
import { HomeworkDraft } from "common/types/Homework";
import { useHomeworks } from "../../../../hooks/useHomeworks";
import {
  sendHomeworkDraft,
  deleteHomeworkDraft,
  updateHomeworkDraft,
  createTemplate,
} from "../../../../services/homeworks";
import Button from "../../../../components/Button";
import Snackbar, { SnackbarStatus } from "../../../../components/Snackbar";
import Icon from "../../../../components/Icon";
import HomeworkForm, {
  HomeworkEdit,
} from "../../../../components/HomeworkForm";
import { checkTemplateExists } from "./utils";

interface NewHomeworkProps {
  draftId: string;
  draft?: HomeworkDraft;
  assigneeId: string;
  close: () => void;
}

export const NewHomework: FC<NewHomeworkProps> = ({
  draftId,
  draft,
  assigneeId,
  close,
}) => {
  const [snackStatus, setSnackStatus] = useState<SnackbarStatus | undefined>(
    undefined
  );
  const { homeworkTemplates } = useHomeworks();

  const updateDraft = async (updatedHomework: HomeworkEdit) => {
    const res = await updateHomeworkDraft(
      draftId,
      assigneeId,
      updatedHomework.title ?? "",
      updatedHomework.description ?? "",
      updatedHomework.images ?? [],
      updatedHomework.labels ?? []
    );
    setSnackStatus({ severity: res.severity, message: res.message });
  };

  const createAsTemplate = async (
    homework: HomeworkEdit,
    privacyValueStr: string
  ) => {
    const privacyValue: boolean = privacyValueStr.toLowerCase() === "true";
    const templateExist = checkTemplateExists(
      homeworkTemplates ?? [],
      homework.title ?? "",
      homework.description,
      homework.images ?? [],
      homework.labels ?? []
    );
    if (templateExist) {
      alert("Identical template already exists.");
      return;
    }
    if (homework.title && homework.description) {
      const result = await createTemplate(
        homework.title,
        homework.description,
        privacyValue,
        homework.images,
        homework.labels
      );
      setSnackStatus(result);
    }
  };

  const deleteDraft = async (images: string[]) => {
    const res = await deleteHomeworkDraft(draftId, images);
    if (res.severity === "success") {
      close();
    } else {
      setSnackStatus({ severity: res.severity, message: res.message });
    }
  };

  const sendDraft = async () => {
    const res = await sendHomeworkDraft(draftId);
    if (res.severity === "success") {
      close();
    } else {
      setSnackStatus({ severity: res.severity, message: res.message });
    }
  };

  return (
    <>
      <Box>
        <Button
          label="back"
          variant="tertiary"
          onClick={close}
          leftIcon={<Icon variant="arrowLeft" />}
        />
      </Box>
      <h2>New homework draft</h2>
      {draft !== undefined && (
        <HomeworkForm
          initHomework={draft}
          onSend={sendDraft}
          onDelete={deleteDraft}
          onUpdate={updateDraft}
          type="homework"
          onTemplate={createAsTemplate}
        />
      )}
      <Snackbar
        message={snackStatus?.message}
        setVisibility={() => setSnackStatus(undefined)}
        duration={6000}
        custom={true}
        severity={snackStatus?.severity}
      />
    </>
  );
};
