import React, { FC, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import { HomeworkCore } from "common/dist/types/Homework";
import FormCard, {
  actionContainerStyles,
  contentContainerStyles,
} from "../FormCard";
import { Editor } from "../Editor";
import { deleteImage, uploadImages } from "../../services/homeworks";
import Button from "../Button";
import { ConfirmationDialog } from "../ConfirmationDialog";
import { ThumbnailGroup } from "../ThumbnailGroup";
import { MultipleChoiceDialog } from "../MultipleChoiceDialog";
import TextInput from "../TextInput";
import Icon from "../Icon";
import { THEME } from "../../theme";

export interface HomeworkEdit extends HomeworkCore {
  labels?: string[];
  receiver?: string;
  public?: boolean;
}

interface HomeworkFormProps {
  onSend: () => Promise<void>;
  onDelete: (images: string[]) => Promise<void>;
  onTemplate?: (homework: HomeworkEdit, privacyVal: string) => Promise<void>;
  onUpdate: (homework: HomeworkEdit) => Promise<void>;
  type: "homework" | "homework template";
  initHomework: HomeworkEdit;
}

const styles = {
  chip: {
    marginRight: "10px",
    marginTop: "10px",
    fontSize: "16px",
    paddingRight: "10px",
    paddingLeft: "10px",
    height: "30px",
  },
};

const HomeworkForm: FC<HomeworkFormProps> = ({
  onSend,
  onDelete,
  onTemplate,
  onUpdate,
  type,
  initHomework,
}) => {
  const [showSendConfirmation, setSendConfirmation] = useState<boolean>(false);
  const [showDiscardConfirmation, setDiscardConfirmation] =
    useState<boolean>(false);
  const [showTemplatePrivacyDialog, setTemplatePrivacyDialog] =
    useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const [changes, setChanges] = useState<boolean>(false);

  const [description, setDescription] = useState<string>(
    initHomework.description
  );
  const [images, setImages] = useState<string[]>(initHomework.images ?? []);
  const [title, setTitle] = useState<string>(initHomework.title ?? "");
  const [label, setLabel] = useState<string>("");
  const [labels, setLabels] = useState<string[]>(initHomework.labels ?? []);
  const [publicFlag, setPublicFlag] = useState<boolean>(
    initHomework.public ?? false
  );

  const handleApprove = async () => {
    await onSend();
    setSendConfirmation(false);
  };

  const handleDelete = async () => {
    await onDelete(images);
  };

  const handleUpdate = async () => {
    setSaving(true);
    const updatedHomeworkDraft: HomeworkEdit = {
      title: title,
      description: description,
      images: images,
      labels: labels,
      receiver: initHomework.receiver ?? "",
    };
    await onUpdate(updatedHomeworkDraft);
    setSaving(false);
  };

  const handleTemplateApprove = async (privacyVal: string) => {
    if (onTemplate === undefined) {
      return;
    }
    const newTemplate: HomeworkEdit = {
      title: title,
      description: description,
      images: images,
      labels: labels,
    };
    await onTemplate(newTemplate, privacyVal);
  };

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setSaving(true);
      uploadImages([...e.target.files])
        .then((downloadUrls) => setImages([...images, ...downloadUrls]))
        .finally(() => setSaving(false));
    }
  };

  const handleEnterLabel = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      const target = event.target as HTMLInputElement;

      if (target.value.length > 0 && !labels.includes(target.value)) {
        setLabels([...labels, target.value]);
      }
      setLabel("");
    }
  };

  useEffect(() => {
    setChanges(
      initHomework?.description !== description ||
        initHomework?.description === "" ||
        initHomework?.title !== title ||
        !(initHomework?.images?.every((i) => images.includes(i)) ?? true) ||
        !images.every((i) => initHomework?.images?.includes(i)) ||
        !(initHomework?.labels?.every((i) => labels.includes(i)) ?? true) ||
        !labels.every((i) => initHomework?.labels?.includes(i))
    );
  }, [
    initHomework?.description,
    description,
    initHomework?.title,
    title,
    initHomework?.images,
    images,
    initHomework?.labels,
    labels,
  ]);

  return (
    <FormCard>
      <Box sx={contentContainerStyles}>
        <ConfirmationDialog
          title={`Send ${type}?`}
          content="Send as a message to user?"
          approveText="Send"
          open={showSendConfirmation}
          handleClose={() => {
            setSendConfirmation(false);
          }}
          approve={handleApprove}
        />
        <ConfirmationDialog
          title={`Discard ${type}?`}
          content={`Discard ${type} and its contents?`}
          approveText="Discard"
          open={showDiscardConfirmation}
          handleClose={() => {
            setDiscardConfirmation(false);
          }}
          approve={handleDelete}
          buttonVariant="danger"
        />
        <MultipleChoiceDialog
          title="Who can view this template?"
          options={[
            { displayValue: "Only me", value: "false" },
            {
              displayValue: "Me and the maind community",
              value: "true",
              infoText:
                "Please note that this template will be shared with your name. " +
                "You can change this at any time by editing the template.",
            },
          ]}
          selectedByDefault={0}
          approveText="Send"
          open={showTemplatePrivacyDialog}
          handleClose={() => {
            setTemplatePrivacyDialog(false);
          }}
          approve={handleTemplateApprove}
        />
        <TextInput label="Title" value={title} onChange={setTitle} />
        <Box mt={-3} mb={6}>
          <IconButton
            style={{
              marginBottom: -65,
              marginLeft: 40,
              zIndex: 999,
            }}
            component="label"
          >
            <Icon
              variant="addPhoto"
              size="small"
              color={THEME.colors.grey700}
            />
            <input
              value=""
              type="file"
              accept="image/*"
              multiple
              hidden
              onChange={handleImageUpload}
            />
          </IconButton>
          <Editor
            initialText={description}
            onChange={setDescription}
            placeholder="Homework description"
          />
          <ThumbnailGroup
            images={images}
            onDelete={(url) => {
              deleteImage(url);
              setImages(images.filter((i) => i !== url));
            }}
          />
          <Typography color={THEME.colors.grey600} mt={3} mb={1}>
            Add tags to help you or others find this content:
          </Typography>
          <TextInput
            value={label}
            onChange={setLabel}
            label="Write a tag, then press enter"
            onKeyDown={handleEnterLabel}
          />
          {labels.map((label) => (
            <Chip
              key={label}
              label={label}
              variant="outlined"
              onDelete={() => setLabels(labels.filter((l) => l !== label))}
              sx={{ ...styles.chip, color: THEME.colors.grey700 }}
            />
          ))}
        </Box>
        {type === "homework template" && (
          <Box
            sx={{
              mt: 3,
              color: THEME.colors.grey600,
              display: "flex",
              alignItems: "center",
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={publicFlag}
                  onChange={(_, value) => setPublicFlag(value)}
                />
              }
              label="Share with maind community"
            />
            <Tooltip
              title={
                "Please note that this template will be shared with your name. " +
                "You can change this at any time."
              }
            >
              <Icon variant="info" />
            </Tooltip>
          </Box>
        )}
      </Box>
      <Box sx={actionContainerStyles}>
        {type === "homework" && (
          <Tooltip
            title={
              !title
                ? "Please add a title before saving as template"
                : !description
                ? "Please add a description before saving as template"
                : ""
            }
          >
            <span>
              <Button
                variant="primary"
                size="medium"
                onClick={() => setTemplatePrivacyDialog(true)}
                leftIcon={<Icon variant="addBookmark" />}
                loading={saving}
                disabled={saving || !(title && description)}
                label="create new template"
              />
            </span>
          </Tooltip>
        )}
        <Button
          variant="success"
          onClick={handleUpdate}
          loading={saving}
          disabled={!changes}
          leftIcon={<Icon variant="save" />}
          label="Save"
          size="medium"
        />
        <Button
          variant="danger"
          onClick={() => setDiscardConfirmation(true)}
          leftIcon={<Icon variant="delete" />}
          disabled={saving}
          size="medium"
          label="Delete"
        />
        <Tooltip title={changes ? "Please save changes before sending" : ""}>
          <span>
            <Button
              variant="primary"
              onClick={() => setSendConfirmation(true)}
              leftIcon={<Icon variant="send" />}
              disabled={saving || changes}
              label="Send"
              size="medium"
            />
          </span>
        </Tooltip>
      </Box>
    </FormCard>
  );
};

export default HomeworkForm;
