import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { Homework } from "common/types/Homework";
import { timestampToDateTimeString } from "common/dist/feat/date";
import { ThumbnailGroup } from "../../../../../components/ThumbnailGroup";
import Button from "../../../../../components/Button";
import Icon from "../../../../../components/Icon";
import AnswerCard from "./AnswerCard";

interface HomeworkDetailsProps {
  homework: Homework;
  close?: () => void;
}

export const HomeworkDetails: FC<HomeworkDetailsProps> = ({
  homework,
  close,
}) => {
  return (
    <Box pt={1} display="flex" flexDirection="column" gap={1}>
      <Box>
        <Button
          variant="tertiary"
          onClick={close}
          leftIcon={<Icon variant="arrowLeft" />}
          label="Back"
          size="medium"
        />
      </Box>
      <Box>
        <Typography variant="caption" pb={1}>
          {timestampToDateTimeString(homework.timestamp)}
        </Typography>
        <Typography variant="h6">{homework.title}</Typography>
        <Typography>{homework.description}</Typography>
        {homework.images && <ThumbnailGroup images={homework.images} />}
      </Box>
      <Box pt={1}>
        <Typography variant="caption" pb={1}>
          ANSWERS
        </Typography>
        <Box display="flex" flexDirection="column" gap={1}>
          {homework.answers
            .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
            .map((a, i) => (
              <AnswerCard key={i} answer={a} />
            ))}
        </Box>
      </Box>
    </Box>
  );
};
