import { FC } from "react";
import { useLocation, useParams } from "wouter";
import { Box, Typography } from "@mui/material";
import { Homework } from "common/types/Homework";
import { useHomeworks } from "../../../../hooks/useHomeworks";
import Button from "../../../../components/Button";
import Icon from "../../../../components/Icon";
import { HomeworkDetails } from "./HomeworkDetails";

export const HomeworkContainer: FC = () => {
  const params = useParams();
  const homeworkId = params.homeworkId ?? "";
  const [, setLocation] = useLocation();
  const { homeworks } = useHomeworks();
  const homework: Homework | undefined = homeworks?.find(
    (h) => h.id === homeworkId
  );

  if (homework === undefined) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        paddingTop={10}
        alignItems="center"
        justifyContent="center"
      >
        <Typography>Homework not found</Typography>
        <Box>
          <Button
            label="go back to homeworks"
            onClick={() => setLocation("/", { replace: true })}
            size="medium"
            variant="primary"
            leftIcon={<Icon variant="arrowLeft" />}
          />
        </Box>
      </Box>
    );
  }

  return (
    <HomeworkDetails
      homework={homework}
      close={() => setLocation("/", { replace: true })}
    />
  );
};
