import { FC } from "react";
import {
  Heatmap,
  HeatmapSeries,
  LinearXAxis,
  LinearXAxisTickLabel,
  LinearXAxisTickSeries,
  LinearYAxis,
  LinearYAxisTickLabel,
  LinearYAxisTickSeries,
} from "reaviz";
import { CorrelationHeatmapCell } from "./CorrelationHeatmapCell";
import { THEME } from "../../../../../theme";

export const CorrelationHeatmap: FC<{
  correlationCoefficients: number[][];
  variableNames: string[];
}> = ({ correlationCoefficients, variableNames }) => {
  const data = variableNames.map((v1, i) => ({
    key: v1,
    data: variableNames
      .map((v2, j) => ({ key: v2, idx: j }))
      .filter((_, j) => j >= i)
      .reverse()
      .map((v2) => ({
        key: v2.key,
        data: Math.round(correlationCoefficients[i][v2.idx] * 100) / 100,
      })),
  }));

  return (
    <Heatmap
      height={Math.max(400, variableNames.length * 70 + 70)}
      width={Math.max(400, variableNames.length * 70 + 70)}
      data={data}
      style={{ color: THEME.colors.grey600 }}
      xAxis={
        <LinearXAxis
          tickSeries={
            <LinearXAxisTickSeries
              tickSize={0}
              label={
                <LinearXAxisTickLabel
                  fill={THEME.colors.grey600}
                  fontSize={16}
                  padding={{ fromAxis: 5, alongAxis: 0 }}
                />
              }
            />
          }
          axisLine={null}
        />
      }
      yAxis={
        <LinearYAxis
          tickSeries={
            <LinearYAxisTickSeries
              label={
                <LinearYAxisTickLabel
                  fill={THEME.colors.grey600}
                  fontSize={16}
                  padding={{ fromAxis: 5, alongAxis: 0 }}
                />
              }
            />
          }
          axisLine={null}
        />
      }
      series={
        <HeatmapSeries
          padding={0.05}
          emptyColor={THEME.colors.grey000}
          cell={<CorrelationHeatmapCell />}
        />
      }
    />
  );
};
