const calcMean = (data: number[]) =>
  data.reduce((a, b) => a + b, 0) / data.length;

const calcSSD = (data: number[], mean: number) =>
  Math.sqrt(data.map((a) => (a - mean) ** 2).reduce((a, b) => a + b));

const calcPairwiseSCC = (data: [number, number][]) => {
  if (data.length === 0) {
    return NaN;
  }

  const x = data.map((d) => d[0]);
  const y = data.map((d) => d[1]);

  const xMean = calcMean(x);
  const yMean = calcMean(y);

  const xSSD = calcSSD(x, xMean);
  const ySSD = calcSSD(y, yMean);

  return (
    x.map((a, idx) => (a - xMean) * (y[idx] - yMean)).reduce((a, b) => a + b) /
    (xSSD * ySSD)
  );
};

export const calcCorrelationCoefficients = (dataMatrix: number[][]) => {
  if (dataMatrix.length === 0 || dataMatrix[0].length === 0) {
    return null;
  }

  const variableIndices = Array.from(Array(dataMatrix[0].length).keys());

  return variableIndices.map((i) =>
    variableIndices.map((j) =>
      calcPairwiseSCC(
        dataMatrix
          .filter(
            (d) =>
              d[i] !== null &&
              d[i] !== undefined &&
              d[j] !== null &&
              d[j] !== undefined
          )
          .map((d) => [d[i], d[j]])
      )
    )
  );
};

export const correlationColors = [
  "#1F275B",
  "#0766BE",
  "#2898FF",
  "#B1E3FF",
  "#EEE",
  "#EEE",
  "#FFC7C7",
  "#FE8F8F",
  "#F36F6F",
  "#EC4A4A",
];
