import { Timestamp } from "firebase/firestore";
import { ClassifierEmotion } from "./ClassifierResults";
import { MessageGPT, DbMessageGPT } from "./Conversations";
import { commonColors } from "../theme";

export interface Emotions {
  calm?: number;
  happy?: number;
  energy?: number;
  content?: number;
  passion?: number;
  confidence?: number;
}

export type Emotion = keyof Emotions;

export type EmotionDescription =
  | "anxious"
  | "calm"
  | "sad"
  | "happy"
  | "tired"
  | "energetic"
  | "irritated"
  | "content"
  | "apathetic"
  | "passionate"
  | "self-critical"
  | "confident";

export const EmotionDescriptions: Record<
  Emotion,
  {
    minimum: EmotionDescription;
    maximum: EmotionDescription;
    displayName: string;
    displayNameMinimum: string;
    color: string;
  }
> = {
  calm: {
    minimum: "anxious",
    maximum: "calm",
    displayName: "anxiousness",
    displayNameMinimum: "calmness",
    color: "#66C2A5",
  },
  happy: {
    minimum: "sad",
    maximum: "happy",
    displayName: "happiness",
    displayNameMinimum: "sadness",
    color: "#FC8D62",
  },
  energy: {
    minimum: "tired",
    maximum: "energetic",
    displayName: "energy",
    displayNameMinimum: "tiredness",
    color: "#8DA0CB",
  },
  content: {
    minimum: "irritated",
    maximum: "content",
    displayName: "irritation",
    displayNameMinimum: "contentment",
    color: "#E78AC3",
  },
  passion: {
    minimum: "apathetic",
    maximum: "passionate",
    displayName: "passion",
    displayNameMinimum: "apathy",
    color: "#A6D854",
  },
  confidence: {
    minimum: "self-critical",
    maximum: "confident",
    displayName: "confidence",
    displayNameMinimum: "self-criticism",
    color: "#FFD92F",
  },
};

export enum Symptom {
  NoSymptoms = "nosymptoms",
  Headache = "headache",
  Fatigue = "fatigue",
  Insomnia = "insomnia",
  ChangesInAppetite = "appetite",
  MuscleTensionSoreness = "tension",
  Constipation = "constipation",
  Diarrhea = "diarrhea",
  Stomachache = "stomachache",
}

export const SymptomDescriptions: Record<Symptom, string> = {
  [Symptom.NoSymptoms]: "no symptoms",
  [Symptom.Headache]: "headache",
  [Symptom.Fatigue]: "fatigue",
  [Symptom.Insomnia]: "insomnia",
  [Symptom.ChangesInAppetite]: "changes in appetite",
  [Symptom.MuscleTensionSoreness]: "muscle tension / soreness",
  [Symptom.Constipation]: "constipation",
  [Symptom.Diarrhea]: "diarrhea",
  [Symptom.Stomachache]: "stomachache",
};

export enum EntrySection {
  EmotionLog = "emotion log",
  SymptomLog = "symptom log",
  DiaryEntry = "diary entry",
}

export interface LogEntry {
  emotions: Emotions;
  symptoms: Symptom[];
  diary: string;
  timestamp: Date;
  seenByTherapist?: boolean;
  hidden?: EntrySection[];
  images?: string[];
}

export interface LogEntryWithTopClassifiedEmotion extends LogEntry {
  topClassifiedEmotion?: ClassifierEmotion;
}

export type DbEntry = Omit<LogEntry, "timestamp"> & {
  timestamp: Timestamp;
};

export interface EmotionCategory {
  category: string;
  color: string;
}

export function getEmotionCategories(
  theme: "light" | "dark"
): EmotionCategory[] {
  switch (theme) {
    case "light": {
      return [
        {
          category: "happiness",
          color: commonColors.light.happinessCategory,
        },
        {
          category: "fear",
          color: commonColors.light.fearCategory,
        },
        {
          category: "confusion",
          color: commonColors.light.confusionCategory,
        },
        {
          category: "sadness",
          color: commonColors.light.sadnessCategory,
        },
        {
          category: "neutral",
          color: commonColors.light.neutralCategory,
        },
        {
          category: "anger",
          color: commonColors.light.angerCategory,
        },
      ];
    }
    case "dark": {
      return [
        {
          category: "happiness",
          color: commonColors.dark.happinessCategory,
        },
        {
          category: "fear",
          color: commonColors.dark.fearCategory,
        },
        {
          category: "confusion",
          color: commonColors.dark.confusionCategory,
        },
        {
          category: "sadness",
          color: commonColors.dark.sadnessCategory,
        },
        {
          category: "neutral",
          color: commonColors.dark.neutralCategory,
        },
        {
          category: "anger",
          color: commonColors.dark.angerCategory,
        },
      ];
    }
  }
}

export type EmotionCategoryVariant =
  | "happiness"
  | "neutral"
  | "anger"
  | "fear"
  | "sadness"
  | "confusion";

export interface EmotionV2 {
  category: EmotionCategoryVariant;
  displayName: string;
  definition: string;
}

export interface SymptomV2 {
  id?: string;
  label: string;
  custom?: boolean;
}

export interface EntryLog {
  id: string;
  emotions: EmotionV2[];
  symptoms: SymptomV2[];
  diary: string;
  timestamp: Date;
  seenByTherapist: boolean;
  hidden: EntrySection[];
  images: string[];
  topClassifiedEmotion: ClassifierEmotion | undefined;
  guidedJournal?: MessageGPT[];
}

export type DbEntryLog = Omit<
  EntryLog,
  "timestamp" | "id" | "topClassifiedEmotion" | "guidedJournal"
> & {
  timestamp: Timestamp;
  topClassifiedEmotion: ClassifierEmotion | null;
  guidedJournal: DbMessageGPT[] | null;
};
