import { FC, useState } from "react";
import { useLocation, useParams } from "wouter";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FeedbackCore } from "common/types/Feedback";
import { useTemplates } from "../../../../hooks/useAppData";
import { useAssignee } from "../../../../hooks/useAssignee";
import Button from "../../../../components/Button";
import Icon from "../../../../components/Icon";
import FeedbackForm from "../../../../components/FeedbackForm";
import Snackbar, { SnackbarStatus } from "../../../../components/Snackbar";
import {
  updateTemplate,
  deleteTemplate,
  sendTemplate,
  checkTemplateExists,
} from "../../../../services/feedback";

export const TemplateDetails: FC = () => {
  const params = useParams();
  const templateId = params.templateId ?? "";
  const [, setLocation] = useLocation();
  const { assignee } = useAssignee();
  const assigneeId = assignee?.uid ?? "";
  const { templates } = useTemplates();
  const template = templates?.find((t) => t.id === templateId);
  const [snackStatus, setSnackStatus] = useState<SnackbarStatus | undefined>(
    undefined
  );

  const handleSendTemplate = async () => {
    const res = await sendTemplate(templateId, assigneeId);

    setSnackStatus(res);
    if (res.severity === "success") {
      setLocation("/templates", { replace: true });
      return;
    }
  };

  const handleDeleteTemplate = async () => {
    const res = await deleteTemplate(templateId);
    setSnackStatus(res);
    if (res.severity === "success") {
      setLocation("/templates", { replace: true });
    }
  };

  const handleUpdateTemplate = async (updatedTemplate: FeedbackCore) => {
    if (
      checkTemplateExists(
        templates,
        updatedTemplate.description,
        updatedTemplate.questions
      )
    ) {
      setSnackStatus({
        severity: "error",
        message: "Identical template already exists.",
      });
      return;
    }
    const res = await updateTemplate(
      templateId,
      updatedTemplate.description,
      updatedTemplate.questions
    );
    if (res.severity === "error") {
      setSnackStatus(res);
    }
  };

  if (template === undefined) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        gap={3}
        paddingTop={10}
        alignItems="center"
        justifyContent="center"
      >
        <Typography>Template not found</Typography>
        <Box>
          <Button
            label="go back to templates"
            onClick={() => setLocation("/templates", { replace: true })}
            size="medium"
            variant="primary"
            leftIcon={<Icon variant="arrowLeft" />}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap={1} paddingTop={1}>
      <Box>
        <Button
          label="back"
          variant="tertiary"
          size="medium"
          onClick={() => setLocation("/templates", { replace: true })}
          leftIcon={<Icon variant="arrowLeft" />}
        />
      </Box>
      <h2>Feedback Template</h2>
      <FeedbackForm
        onSend={handleSendTemplate}
        onDelete={handleDeleteTemplate}
        onUpdate={handleUpdateTemplate}
        feedback={template}
        type="template"
      />
      <Snackbar
        message={snackStatus?.message}
        setVisibility={() => setSnackStatus(undefined)}
        duration={6000}
        custom={true}
        severity={snackStatus?.severity}
      />
    </Box>
  );
};
