import { Box, Chip, Typography } from "@mui/material";
import { EntryLog } from "common/dist/types/LogEntry";
import { FC } from "react";
import { styles } from "../styles";
import {
  ClassifierEmotion,
  ClassifierEmotionDescriptions,
} from "common/dist/types/ClassifierResults";
import { THEME } from "../../../../../theme";

export const ClassifiedLabelTotals: FC<{
  entries: EntryLog[];
}> = ({ entries }) => {
  const emotions = Object.entries(ClassifierEmotionDescriptions)
    .map((c) => ({
      emotion: c,
      value: entries.filter(
        (e) => e.topClassifiedEmotion === (c[0] as ClassifierEmotion)
      ).length,
    }))
    .filter((e) => e.value > 0)
    .sort((a, b) => b.value - a.value);

  return (
    <Box display="flex" flexDirection="column">
      <Typography sx={styles.statisticHeader}>Classifier total</Typography>
      {emotions.map((c) => (
        <Box
          key={`classified-${c.emotion[0]}`}
          display="flex"
          flexDirection="row"
          mt={2}
          color={THEME.colors.grey600}
          alignItems="center"
        >
          <Typography sx={styles.statisticSmall} mr={1}>
            {c.value}
          </Typography>
          <Typography sx={styles.statisticDescription} mr={1}>
            ({Math.round((c.value / entries.length) * 100)}%)
          </Typography>
          <Chip
            label={c.emotion[1].displayName}
            sx={{
              width: "145px",
              height: "22px",
              backgroundColor: c.emotion[1].color,
              borderColor: c.emotion[1].color,
              color: THEME.colors.grey000,
            }}
          />
        </Box>
      ))}
    </Box>
  );
};
