import {
  collection,
  orderBy,
  Query,
  query,
  onSnapshot,
  updateDoc,
  doc,
} from "firebase/firestore";
import { EntryLog, DbEntryLog, EntrySection } from "common/dist/types/LogEntry";
import { db } from "../../features/firebase";

const allowPresent = (entry: EntryLog): boolean => {
  const hide = Object.values(EntrySection)
    .map((s) => entry.hidden.includes(s))
    .reduce((a, b) => a && b);
  if (hide) {
    return false;
  }
  return entry.diary ||
    entry.emotions.length > 0 ||
    entry.symptoms.length > 0 ||
    entry.images.length > 0
    ? true
    : false;
};

const getEntryWithoutHiddenSections = (entry: EntryLog) => {
  return {
    ...entry,
    diary: entry.hidden.includes(EntrySection.DiaryEntry) ? "" : entry.diary,
    images: entry.hidden.includes(EntrySection.DiaryEntry) ? [] : entry.images,
    emotions: entry.hidden.includes(EntrySection.EmotionLog)
      ? []
      : entry.emotions,
    symptoms: entry.hidden.includes(EntrySection.SymptomLog)
      ? []
      : entry.symptoms,
  };
};

export const subscribeToEntries = (
  onUpdate: (entries: EntryLog[] | undefined) => void,
  userId: string
) => {
  const q = query(
    collection(db, "logs", userId, "entriesV2"),
    orderBy("timestamp", "desc")
  ) as Query<DbEntryLog>;

  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const entries = querySnapshot.docs
        .map((doc) => {
          const data = doc.data();
          return getEntryWithoutHiddenSections({
            ...data,
            id: doc.id,
            timestamp: data.timestamp.toDate(),
            topClassifiedEmotion:
              data.topClassifiedEmotion === null
                ? undefined
                : data.topClassifiedEmotion,
            guidedJournal: undefined,
          });
        })
        .filter(allowPresent);
      onUpdate(entries);
    },
    (err) => console.debug("Error subscribing to entries", err)
  );
  return unsubscribe;
};

export const updateEntriesAsSeenByTherapist = async (
  assigneeId: string,
  entryIds: string[]
) => {
  for (const entryId of entryIds) {
    try {
      const entryRef = doc(db, "logs", assigneeId, "entriesV2", entryId);
      await updateDoc(entryRef, {
        seenByTherapist: true,
      });
    } catch (error) {
      console.debug("Error updating entry seen status", error);
    }
  }
};
