import { FC } from "react";
import SnackbarMUI from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

export interface SnackbarStatus {
  status?: boolean;
  severity?: SnackSeverity;
  message: string;
}

export type SnackSeverity = "success" | "error" | "info" | "warning";

export interface SnackbarProps {
  message: string | undefined;
  custom?: boolean;
  severity?: SnackSeverity;
  duration?: number;
  setVisibility: () => void;
  position?: {
    vertical: "bottom" | "top";
    horizontal: "left" | "right" | "center";
  };
}

const Snackbar: FC<SnackbarProps> = ({
  message,
  custom,
  severity,
  duration,
  setVisibility,
  position,
}) => {
  if (!custom) {
    return (
      <SnackbarMUI
        open={message !== undefined}
        onClose={setVisibility}
        message={message}
        autoHideDuration={duration}
        anchorOrigin={position}
      />
    );
  }
  return (
    <SnackbarMUI
      open={message !== undefined}
      onClose={setVisibility}
      autoHideDuration={duration}
    >
      {message ? (
        <Alert onClose={setVisibility} variant="filled" severity={severity}>
          {message}
        </Alert>
      ) : undefined}
    </SnackbarMUI>
  );
};

export default Snackbar;
