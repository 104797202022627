import { FC, useState } from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Modal from "@mui/material/Modal";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import { useTheme } from "@mui/material";
import Icon from "./Icon";
import Button from "./Button";

interface ThumbnailGroupProps {
  images: string[];
  onDelete?: (image: string) => void;
}

export const ThumbnailGroup: FC<ThumbnailGroupProps> = ({
  images,
  onDelete,
}) => {
  const theme = useTheme();
  const [fullScreenImage, setFullScreenImage] = useState<string>();
  return (
    <Box style={{ width: 550 }}>
      <Modal
        open={!!fullScreenImage}
        onClose={() => setFullScreenImage(undefined)}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src={fullScreenImage}
            alt={fullScreenImage}
            style={{
              maxHeight: window.innerHeight * 0.9,
              maxWidth: window.innerWidth * 0.9,
              objectFit: "contain",
            }}
          />
        </Box>
      </Modal>
      {images.length > 0 && (
        <ImageList cols={5} gap={8} rowHeight={onDelete ? 140 : 100}>
          {images.map((item) => (
            <ImageListItem key={item}>
              <img
                src={item}
                alt={item}
                loading="lazy"
                style={{ borderRadius: 10, height: 100 }}
                onClick={() => setFullScreenImage(item)}
              />
              {onDelete && (
                <ImageListItemBar
                  position="below"
                  style={{ height: 40 }}
                  actionIcon={
                    <Button
                      label="remove"
                      variant="tertiary"
                      aria-label={"Remove photo"}
                      onClick={() => onDelete(item)}
                      leftIcon={<Icon variant="delete" />}
                      color={theme.palette.grey[700]}
                    />
                  }
                />
              )}
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </Box>
  );
};
