import { THEME } from "../../../../theme";

export const styles = {
  chipGroup: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginBottom: "30px",
  },
  chipButton: {
    marginRight: "10px",
    height: "50px",
    paddingLeft: "25px",
    paddingRight: "25px",
    fontSize: "16px",
    borderRadius: "10px",
  },
  chipThin: {
    marginRight: "10px",
    fontSize: "16px",
    width: "165px",
    height: "30px",
  },
  legendChip: {
    width: "41px",
    height: "9px",
    borderRadius: "20px",
  },
  statistic: {
    color: THEME.colors.grey600,
    fontSize: "32px",
    fontWeight: "700",
    marginLeft: "5px",
    marginRight: "5px",
  },
  statisticDescription: {
    color: THEME.colors.grey600,
    fontSize: "14px",
    display: "flex",
    flexDirection: "row",
    alignItems: "baseline",
  },
  statisticHeader: {
    color: THEME.colors.grey600,
    textTransform: "uppercase",
  },
  statisticTypography: {
    color: THEME.colors.grey600,
    fontSize: "14px",
    alignItems: "baseline",
  },
  statisticSmall: {
    color: THEME.colors.grey600,
    fontSize: "20px",
    fontWeight: 700,
  },
  statisticLabelSmall: {
    color: THEME.colors.grey600,
    fontSize: "12px",
    alignItems: "baseline",
    marginLeft: "3px",
  },
};
