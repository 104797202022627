import { FC, useState } from "react";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Tooltip from "@mui/material/Tooltip";
import { BugReport } from "common/types/BugReport";

import { functionsClient } from "../firebase";
import FormCard, {
  actionContainerStyles,
  contentContainerStyles,
} from "../../components/FormCard";
import InfoBox from "../../components/InfoBox";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";

const feedbackEmotions = [
  "neutral",
  "confused",
  "irritated",
  "angry",
  "sad",
  "anxious",
  "happy",
  "excited",
];

const BugReportForm: FC<{ setReportSent: () => void }> = ({
  setReportSent,
}) => {
  const [selectedEmotion, setSelectedEmotion] = useState<string | null>(null);
  const [requestResponse, setRequestResponse] = useState(false);
  const [allowFollowUp, setAllowFollowUp] = useState(false);
  const [feedback, setFeedback] = useState<string>();
  const [loading, setLoading] = useState(false);

  const handleSend = () => {
    if (feedback) {
      setLoading(true);
      const bugReport: BugReport = {
        feedback,
        requestResponse,
        allowFollowUp,
        fromTherapist: true,
      };

      if (selectedEmotion) {
        bugReport.emotion = selectedEmotion;
      }

      functionsClient
        .sendBugReport({
          bugReport,
        })
        .then(() => {
          setReportSent();
          setSelectedEmotion(null);
          setRequestResponse(false);
          setAllowFollowUp(false);
          setFeedback(undefined);
          setLoading(false);
        });
    }
  };

  return (
    <FormCard>
      <Box sx={contentContainerStyles}>
        <FormGroup style={{ width: 400 }}>
          <FormLabel>How are you feeling about this issue?</FormLabel>
          <RadioGroup
            value={selectedEmotion}
            onChange={(e, value) => setSelectedEmotion(value)}
            sx={{ mt: 1 }}
          >
            {feedbackEmotions.map((e) => (
              <FormControlLabel
                key={e}
                value={e}
                control={<Radio />}
                label={e.replace(/^\w/, (c) => c.toUpperCase())}
              />
            ))}
          </RadioGroup>
        </FormGroup>

        <Box mt={4}>
          <FormLabel>Please give your feedback here*:</FormLabel>
          <TextInput
            required
            multiline
            value={feedback}
            onChange={setFeedback}
            label=""
          />
        </Box>

        <FormGroup sx={{ mt: 4 }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={requestResponse}
                onChange={(e, value) => setRequestResponse(value)}
              />
            }
            label="I want to receive a response to this feedback via email"
          />

          <FormControlLabel
            value={true}
            control={
              <Checkbox
                checked={allowFollowUp}
                onChange={(e, value) => setAllowFollowUp(value)}
              />
            }
            label="I am open to follow-up questions about this issue via email"
          />
        </FormGroup>
        <InfoBox content="Clicking on either of these options will attach your email to this feedback ticket until the issue is resolved." />
      </Box>
      <Box sx={actionContainerStyles}>
        <Tooltip
          title={
            !feedback ? "Please add feedback to text field before sending" : ""
          }
        >
          <span>
            <Button
              variant="primary"
              onClick={handleSend}
              disabled={loading || !feedback}
              loading={loading}
              label="send"
              size="medium"
            />
          </span>
        </Tooltip>
      </Box>
    </FormCard>
  );
};

export default BugReportForm;
