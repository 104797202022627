import { FC } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import EmailOutlined from "@mui/icons-material/EmailOutlined";
import AccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import HistoryEdu from "@mui/icons-material/HistoryEdu";
import FeedbackOutlined from "@mui/icons-material/FeedbackOutlined";
import PersonAddOutlined from "@mui/icons-material/PersonAddOutlined";
import LockOutlined from "@mui/icons-material/LockOutlined";
import SchoolOutlined from "@mui/icons-material/SchoolOutlined";
import BusinessCenterOutlined from "@mui/icons-material/BusinessCenterOutlined";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import Google from "@mui/icons-material/Google";
import VpnKeyOutlined from "@mui/icons-material/VpnKeyOutlined";
import MoreVertOutlined from "@mui/icons-material/MoreVertOutlined";
import DeleteOutlined from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import MarkChatReadOutlinedIcon from "@mui/icons-material/MarkChatReadOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import BackIcon from "@mui/icons-material/ArrowBack";
import SaveIcon from "@mui/icons-material/Save";
import BookmarkAddIcon from "@mui/icons-material/BookmarkAdd";
import SendIcon from "@mui/icons-material/Send";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ArrowCircleDownOutlinedIcon from "@mui/icons-material/ArrowCircleDownOutlined";
import ArrowCircleUpOutlinedIcon from "@mui/icons-material/ArrowCircleUpOutlined";
import ThumbDownOutlined from "@mui/icons-material/ThumbDownOutlined";
import ThumbUpOutlined from "@mui/icons-material/ThumbUpOutlined";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import TrendingUp from "@mui/icons-material/TrendingUp";
import TrendingDown from "@mui/icons-material/TrendingDown";
import TrendingFlat from "@mui/icons-material/TrendingFlat";

export type IconVariant =
  | "arrowRight"
  | "arrowDown"
  | "arrowLeft"
  | "arrowDownCircle"
  | "arrowUpCircle"
  | "email"
  | "userCircle"
  | "userAdd"
  | "history"
  | "feedback"
  | "lock"
  | "edu"
  | "work"
  | "info"
  | "google"
  | "key"
  | "moreVertical"
  | "delete"
  | "edit"
  | "chatComplete"
  | "warning"
  | "send"
  | "addBookmark"
  | "save"
  | "circleMinus"
  | "circlePlus"
  | "thumbUp"
  | "thumbDown"
  | "addPhoto"
  | "globe"
  | "checkboxDone"
  | "checkboxEmpty"
  | "externalLink"
  | "trendingUp"
  | "trendingDown"
  | "trendingFlat";

export interface IconProps {
  variant?: IconVariant;
  size?: "small" | "medium" | "large";
  color?: string;
}

const Icon: FC<IconProps> = ({ variant, size, color }) => {
  switch (variant) {
    case "arrowRight": {
      return <KeyboardArrowRightIcon fontSize={size} />;
    }
    case "arrowDown": {
      return <KeyboardArrowDownIcon fontSize={size} />;
    }
    case "arrowLeft": {
      return <BackIcon fontSize={size} sx={{ color: color }} />;
    }
    case "arrowUpCircle": {
      return (
        <ArrowCircleUpOutlinedIcon fontSize={size} sx={{ color: color }} />
      );
    }
    case "arrowDownCircle": {
      return (
        <ArrowCircleDownOutlinedIcon fontSize={size} sx={{ color: color }} />
      );
    }
    case "email": {
      return <EmailOutlined fontSize={size} />;
    }
    case "userCircle": {
      return <AccountCircleOutlined fontSize={size} />;
    }
    case "history": {
      return <HistoryEdu fontSize={size} />;
    }
    case "userAdd": {
      return <PersonAddOutlined fontSize={size} />;
    }
    case "feedback": {
      return <FeedbackOutlined fontSize={size} />;
    }
    case "lock": {
      return <LockOutlined fontSize={size} />;
    }
    case "edu": {
      return <SchoolOutlined fontSize={size} />;
    }
    case "work": {
      return <BusinessCenterOutlined fontSize={size} />;
    }
    case "info": {
      return <InfoOutlined fontSize={size} sx={{ color: color }} />;
    }
    case "google": {
      return <Google fontSize={size} sx={{ color: color }} />;
    }
    case "key": {
      return <VpnKeyOutlined fontSize={size} sx={{ color: color }} />;
    }
    case "moreVertical": {
      return <MoreVertOutlined fontSize={size} sx={{ color: color }} />;
    }
    case "delete": {
      return <DeleteOutlined fontSize={size} sx={{ color: color }} />;
    }
    case "edit": {
      return <EditIcon fontSize={size} sx={{ color: color }} />;
    }
    case "chatComplete": {
      return <MarkChatReadOutlinedIcon fontSize={size} sx={{ color: color }} />;
    }
    case "warning": {
      return <WarningAmberIcon fontSize={size} sx={{ color: color }} />;
    }
    case "send": {
      return <SendIcon fontSize={size} sx={{ color: color }} />;
    }
    case "save": {
      return <SaveIcon fontSize={size} sx={{ color: color }} />;
    }
    case "addBookmark": {
      return <BookmarkAddIcon fontSize={size} sx={{ color: color }} />;
    }
    case "circleMinus": {
      return (
        <RemoveCircleOutlineOutlinedIcon
          fontSize={size}
          sx={{ color: color }}
        />
      );
    }
    case "circlePlus": {
      return (
        <AddCircleOutlineOutlinedIcon fontSize={size} sx={{ color: color }} />
      );
    }
    case "thumbUp": {
      return <ThumbUpOutlined fontSize={size} sx={{ color: color }} />;
    }
    case "thumbDown": {
      return <ThumbDownOutlined fontSize={size} sx={{ color: color }} />;
    }
    case "addPhoto": {
      return (
        <AddPhotoAlternateOutlinedIcon fontSize={size} sx={{ color: color }} />
      );
    }
    case "globe": {
      return <LanguageOutlinedIcon fontSize={size} sx={{ color: color }} />;
    }
    case "checkboxDone": {
      return <CheckBoxIcon fontSize={size} sx={{ color: color }} />;
    }
    case "checkboxEmpty": {
      return <CheckBoxOutlineBlankIcon fontSize={size} sx={{ color: color }} />;
    }
    case "externalLink": {
      return <OpenInNewIcon fontSize={size} sx={{ color: color }} />;
    }
    case "trendingUp": {
      return <TrendingUp fontSize={size} sx={{ color: color }} />;
    }
    case "trendingDown": {
      return <TrendingDown fontSize={size} sx={{ color: color }} />;
    }
    case "trendingFlat": {
      return <TrendingFlat fontSize={size} sx={{ color: color }} />;
    }
    default:
      return null;
  }
};

export default Icon;
