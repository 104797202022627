import { FC } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import HorizontalBar from "../../../../../../components/HorizontalBar";
import { styles } from "../../styles";

interface LoggedCategoriesProps {
  categoriesData: { category: string; count: number; color: string }[];
}

const LoggedCategories: FC<LoggedCategoriesProps> = ({ categoriesData }) => {
  const total = categoriesData.reduce((sum, v) => sum + v.count, 0);

  return (
    <Box>
      <Typography sx={styles.statisticHeader} mb={1}>
        logged categories
      </Typography>

      <Box display="flex" flexDirection="column" gap={1}>
        {categoriesData
          .sort((a, b) => b.count - a.count)
          .map((el) => (
            <HorizontalBar
              key={`category-bar-${el.category}`}
              values={[
                {
                  color: el.color ?? "",
                  value: el.count,
                },
              ]}
              total={total}
              label={el.category}
              labelWidth={80}
              showRatio
            />
          ))}
      </Box>
    </Box>
  );
};

export default LoggedCategories;
