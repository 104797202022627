import { FC, useState } from "react";
import Box from "@mui/material/Box";
import { Provider } from "common/dist/types/User";

import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import Snackbar, { SnackbarStatus } from "../../components/Snackbar";
import Icon from "../../components/Icon";
import FormCard, {
  contentContainerStyles,
  actionContainerStyles,
} from "../../components/FormCard";
import { changePwd } from "../../services/user";
import { haveProvider } from "./utils";

interface ChangePwdProps {
  providers: Provider[];
}

const content = {
  create: {
    title: "Create Password",
    buttonLabel: "create",
  },
  update: {
    title: "Change Password",
    buttonLabel: "change password",
  },
};

const ChangePwd: FC<ChangePwdProps> = ({ providers }) => {
  const [currPwd, setCurrPwd] = useState<string>("");
  const [newPwd, setNewPwd] = useState<string>("");
  const [newPwdRepeat, setNewPwdRepeat] = useState<string>("");

  const [inputError, setInputError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackStatus, setSnackStatus] = useState<SnackbarStatus | undefined>(
    undefined
  );

  const contentKey = haveProvider("password", providers) ? "update" : "create";

  const handlePwdChange = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      const res = await changePwd(currPwd, newPwd);
      setSnackStatus({ status: res.status, message: res.message });

      if (res.status) {
        setCurrPwd("");
        setNewPwd("");
        setNewPwdRepeat("");
      }
    } catch (error) {
      console.debug("Error changing email", error);
      setSnackStatus({
        status: false,
        message: "Sorry, could not update password. Please try again",
      });
    } finally {
      setLoading(false);
    }
  };

  const checkPwd = () => {
    if (newPwd !== newPwdRepeat && newPwdRepeat) {
      setInputError("Passwords do not match");
      return;
    }

    if (newPwd === newPwdRepeat && inputError !== undefined) {
      setInputError(undefined);
    }
  };
  return (
    <FormCard component="form" onSubmit={handlePwdChange}>
      <Box sx={contentContainerStyles}>
        <Box display="flex" flexDirection="row" gap={1} alignItems="center">
          <Icon variant="lock" size="large" />
          <h2>{content[contentKey].title}</h2>
        </Box>
        {contentKey === "update" && (
          <TextInput
            label="Current Password"
            onChange={setCurrPwd}
            value={currPwd}
            disabled={loading}
            type="password"
            required
          />
        )}
        <TextInput
          label="New Password"
          onChange={setNewPwd}
          value={newPwd}
          disabled={loading}
          type="password"
          required
          onBlur={checkPwd}
        />
        <TextInput
          label="Repeat New Password"
          onChange={setNewPwdRepeat}
          value={newPwdRepeat}
          disabled={loading}
          type="password"
          required
          onBlur={checkPwd}
          error={inputError}
        />
      </Box>
      <Box sx={actionContainerStyles}>
        <Button
          variant="primary"
          loading={loading}
          disabled={!newPwd || !newPwdRepeat || newPwd !== newPwdRepeat}
          label={content[contentKey].buttonLabel}
          size="medium"
          type="submit"
        />
      </Box>
      <Snackbar
        message={snackStatus?.message}
        custom={true}
        duration={5000}
        severity={snackStatus?.status ? "success" : "error"}
        setVisibility={() => setSnackStatus(undefined)}
      />
    </FormCard>
  );
};

export default ChangePwd;
