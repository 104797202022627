import { FC } from "react";
import Box from "@mui/material/Box";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import { signup } from "../../services/auth";

export const SignUpForm: FC = () => {
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { email, password, repeatedPassword } =
      event.target as HTMLFormElement;
    if (password.value === repeatedPassword.value) {
      const res = await signup(email.value, password.value);
      if (!res.status && res.message !== "claims not set") {
        alert(res.message);
      }
    } else {
      alert("Passwords do not match");
    }
  };

  return (
    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
      <TextInput
        required
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        autoFocus
      />
      <TextInput
        required
        name="password"
        label="Password"
        type="password"
        id="password"
      />
      <TextInput
        required
        name="repeatedPassword"
        label="Confirm Password"
        type="password"
        id="repeatedPassword"
      />
      <Button type="submit" fullWidth variant="primary" label="sign up" />
    </Box>
  );
};
