import {
  collection,
  query,
  where,
  onSnapshot,
  Query,
  orderBy,
  doc,
  setDoc,
  addDoc,
  deleteDoc,
  updateDoc,
  documentId,
  getDocs,
  Timestamp,
} from "firebase/firestore";
import {
  DbFeedback,
  DbFeedbackTemplate,
  Feedback,
  FeedbackDraft,
  FeedbackQuestion,
  FeedbackTemplate,
} from "common/dist/types/Feedback";
import { isPresent } from "common/dist/feat/util";
import { auth, db, functionsClient } from "../features/firebase";
import { SnackSeverity } from "../components/Snackbar";
import { INTERNAL_ERROR } from "./constants";
import { getCurrentUser } from "./auth";

export const subscribeToFeedbacks = (
  onUpdate: (feedbacks: { feedback: Feedback; id: string }[]) => void
) => {
  const q = query(
    collection(db, "feedbacks"),
    where("therapist", "==", auth.currentUser?.uid),
    orderBy("timestamp", "desc")
  ) as Query<DbFeedback>;

  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const feedbacks = querySnapshot.docs
        .map((doc) => {
          const data = doc.data();
          if (!data.questions || data.questions.length === 0) {
            console.error("Invalid feedback", JSON.stringify(data));
            return undefined;
          }
          return {
            feedback: {
              ...data,
              timestamp: data.timestamp.toDate(),
              lastSeenByPatient: data.lastSeenByPatient?.toDate(),
              lastSeenByTherapist: data.lastSeenByTherapist?.toDate(),
              lastUpdatedByPatient: data.lastUpdatedByPatient?.toDate(),
            },
            id: doc.id,
          };
        })
        .filter(isPresent);
      onUpdate(feedbacks);
    },
    (err) => console.error("Error subscribing to feedbacks", err)
  );
  return unsubscribe;
};

export const subscribeToDrafts = (
  receiverUid: string,
  onUpdate: (drafts: { draft: FeedbackDraft; id: string }[]) => void
) => {
  const q = query(
    collection(db, "therapists", auth.currentUser!.uid, "feedbackdrafts"),
    where("receiver", "==", receiverUid)
  ) as Query<FeedbackDraft>;

  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const drafts = querySnapshot.docs
        .map((doc) => {
          const draft = doc.data();
          if (!draft.receiver || typeof draft.description !== "string") {
            console.error("Invalid draft", JSON.stringify(draft));
            return undefined;
          }
          return {
            draft: draft,
            id: doc.id,
          };
        })
        .filter(isPresent);
      onUpdate(drafts);
    },
    (err) => console.error("Error subscribing to drafts", err)
  );
  return unsubscribe;
};

export async function createDraft(
  receiver: string
): Promise<string | undefined> {
  try {
    const uid = getCurrentUser().uid;

    const draft = {
      receiver,
      description: "",
      questions: [],
    };

    const docRef = await addDoc(
      collection(db, "therapists", uid, "feedbackdrafts"),
      draft
    );

    return docRef.id;
  } catch (error) {
    console.debug(error);
    return undefined;
  }
}

export const subscribeToDraft = (
  draftId: string,
  onUpdate: (draft: { draft: FeedbackDraft; id: string } | undefined) => void
) => {
  const unsubscribe = onSnapshot(
    doc(db, "therapists", auth.currentUser!.uid, "feedbackdrafts", draftId),
    (doc) => {
      const draft = doc.data() as FeedbackDraft;
      if (!doc.exists()) {
        onUpdate(undefined);
      } else {
        onUpdate({ draft: draft, id: doc.id });
      }
    },
    (err) => console.error("Error subscribing to drafts", err)
  );
  return unsubscribe;
};

export async function updateDraft(
  draftId: string,
  receiver: string,
  description: string,
  questions: FeedbackQuestion[]
): Promise<{ severity: SnackSeverity; message: string }> {
  try {
    const uid = getCurrentUser().uid;

    const draft: FeedbackDraft = {
      receiver,
      description,
      questions,
    };

    await setDoc(doc(db, "therapists", uid, "feedbackdrafts", draftId), draft);
    return { severity: "success", message: "Feedback draft updated" };
  } catch (e) {
    return { severity: "error", message: INTERNAL_ERROR };
  }
}

export async function deleteDraft(
  draftId: string
): Promise<{ severity: SnackSeverity; message: string }> {
  try {
    const uid = getCurrentUser().uid;

    await deleteDoc(doc(db, "therapists", uid, "feedbackdrafts", draftId));
    return { severity: "success", message: "Feedback draft deleted" };
  } catch (e) {
    return { severity: "error", message: INTERNAL_ERROR };
  }
}

export async function sendDraft(
  draftId: string
): Promise<{ severity: SnackSeverity; message: string }> {
  try {
    await functionsClient.sendFeedbackRequest({
      draftId: draftId,
    });
    return { severity: "success", message: "New Feedback request send" };
  } catch (e) {
    return { severity: "error", message: INTERNAL_ERROR };
  }
}

export const subscribeToTemplates = (
  onUpdate: (templates: FeedbackTemplate[]) => void
) => {
  const q = query(
    collection(db, "therapists", auth.currentUser!.uid, "feedbackTemplates")
  ) as Query<DbFeedbackTemplate>;

  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const templates = querySnapshot.docs
        .map((doc) => {
          const template = doc.data();
          if (
            typeof template.description !== "string" ||
            !Array.isArray(template.questions)
          ) {
            console.error("Invalid template", JSON.stringify(template));
            return undefined;
          }
          return {
            ...template,
            timestamp: template.timestamp.toDate(),
            id: doc.id,
          };
        })
        .filter(isPresent);
      onUpdate(templates);
    },
    (err) => console.error("Error subscribing to templates", err)
  );
  return unsubscribe;
};

export async function createTemplate(
  description: string,
  questions: FeedbackQuestion[]
): Promise<{ severity: SnackSeverity; message: string }> {
  try {
    const uid = getCurrentUser().uid;
    const template: DbFeedbackTemplate = {
      description,
      questions,
      timestamp: Timestamp.fromDate(new Date()),
    };

    await addDoc(
      collection(db, "therapists", uid, "feedbackTemplates"),
      template
    );
    return { severity: "success", message: "New feedback template created" };
  } catch (e) {
    return { severity: "error", message: INTERNAL_ERROR };
  }
}

export async function updateTemplate(
  templateId: string,
  description: string,
  questions: FeedbackQuestion[]
): Promise<{ severity: SnackSeverity; message: string }> {
  try {
    const uid = auth.currentUser!.uid;
    const template = { description, questions };

    await updateDoc(
      doc(db, "therapists", uid, "feedbackTemplates", templateId),
      template
    );
    return { severity: "success", message: "Feedback template uptaded" };
  } catch (e) {
    return { severity: "error", message: INTERNAL_ERROR };
  }
}

export async function deleteTemplate(
  templateId: string
): Promise<{ severity: SnackSeverity; message: string }> {
  try {
    const uid = getCurrentUser().uid;

    await deleteDoc(
      doc(db, "therapists", uid, "feedbackTemplates", templateId)
    );
    return { severity: "success", message: "Feedback template deleted" };
  } catch (e) {
    return { severity: "error", message: INTERNAL_ERROR };
  }
}

export async function sendTemplate(
  templateId: string,
  assigneeId: string
): Promise<{ severity: SnackSeverity; message: string }> {
  try {
    await functionsClient.sendFeedbackRequestFromTemplate({
      templateId: templateId,
      assigneeId: assigneeId,
    });
    return { severity: "success", message: "New feedback request send" };
  } catch (e) {
    return { severity: "error", message: INTERNAL_ERROR };
  }
}

export const checkTemplateExists = (
  templates: FeedbackTemplate[] | undefined,
  description: string,
  questions: FeedbackQuestion[]
) =>
  !!templates?.find(
    (t) =>
      t.description === description &&
      t.questions.every((q) =>
        questions.find(
          (qu) => q.question === qu.question && q.binary === qu.binary
        )
      ) &&
      questions.every((q) =>
        t.questions.find(
          (qu) => q.question === qu.question && q.binary === qu.binary
        )
      )
  );

export function updateFeedbackAnswersSeen(feedbackIds: string[]) {
  getDocs(
    query(
      collection(db, "feedbacks"),
      where(documentId(), "in", feedbackIds)
    ) as Query<DbFeedback>
  ).then((feedbackDocs) => {
    feedbackDocs.forEach((fdoc) => {
      updateDoc(fdoc.ref, {
        lastSeenByTherapist: new Date(),
      });
    });
  });
}
