import { FC, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import {
  VictoryAxis,
  VictoryChart,
  VictoryScatter,
  VictoryTheme,
  VictoryTooltip,
} from "victory";
import { getDatesInRange } from "common/dist/feat/date";
import { addDays, subDays, isSameDay } from "date-fns";
import Chip from "../../../../../../components/Chip";
import StatsLegend from "../../../../../../components/StatsLegend";
import { THEME } from "../../../../../../theme";
import { styles } from "../../styles";

export enum GraphVariable {
  Homework,
  HomeworkAnswer,
  Feedback,
  FeedbackAnswer,
}

const COLORS = {
  [GraphVariable.Homework]: "#92C194",
  [GraphVariable.HomeworkAnswer]: "#59A15C",
  [GraphVariable.Feedback]: "#EF89AE",
  [GraphVariable.FeedbackAnswer]: "#F04F89",
};

const LabelledVariables = [
  { variable: GraphVariable.Homework, label: "Homework assigned" },
  { variable: GraphVariable.HomeworkAnswer, label: "Homework answered" },
  { variable: GraphVariable.Feedback, label: "Feedback requested" },
  { variable: GraphVariable.FeedbackAnswer, label: "Feedback received" },
];

export type GraphValues = {
  x: Date;
  variant: GraphVariable;
}[];

interface HomeworkFeedbackGraphProps {
  fromDate: Date;
  values: GraphValues;
}

const HomeworkFeedbackGraph: FC<HomeworkFeedbackGraphProps> = ({
  fromDate,
  values,
}) => {
  const [selectedVariables, setSelectedVariables] = useState([
    GraphVariable.Homework,
    GraphVariable.HomeworkAnswer,
    GraphVariable.Feedback,
    GraphVariable.FeedbackAnswer,
  ]);

  const variants = new Set(
    values.map((v) => v.variant).filter((v) => selectedVariables.includes(v))
  );
  const legendsData = [...variants].map((l) => ({
    legend: l,
    count: values.filter((v) => v.variant === l).length,
  }));

  const valuesWithY = values
    .filter((v) => selectedVariables.includes(v.variant))
    .map((v, _, a) => ({
      ...v,
      y: a.filter((v1) => isSameDay(v1.x!, v.x!)).indexOf(v) + 1,
    }));

  const width = 895;
  const maxY = Math.max(...valuesWithY.map((v) => v.y), 1);
  const height = 40 + 13 * maxY;

  return (
    <Box component="section" display="flex" flexDirection="column" mr={10}>
      <Typography sx={styles.statisticHeader}>Homework & feedback</Typography>

      <Stack alignItems="end" width={width - 50} mb={1}>
        {legendsData.map((l) => (
          <StatsLegend
            key={`graph-legend-${l.legend}`}
            label={l.count}
            color={COLORS[l.legend]}
          />
        ))}
      </Stack>

      <svg width={width} height={height}>
        <VictoryChart
          height={height}
          width={width}
          theme={VictoryTheme.material}
          padding={{ bottom: 40, left: 20, right: 25 }}
          standalone={false}
        >
          <VictoryAxis
            dependentAxis
            domain={[0.5, maxY + 0.5]}
            style={{
              axis: { display: "none" },
              ticks: { display: "none" },
              tickLabels: { display: "none" },
              grid: { stroke: "#D9D9D9", strokeDasharray: "0, 0" },
            }}
            tickValues={Array.from(Array(maxY), (_, i) => i + 1.5)}
          />

          <VictoryAxis
            tickCount={5}
            tickValues={getDatesInRange(
              subDays(fromDate, 1),
              addDays(new Date(), 1)
            )}
            tickFormat={(t) =>
              `${new Date(t).toLocaleDateString(undefined, {
                day: "numeric",
                month: "short",
              })}`
            }
            fixLabelOverlap={true}
            style={{
              tickLabels: { fontSize: 14, angle: -30 },
              grid: { display: "none" },
            }}
          />

          <VictoryScatter
            data={valuesWithY}
            x="x"
            y="y"
            size={6}
            style={{
              data: {
                fill: ({ datum }) => COLORS[datum.variant as GraphVariable],
                strokeWidth: 1,
                stroke: THEME.colors.grey000,
              },
            }}
            labelComponent={
              <VictoryTooltip
                renderInPortal={false}
                y={height}
                pointerLength={0}
                flyoutPadding={10}
                flyoutStyle={{
                  fill: THEME.colors.grey000,
                  stroke: THEME.colors.grey600,
                }}
                style={{ fontSize: 16, fill: THEME.colors.grey600 }}
                constrainToVisibleArea
              />
            }
            labels={({ datum }) => datum.x.toLocaleDateString()}
          />
        </VictoryChart>
      </svg>

      <Box sx={styles.chipGroup} mt={2} pb={5} gap={1}>
        <Chip
          label="show all"
          variant="secondary"
          size="medium"
          onClick={() =>
            setSelectedVariables([
              GraphVariable.Homework,
              GraphVariable.HomeworkAnswer,
              GraphVariable.Feedback,
              GraphVariable.FeedbackAnswer,
            ])
          }
        />
        {LabelledVariables.map((v) => {
          const selected = selectedVariables.includes(v.variable);
          return (
            <Chip
              key={`${v.variable}-toggle`}
              label={v.label}
              onClick={() => {
                setSelectedVariables(
                  selected
                    ? selectedVariables.filter((e) => e !== v.variable)
                    : selectedVariables.concat([v.variable])
                );
              }}
              variant={selected ? "custom" : "secondary"}
              backgroundColor={selected ? COLORS[v.variable] : undefined}
              size="medium"
            />
          );
        })}
      </Box>
    </Box>
  );
};

export default HomeworkFeedbackGraph;
