import { FC } from "react";
import { Box, Chip, Tooltip, Typography, useTheme } from "@mui/material";
import { HomeworkTemplate } from "common/dist/types/Homework";
import { ItemCard } from "../../../../../components/ItemCard";
import Icon from "../../../../../components/Icon";
import { styles } from "../styles";

interface TemplateCardProps {
  template: HomeworkTemplate;
  onClick: () => void;
  editable: boolean;
}

const TemplateCard: FC<TemplateCardProps> = ({
  template,
  onClick,
  editable,
}) => {
  const theme = useTheme();

  return (
    <ItemCard>
      <Box onClick={onClick} sx={{ cursor: "pointer" }}>
        <Box display="flex" flexDirection="row">
          <Tooltip
            title={
              !editable
                ? ""
                : template.public
                ? "This template is shared with the maind community"
                : "This template is visible only to you"
            }
          >
            <Typography variant="caption" pb={1}>
              {editable ? "BY YOU " : `BY ${template.createdBy}`}
              {editable &&
                (template.public ? (
                  <Icon variant="globe" />
                ) : (
                  <Icon variant="lock" />
                ))}
            </Typography>
          </Tooltip>
          <Box flexGrow={1} />
          {editable && (
            <Tooltip title="Edit">
              <Icon size="small" variant="edit" />
            </Tooltip>
          )}
        </Box>
        {template.title && (
          <Typography variant="subtitle2" whiteSpace="pre-line">
            {template.title}
          </Typography>
        )}
        <Typography
          whiteSpace="pre-line"
          textOverflow="ellipsis"
          overflow="hidden"
          display="-webkit-box"
          sx={{
            WebkitLineClamp: "3",
            WebkitBoxOrient: "vertical",
          }}
        >
          {template.description}
        </Typography>
        {template.labels?.map((label) => (
          <Chip
            key={label}
            label={label}
            variant="outlined"
            sx={{
              ...styles.chip,
              borderColor: theme.palette.common.white,
              color: theme.palette.common.white,
            }}
          />
        ))}
      </Box>
    </ItemCard>
  );
};

export default TemplateCard;
