import React, { FC, PropsWithChildren } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { THEME } from "../../theme";
import { IconVariant } from "../Icon";

import Icon from "../Icon";

export type InfoBoxVariant = "info" | "warning";

interface InfoBoxProps {
  content: string;
  variant?: InfoBoxVariant;
}

function getStyles(variant: InfoBoxVariant): {
  icon: IconVariant;
  backgroundColor: string;
  borderColor: string;
} {
  switch (variant) {
    case "info": {
      return {
        icon: "info",
        backgroundColor: "rgba(146, 209, 255, 0.3)",
        borderColor: "#62BDFF",
      };
    }
    case "warning": {
      return {
        icon: "warning",
        backgroundColor: "rgba(255, 251, 147, 0.8)",
        borderColor: "FFFA76",
      };
    }
    default:
      return {
        icon: "info",
        backgroundColor: "rgba(146, 209, 255, 0.3)",
        borderColor: "#62BDFF",
      };
  }
}

const InfoBox: FC<PropsWithChildren<InfoBoxProps>> = ({
  children,
  content,
  variant = "info",
}) => {
  const styles = getStyles(variant);
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="start"
      gap={1}
      sx={{
        backgroundColor: styles.backgroundColor,
        borderWidth: 2,
        borderColor: styles.borderColor,
        padding: 2,
        borderRadius: 3,
      }}
    >
      <Icon variant={styles.icon} color={THEME.colors.grey600} />
      <Box display="flex" flexDirection="column" gap={2}>
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <Typography variant="body2" color={THEME.colors.grey700}>
            {content}
          </Typography>
        </Box>
        {children}
      </Box>
    </Box>
  );
};

export default InfoBox;
