import { FC, useEffect, useState } from "react";
import { Box, Typography, CircularProgress } from "@mui/material";
import { auth } from "../firebase";
import { getAssignees } from "../../services/assignees";
import Button from "../../components/Button";
import { AssigneeV2 } from "./utils";
import AssigneeCard from "./AssigneeCard";

const Assignees: FC = () => {
  const [assignees, setAssignees] = useState<AssigneeV2[] | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);

  const fetchAssignees = async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      setLoading(true);
      const data = await getAssignees(currentUser.uid);
      if (data) {
        setAssignees(data.sort((a, b) => a.name.localeCompare(b.name)));
      } else {
        setAssignees(undefined);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssignees();
  }, []);

  return (
    <>
      <h1>Clients</h1>
      {loading ? (
        <CircularProgress size={100} />
      ) : (
        <>
          {assignees ? (
            <>
              {assignees.length > 0 ? (
                <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2}>
                  {assignees.map((a) => (
                    <AssigneeCard key={a.uid} assignee={a} />
                  ))}
                </Box>
              ) : (
                <Typography>No Assignees</Typography>
              )}
            </>
          ) : (
            <>
              <Typography>
                Sorry, could not fetch clients, please refetch.
              </Typography>
              <Button
                variant="primary"
                label="refetch"
                size="large"
                onClick={fetchAssignees}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Assignees;
