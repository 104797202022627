import { DbInvitation, Invitation } from "common/types/Invitation";
import {
  Query,
  collection,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { auth, db } from "../features/firebase";
import { isPresent } from "common/dist/feat/util";

export const subscribeToInvitations = (
  onUpdate: (invitations: Invitation[]) => void
) => {
  const q = query(
    collection(db, "invitations"),
    where("therapist", "==", auth.currentUser?.uid)
  ) as Query<DbInvitation>;

  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const invitations = querySnapshot.docs
        .map((doc) => {
          const invitation = doc.data();
          if (
            (invitation.accepted !== null &&
              typeof invitation.accepted !== "boolean") ||
            typeof invitation.therapist !== "string" ||
            typeof invitation.patientEmail !== "string"
          ) {
            console.debug("Invalid invitation", JSON.stringify(invitation));
            return undefined;
          }
          return {
            ...invitation,
            timestamp: invitation.timestamp.toDate(),
            id: doc.id,
          };
        })
        .filter(isPresent)
        .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());

      console.debug("Updating invitations");
      onUpdate(invitations);
    },
    (err) => console.error("Error subscribing to invitations", err)
  );
  return unsubscribe;
};
