import { FC, useState } from "react";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { FeedbackQuestion } from "common/types/Feedback";
import Button from "../Button";
import IconButton from "../IconButton";
import Icon from "../Icon";
import { THEME } from "../../theme";

interface QuestionPickerProps {
  questions: FeedbackQuestion[];
  onChange: (f: FeedbackQuestion[]) => void;
}

export const QuestionPicker: FC<QuestionPickerProps> = ({
  questions,
  onChange,
}) => {
  const handleChange = (newQuestions: FeedbackQuestion[]) => {
    onChange(newQuestions);
  };

  return (
    <Box pt={1} mb={4}>
      {questions
        .sort((a, b) => a.id - b.id)
        .map((q, i) => (
          <QuestionForm
            key={q.id}
            feedbackQuestion={q}
            onChange={(editedQ) => {
              handleChange([
                ...questions.filter((e) => e.id !== q.id),
                editedQ,
              ]);
            }}
            title={`QUESTION ${i + 1}`}
            onDelete={() =>
              handleChange(questions.filter((e) => e.id !== q.id))
            }
          />
        ))}
      <Button
        variant="tertiary"
        color={THEME.colors.grey600}
        label="add question"
        size="medium"
        leftIcon={<Icon variant="circlePlus" />}
        onClick={() => {
          handleChange([
            ...questions,
            {
              question: "",
              binary: false,
              id: new Date().getTime(),
            },
          ]);
        }}
      />
    </Box>
  );
};

const QuestionForm: FC<{
  feedbackQuestion: FeedbackQuestion;
  onChange: (q: FeedbackQuestion) => void;
  onDelete: () => void;
  title: string;
}> = ({ feedbackQuestion, onChange, onDelete, title }) => {
  const [question, setQuestion] = useState(feedbackQuestion.question);
  const [binary, setBinary] = useState(feedbackQuestion.binary);

  return (
    <Box mt={4}>
      <Typography sx={{ mb: 1 }}>
        {title}
        <IconButton
          variant="delete"
          aria-label="Delete question"
          onClick={onDelete}
        />
      </Typography>
      <TextField
        value={question}
        fullWidth
        label="Question"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          setQuestion(event.target.value);
          onChange({ ...feedbackQuestion, question: event.target.value });
        }}
      />
      <RadioGroup
        value={binary ? "binary" : "open"}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const newBinary =
            (event.target as HTMLInputElement).value === "binary";
          setBinary(newBinary);
          onChange({ ...feedbackQuestion, binary: newBinary });
        }}
        sx={{ pt: 1 }}
      >
        <FormControlLabel
          value="open"
          control={<Radio />}
          label="Open text box"
        />
        <FormControlLabel
          value="binary"
          control={<Radio />}
          label={
            <>
              <Icon variant="thumbUp" />
              <Icon variant="thumbDown" />
            </>
          }
        />
      </RadioGroup>
    </Box>
  );
};
