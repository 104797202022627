import { FC, useMemo, useState, useEffect, useCallback } from "react";
import { useLocation } from "wouter";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { HomeworkTemplate } from "common/dist/types/Homework";
import { functionsClient } from "../../../../firebase";
import { useHomeworkTemplates } from "../../../../../hooks/useAppData";
import { useHomeworks } from "../../../../../hooks/useHomeworks";
import { useAuth } from "../../../../../hooks/useAuth";
import InfoBox from "../../../../../components/InfoBox";
import Button from "../../../../../components/Button";
import Icon from "../../../../../components/Icon";
import TextInput from "../../../../../components/TextInput";
import HomeworkTemplatePage from "./HomeworkTemplate";
import TemplateCard from "./TemplateCard";

const createdByFilter = [
  { value: "all", displayValue: "By anyone" },
  { value: "own", displayValue: "By you" },
  { value: "community", displayValue: "By maind community" },
];

export const HomeworkTemplates: FC = () => {
  const [, setLocation] = useLocation();
  const { userAuth } = useAuth();
  const { publicHomeworkTemplates } = useHomeworkTemplates();
  const { homeworkTemplates } = useHomeworks();
  const [processedPublicTemplates, setProcessedPublicTemplates] = useState<
    HomeworkTemplate[] | undefined
  >(undefined);
  const [viewTemplate, setViewTemplate] = useState<
    HomeworkTemplate | undefined
  >(undefined);
  const [filterBy, setFilterBy] = useState("all");
  const [searchBy, setSearchBy] = useState("");

  const therapistId = userAuth.uid;

  const fetchPublishers = useCallback(async () => {
    try {
      const res = await functionsClient.getHomeworkTemplatesPublicProfiles();
      const data = res.data;
      if (data) {
        setProcessedPublicTemplates(
          publicHomeworkTemplates.map((t) => ({
            ...t,
            createdBy:
              data.find((publisher) => t.createdBy === publisher.id)?.name ??
              "(deleted)",
          }))
        );
      }
    } catch (error) {
      console.debug("Error fetching template publishers");
    }
  }, [publicHomeworkTemplates]);

  useEffect(() => {
    fetchPublishers();
  }, [publicHomeworkTemplates, fetchPublishers, therapistId]);

  const templates = homeworkTemplates
    ? homeworkTemplates
        .concat(processedPublicTemplates ?? [])
        .sort(
          (a: HomeworkTemplate, b: HomeworkTemplate) =>
            b.updatedAt.getTime() - a.updatedAt.getTime()
        )
    : publicHomeworkTemplates;

  const filteredTemplates = useMemo(() => {
    const searchByLowercase = searchBy.toLowerCase();
    return templates.filter(
      (t) =>
        (t.description.includes(searchByLowercase) ||
          t.title?.includes(searchByLowercase) ||
          !!t.labels?.find((l) =>
            l.toLowerCase().includes(searchByLowercase)
          )) &&
        (filterBy === "all" ||
          (filterBy === "own" && therapistId === t.createdBy) ||
          (filterBy === "community" && therapistId !== t.createdBy))
    );
  }, [searchBy, filterBy, therapistId, templates]);

  return viewTemplate ? (
    <HomeworkTemplatePage
      template={viewTemplate}
      close={() => setViewTemplate(undefined)}
    />
  ) : (
    <Box pt={1}>
      <Box p={1} display="flex" flexDirection="row" gap={2} alignItems="center">
        <Button
          label="back"
          variant="tertiary"
          size="medium"
          onClick={() => setLocation("/", { replace: true })}
          leftIcon={<Icon variant="arrowLeft" />}
        />
        <TextInput
          onChange={setSearchBy}
          placeholder="Search..."
          label=""
          size="small"
          maxWidth={400}
        />
        <Select
          sx={{
            maxWidth: 200,
            width: "100%",
          }}
          size="small"
          placeholder="Country of residence"
          value={filterBy}
          onChange={(event: SelectChangeEvent) => {
            setFilterBy(event.target.value);
          }}
        >
          {createdByFilter.map((c) => (
            <MenuItem key={c.value} value={c.value}>
              {c.displayValue}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <h2>Homework templates</h2>
      <Box paddingBottom={1}>
        <InfoBox content="Clients will not see these templates, only the homework that you send." />
      </Box>
      <Grid container spacing={1}>
        {filteredTemplates.map((template) => {
          const editable = template.createdBy === userAuth.uid;
          return (
            <Grid item sm={6} key={template.id}>
              <TemplateCard
                template={template}
                onClick={() => setViewTemplate(template)}
                editable={editable}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};
