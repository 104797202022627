import { FC, memo, useState } from "react";
import { Box, Chip, Typography } from "@mui/material";
import { isBefore, subDays } from "date-fns";
import { differenceInDays, startOfDay } from "date-fns";
import {
  getStringArrayCounts,
  categoriesDuringWeek,
} from "common/dist/feat/logEntry";
import { getEmotionCategories } from "common/dist/types/LogEntry";
import { AssigneeV2 } from "../../../utils";
import { useEntries } from "../../../../../hooks/useEntries";
import { useHomeworks } from "../../../../../hooks/useHomeworks";
import { timeRanges } from "../dataUtils";
import { SymptomsTotal } from "./SymptomTotals";
import { DiaryStatistics } from "./DiaryStatistics";
import { styles } from "../styles";
import { ClassifiedLabelTotals } from "./ClassifiedLabelTotals";
import HomeworkFeedbackGraph, {
  GraphValues,
  GraphVariable,
} from "./HomeworkFeedbackGraph";
import LoggedCategories from "./LoggedCategories";
import MostLoggedEmotions from "./MostLoggedEmotions";
import WeekdayCategories from "./WeekdayCategories";
import { useAssigneeFeedbacks } from "../../../../../hooks/useAppData";
import { THEME } from "../../../../../theme";

interface OverviewProps {
  assignee: AssigneeV2;
}

const Overview: FC<OverviewProps> = ({ assignee }) => {
  const [range, setRange] = useState<number>(timeRanges[1]);
  const { feedbacks } = useAssigneeFeedbacks(assignee.uid);
  const { entries } = useEntries();
  const { homeworks } = useHomeworks();

  if (!entries) {
    return (
      <Box>
        <Typography>No data</Typography>
      </Box>
    );
  }

  const entriesV2 =
    range === 0
      ? entries
      : entries.filter(
          (e) => differenceInDays(new Date(), startOfDay(e.timestamp)) <= range
        );

  const previousEntriesV2 =
    range === 0
      ? undefined
      : entries.filter((e) => {
          const diff = differenceInDays(new Date(), startOfDay(e.timestamp));
          return diff > range && diff <= range * 2;
        });

  const fromDate =
    range === 0
      ? [
          ...(feedbacks?.map((f) => f.feedback.timestamp) ?? []),
          ...(homeworks?.map((h) => h.timestamp) ?? []),
        ].sort((a, b) => (isBefore(a, b) ? -1 : 1))[0]
      : subDays(new Date(), range);

  const filteredHomeworks = homeworks
    ? homeworks.filter(
        (h) =>
          h.timestamp >= fromDate ||
          (h.answers.length > 0 &&
            h.answers.filter((ha) => ha.timestamp >= fromDate))
      )
    : [];
  const filteredFeedbacks = feedbacks
    ? feedbacks.filter(
        (f) =>
          f.feedback.timestamp >= fromDate ||
          (f.feedback.lastUpdatedByPatient &&
            f.feedback.lastUpdatedByPatient >= fromDate)
      )
    : [];

  const categoryCountsWithColors = getStringArrayCounts(
    entriesV2.flatMap((entry) =>
      entry.emotions.map((emotion) => emotion.category)
    )
  ).map(({ element, count }) => ({
    category: element,
    count,
    color:
      getEmotionCategories("light").find((c) => c.category === element)
        ?.color ?? THEME.colors.light.neutralCategory,
  }));

  const emotionCountsWithColors = getStringArrayCounts(
    entriesV2.flatMap((entry) =>
      entry.emotions.map((e) => `${e.category}-${e.displayName}`)
    )
  ).map(({ element, count }) => ({
    emotion: element.split("-")[1],
    count,
    color:
      getEmotionCategories("light").find(
        (c) => c.category === element.split("-")[0]
      )?.color ?? THEME.colors.light.neutralCategory,
  }));

  const entriesByWeekday = categoriesDuringWeek(entriesV2);

  const homeworkFeedbackGraphData = (): GraphValues => {
    const homeworkAndAnswers = filteredHomeworks
      .flatMap((h) => [
        {
          x: startOfDay(h.timestamp),
          variant: GraphVariable.Homework,
        },
        ...h.answers.map((a) => ({
          x: startOfDay(a.timestamp),
          variant: GraphVariable.HomeworkAnswer,
        })),
      ])
      .filter((h) => h.x >= fromDate);

    const feedbackAndAnswers = filteredFeedbacks
      .flatMap((f) => [
        {
          x: startOfDay(f.feedback.timestamp),
          variant: GraphVariable.Feedback,
        },
        {
          x: startOfDay(
            f.feedback.lastUpdatedByPatient
              ? f.feedback.lastUpdatedByPatient
              : new Date(
                  fromDate.getFullYear(),
                  fromDate.getMonth(),
                  fromDate.getDate() - 1
                )
          ),
          variant: GraphVariable.FeedbackAnswer,
        },
      ])
      .filter((f) => f.x >= fromDate);

    return [...homeworkAndAnswers, ...feedbackAndAnswers];
  };

  return (
    <>
      <Box sx={styles.chipGroup}>
        {timeRanges.map((r) => (
          <Chip
            key={`${r}-range`}
            label={r === 0 ? "All time" : `Last ${r} days`}
            onClick={() => setRange(r)}
            color={range === r ? "primary" : "default"}
            variant={range === r ? "filled" : "outlined"}
            sx={{
              ...styles.chipThin,
              color: range === r ? THEME.colors.grey000 : THEME.colors.grey600,
            }}
          />
        ))}
      </Box>
      <DiaryStatistics
        entries={entriesV2}
        range={range}
        previousEntries={previousEntriesV2}
      />
      <Box display="flex" flexDirection="row" gap={8} marginTop={4}>
        <Box
          component="section"
          display="flex"
          flex={1}
          flexDirection="column"
          rowGap={4}
        >
          <LoggedCategories categoriesData={categoryCountsWithColors} />
          <MostLoggedEmotions emotionsWithCount={emotionCountsWithColors} />
          <WeekdayCategories weekdayCategoriesData={entriesByWeekday} />
        </Box>
        <Box component="section">
          <SymptomsTotal
            symptoms={getStringArrayCounts(
              entriesV2.flatMap((e) => e.symptoms.map((s) => s.label))
            ).map((c) => ({ symptom: { label: c.element }, count: c.count }))}
          />

          <ClassifiedLabelTotals entries={entriesV2} />
        </Box>
      </Box>
      <HomeworkFeedbackGraph
        fromDate={fromDate}
        values={homeworkFeedbackGraphData()}
      />
    </>
  );
};

export default memo(Overview);
