import { FC, useState } from "react";
import { Grid, Typography, Box } from "@mui/material";
import { WEEKDAYS } from "common/dist/feat/date";
import {
  getEmotionCategories,
  EmotionCategory,
} from "common/dist/types/LogEntry";
import {
  CategoriesDuringWeek,
  categoriesDuringWeekValues,
} from "common/dist/feat/logEntry";
import HorizontalBar from "../../../../../../components/HorizontalBar";
import Chip from "../../../../../../components/Chip";
import { styles } from "../../styles";

interface WeekdayCategoriesProps {
  weekdayCategoriesData: CategoriesDuringWeek[];
}

const WeekdayCategories: FC<WeekdayCategoriesProps> = ({
  weekdayCategoriesData,
}) => {
  const [selectedCategories, setSelectedCategories] = useState<
    EmotionCategory[]
  >(getEmotionCategories("light"));

  return (
    <Grid item xs={12}>
      <Typography sx={styles.statisticHeader} mb={1}>
        categories during the week
      </Typography>
      <Box display="flex" flex={1} flexDirection="column" gap={1} mb={1}>
        {WEEKDAYS.sort((a, b) => a.order - b.order).map((wd) => (
          <HorizontalBar
            key={`weekday-bar-${wd.displayName}`}
            values={categoriesDuringWeekValues(
              weekdayCategoriesData,
              wd.index,
              selectedCategories
            )}
            total={
              weekdayCategoriesData.find((e) => e.wdIdx === wd.index)?.total ??
              0
            }
            label={wd.displayName}
          />
        ))}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-evenly"
        rowGap={1}
      >
        <Chip
          variant="secondary"
          label="show all"
          onClick={() => setSelectedCategories(getEmotionCategories("light"))}
          size="medium"
        />
        {getEmotionCategories("light").map((c) => {
          const selected = selectedCategories.find(
            (sc) => sc.category === c.category
          );
          return (
            <Chip
              key={`category-selector-${c.category}`}
              label={c.category}
              onClick={() =>
                setSelectedCategories(
                  selected
                    ? selectedCategories.filter(
                        (i) => i.category !== c.category
                      )
                    : [...selectedCategories, c]
                )
              }
              variant={selected ? "custom" : "secondary"}
              backgroundColor={selected ? c.color : undefined}
              size="medium"
            />
          );
        })}
      </Box>
    </Grid>
  );
};

export default WeekdayCategories;
