import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from "firebase/functions";
import { getStorage, connectStorageEmulator } from "firebase/storage";
import { BugReport } from "common/dist/types/BugReport";
import { PublicProfessionalProfileV2 } from "common/types/Therapist";

import { ENV_VARIABLES } from "./envVariables";

// Prod config
const firebaseConfigPROD = {
  apiKey: "AIzaSyCc_Bs79saFVpsZBdx9frNKBcdu8qejTYs",
  authDomain: "app.maind.app",
  projectId: "maind-1f90a",
  storageBucket: "maind-1f90a.appspot.com",
  messagingSenderId: "523768168989",
  appId: "1:523768168989:web:81ebaf3983227a56dc7cf4",
};

const firebaseConfigDEV = {
  apiKey: "AIzaSyAW14mlwkNg0f7DDY3PjWaerH0tvEUFhTM",
  authDomain: "maind-dev-b78cb.web.app",
  projectId: "maind-dev-b78cb",
  storageBucket: "maind-dev-b78cb.appspot.com",
  messagingSenderId: "301400572062",
  appId: "1:301400572062:web:9deba585fd674d572bbb8f",
};

const firebaseConfigLocalDEV = {
  projectId: "demo-maind-dev",
  apiKey: "asd123",
  authDomain: "127.0.0.1",
  storageBucket: "localhost",
  messagingSenderId: "1234567890",
  appId: "1234567890asd",
};

const firebaseConfigTest = {
  projectId: "demo-maind-test",
  apiKey: "asd123",
  authDomain: "localhost",
  storageBucket: "localhost",
  messagingSenderId: "1234567890",
  appId: "1234567890asd",
};

const getFirebaseConfig = () => {
  switch (ENV_VARIABLES.appVariant) {
    case "production":
      return firebaseConfigPROD;
    case "development":
      return firebaseConfigDEV;
    case "localDev":
      return firebaseConfigLocalDEV;
    case "test":
      return firebaseConfigTest;
    default:
      throw new Error("missing appVariant env variable");
  }
};

export const firebaseConfig = getFirebaseConfig();

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export const db = getFirestore(app);

export const functions = getFunctions(app, "europe-west1");

export const storage = getStorage(app);

if (ENV_VARIABLES.useEmulator === "true") {
  console.debug("## Using Firebase emulator ##");
  connectFunctionsEmulator(functions, "localhost", 5001);
  connectAuthEmulator(auth, "http://127.0.0.1:9099");
  connectFirestoreEmulator(db, "127.0.0.1", 8080);
  connectStorageEmulator(storage, "localhost", 9199);
}

const sendApprovedDraft = httpsCallable<{ draftId: string }, undefined>(
  functions,
  "sendApprovedDraft"
);

const sendFeedbackRequest = httpsCallable<{ draftId: string }, undefined>(
  functions,
  "sendFeedbackRequest"
);

const sendHomework = httpsCallable<{ draftId: string }, undefined>(
  functions,
  "sendHomework"
);

const sendFeedbackRequestFromTemplate = httpsCallable<
  { templateId: string; assigneeId: string },
  undefined
>(functions, "sendFeedbackRequestFromTemplate");

const disconnectFromPatient = httpsCallable<{ patientId: string }, undefined>(
  functions,
  "disconnectFromPatient"
);

const sendInvitation = httpsCallable<
  {
    patientEmail: string;
    sendCopy?: boolean;
  },
  { status: boolean; message: string }
>(functions, "sendInvitation");

const sendBugReport = httpsCallable<{ bugReport: BugReport }, undefined>(
  functions,
  "sendBugReport"
);

const sendHomeworkFromTemplate = httpsCallable<
  { templateId: string; patientId: string },
  undefined
>(functions, "sendHomeworkFromTemplate");

const setCustomClaims = httpsCallable<{ professional: boolean }, undefined>(
  functions,
  "setCustomClaims"
);

const getHomeworkTemplatesPublicProfiles = httpsCallable<
  undefined,
  PublicProfessionalProfileV2[] | undefined
>(functions, "getHomeworkTemplatesPublicProfiles");

export const functionsClient = {
  sendApprovedDraft,
  sendFeedbackRequest,
  sendHomework,
  sendFeedbackRequestFromTemplate,
  disconnectFromPatient,
  sendInvitation,
  sendBugReport,
  sendHomeworkFromTemplate,
  setCustomClaims,
  getHomeworkTemplatesPublicProfiles,
};
