import { FC, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SxProps } from "@mui/material";
import { useLocation } from "wouter";
import { PrivateProfessionalProfileV2 } from "common/dist/types/Therapist";

import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import FormCard, {
  actionContainerStyles,
  contentContainerStyles,
} from "../../components/FormCard";
import { createProfile } from "../../services/user";
import { getCurrentUser } from "../../services/auth";
import { INTERNAL_ERROR } from "../../services/constants";
import { useAuth } from "../../hooks/useAuth";
import { validateProfile } from "../../utils";

const formColumn: SxProps = {
  flex: 1,
  minWidth: "250px",
};

const Onboarding: FC = () => {
  const { userAuth } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [newProfile, setNewProfile] = useState<
    Partial<PrivateProfessionalProfileV2>
  >({
    email: getCurrentUser().email ?? "",
    name: "",
    title: "",
  });
  const [, setLocation] = useLocation();

  const setValue =
    (prop: keyof PrivateProfessionalProfileV2) =>
    (value: PrivateProfessionalProfileV2[typeof prop]) =>
      setNewProfile((profile) => ({ ...profile, [prop]: value }));

  const sendProfile = async () => {
    setLoading(true);
    const status = await createProfile(newProfile, userAuth);

    if (status) {
      setLocation("/");
    } else {
      alert(INTERNAL_ERROR);
    }
    setLoading(false);
  };

  return (
    <>
      <Box borderBottom={1} borderColor="rgba(0, 0, 0, 0.12)">
        <h1>{"New profile"}</h1>
        <Typography variant="body1">
          Fill your information to complete your profile.
        </Typography>
      </Box>
      <Box
        onSubmit={sendProfile}
        display="flex"
        flexDirection="column"
        marginTop="16px"
        height="auto"
        gap="32px"
      >
        <FormCard component="form" minWidth="250px">
          <Box sx={contentContainerStyles}>
            <h2>Personal info</h2>
            <Box display="flex" flexDirection="column" rowGap="16px">
              <Box sx={formColumn}>
                <TextInput
                  label="First name last name"
                  type="text"
                  value={newProfile.name}
                  onChange={setValue("name")}
                  disabled={loading}
                  required
                  maxWidth="450px"
                />
                <TextInput
                  label="Title"
                  type="text"
                  value={newProfile.title}
                  onChange={setValue("title")}
                  disabled={loading}
                  required
                  maxWidth="450px"
                />
              </Box>
            </Box>
          </Box>
          <Box sx={actionContainerStyles}>
            <Button
              variant="primary"
              onClick={sendProfile}
              loading={loading}
              disabled={!validateProfile(newProfile)}
              label="save"
              size="medium"
              type="submit"
            />
          </Box>
        </FormCard>
      </Box>
    </>
  );
};

export default Onboarding;
