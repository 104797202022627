import { FC } from "react";
import {
  CalendarHeatmap,
  ChartTooltip,
  HeatmapCell,
  HeatmapSeries,
  TooltipTemplate,
} from "reaviz";
import { HeatmapDataPoint, HeatmapUnit } from "./HeatmapTypes";
import { getTooltipValue } from "./heatmapDataUtils";
import { THEME } from "../../../../../theme";

export const Heatmap: FC<{
  data: HeatmapDataPoint[];
  unit: HeatmapUnit;
  colorScheme?: string[];
  width: number;
}> = ({ data, unit, colorScheme, width }) => (
  <CalendarHeatmap
    height={250}
    width={width}
    data={data}
    style={{ style: { marginLeft: 2 } }}
    series={
      <HeatmapSeries
        padding={0.3}
        emptyColor={THEME.colors.grey000}
        colorScheme={colorScheme}
        cell={
          <HeatmapCell
            tooltip={
              <ChartTooltip
                content={(d: {
                  data: { metadata: { date: Date }; value?: number };
                }) =>
                  d.data.value === undefined ? undefined : (
                    <TooltipTemplate
                      className={"heatmap_tooltip"}
                      value={{
                        x: d.data.metadata.date.toLocaleDateString(),
                        y: getTooltipValue(unit, d.data.value),
                      }}
                    />
                  )
                }
              />
            }
          />
        }
      />
    }
  />
);
