import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { Route, Switch } from "wouter";
import { AssigneeProvider } from "../stores/AssigneeContext";
import { EntriesProvider } from "../stores/EntriesContext";
import { HomeworksProvider } from "../stores/HomeworksContext";
import { ProfileProvider } from "../stores/ProfileContext";
import Profile from "./Profile";
import Assignees from "./Assignees";
import { Assignee } from "./Assignees/Assignee";
import { ConnectTab } from "./Connect";
import { BugReportTab } from "./BugReport";
import SideBar from "./SideBar";

import { AnalyticsTab } from "./Assignees/Assignee/AnalyticsTab";
import Entries from "./Assignees/Assignee/Entries";
import { Conversations } from "./Assignees/Assignee/Conversations";
import { HomeworkTab } from "./Assignees/Assignee/HomeworkTab";
import { HomeworkTemplates } from "./Assignees/Assignee/HomeworkTab/HomeworkTemplates";
import { HomeworkContainer } from "./Assignees/Assignee/HomeworkTab/Homework";
import { Timeline } from "./Assignees/Assignee/Timeline";
import { Feedbacks } from "./Assignees/Assignee/FeedbackTab";
import { FeedbackTemplates } from "./Assignees/Assignee/FeedbackTab/FeedbackTemplates";
import { TemplateDetails } from "./Assignees/Assignee/FeedbackTab/TemplateDetails";
import { NewFeedback } from "./Assignees/Assignee/FeedbackTab/NewFeedback";

export const Home: FC = () => {
  return (
    <AssigneeProvider>
      <EntriesProvider>
        <HomeworksProvider>
          <SideBar />
          <Box
            component="main"
            flex={1}
            paddingY={2}
            paddingX={3}
            maxWidth={1440}
          >
            <Route path="/" component={PlaceHolder} />
            <ProfileProvider>
              <Route path="/profile" component={Profile} />
            </ProfileProvider>
            <Route path="/review" component={Assignees} />
            <Route path="/users/:id" nest>
              <Assignee>
                <Route path="/" component={AnalyticsTab} />
                <Route path="/entries" component={Entries} />
                <Route path="/conversation" component={Conversations} />
                <Route path="/homeworks" nest>
                  <Switch>
                    <Route path="/" component={HomeworkTab} />
                    <Route
                      path="/homework/:homeworkId"
                      component={HomeworkContainer}
                    />
                    <Route path="/templates" component={HomeworkTemplates} />
                  </Switch>
                </Route>
                <Route path="/feedbacks" nest>
                  <Switch>
                    <Route path="/" component={Feedbacks} />
                    <Route path="/new/:draftId" component={NewFeedback} />
                    <Route path="/templates" component={FeedbackTemplates} />
                    <Route
                      path="/templates/:templateId"
                      component={TemplateDetails}
                    />
                  </Switch>
                </Route>
                <Route path="/timeline" component={Timeline} />
              </Assignee>
            </Route>
            <Route path="/connect" component={ConnectTab} />
            <Route path="/bugreport" component={BugReportTab} />
          </Box>
        </HomeworksProvider>
      </EntriesProvider>
    </AssigneeProvider>
  );
};

const PlaceHolder: FC = () => (
  <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <Typography>Welcome back</Typography>
  </Box>
);
