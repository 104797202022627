import { FC } from "react";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useLocation } from "wouter";
import Icon from "../components/Icon";

const drawerWidth = 240;

const NavigationItems = [
  {
    label: "My profile",
    icon: <Icon variant="userCircle" />,
    path: "profile",
  },
  {
    label: "Clients",
    icon: <Icon variant="history" />,
    path: "review",
  },
  {
    label: "Connect",
    icon: <Icon variant="userAdd" />,
    path: "connect",
  },
  {
    label: "Send feedback",
    icon: <Icon variant="feedback" />,
    path: "bugreport",
  },
];

const SideBar: FC = () => {
  const [, setLocation] = useLocation();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        [`& .MuiDrawer-paper`]: {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      anchor="left"
    >
      <Box component="nav" sx={{ paddingTop: 8 }}>
        <List>
          {NavigationItems.map(({ label, icon, path }) => (
            <ListItem key={label} disablePadding>
              <ListItemButton onClick={() => setLocation(`/${path}`)}>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={label} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );
};

export default SideBar;
