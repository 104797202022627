import {
  createContext,
  FC,
  useState,
  PropsWithChildren,
  useCallback,
} from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../features/firebase";
import { AssigneeV2 } from "../features/Assignees/utils";

interface AssigneeContextType {
  assignee: AssigneeV2 | undefined;
  setAssignee: (assignee: AssigneeV2 | undefined) => void;
}

export const AssigneeContext = createContext<AssigneeContextType | undefined>(
  undefined
);

export const AssigneeProvider: FC<PropsWithChildren> = ({ children }) => {
  const [assignee, setAssginee] = useState<AssigneeV2 | undefined>(undefined);

  onAuthStateChanged(auth, async (user) => {
    if (user == null) {
      setAssginee(undefined);
    }
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setAssigneeCallback = useCallback(setAssginee, []);

  const context: AssigneeContextType = {
    assignee: assignee,
    setAssignee: setAssigneeCallback,
  };

  return (
    <AssigneeContext.Provider value={context}>
      {children}
    </AssigneeContext.Provider>
  );
};
