import { doc, getDoc } from "firebase/firestore";
import { db } from "../../features/firebase";
import { AssigneeV2 } from "../../features/Assignees/utils";

export const getAssignees = async (
  therapistId: string
): Promise<AssigneeV2[] | undefined> => {
  try {
    const therapistDataRef = doc(db, "therapists", therapistId);
    const theralistDataSnap = await getDoc(therapistDataRef);

    if (!theralistDataSnap.exists()) {
      return undefined;
    }

    const assigneeIds: string[] = theralistDataSnap.data().assignees;
    const assigneesData: AssigneeV2[] = [];

    for (const assigneeId of assigneeIds) {
      try {
        const assigneeRef = doc(db, "users", assigneeId);
        const assigneeSnap = await getDoc(assigneeRef);
        if (assigneeSnap.exists()) {
          const data = assigneeSnap.data();
          assigneesData.push({
            uid: assigneeId,
            name: data.name ?? "",
            picture: data.picture ?? undefined,
          });
        }
      } catch (assgineeErr) {}
    }
    return assigneesData;
  } catch (err) {
    return undefined;
  }
};

export const getAssignee = async (
  assigneeId: string
): Promise<AssigneeV2 | undefined> => {
  try {
    const assigneeRef = doc(db, "users", assigneeId);
    const assigneeSnap = await getDoc(assigneeRef);
    if (assigneeSnap.exists()) {
      const data = assigneeSnap.data();
      return {
        name: data.name ?? "",
        picture: data.picture ?? undefined,
        uid: assigneeSnap.id,
      };
    }
  } catch (err) {
    return undefined;
  }
  return undefined;
};
