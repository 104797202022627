import { FC, PropsWithChildren } from "react";
import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import Icon from "./Icon";

interface ItemCardProps {
  color?: string;
}

export const ItemCard: FC<PropsWithChildren<ItemCardProps>> = ({
  color,
  children,
}) => {
  const theme = useTheme();
  return (
    <Box
      borderRadius={2}
      p={1}
      pb={2}
      sx={{
        backgroundColor: color ?? theme.palette.primary.light,
        color: theme.palette.primary.contrastText,
      }}
      maxWidth={600}
    >
      {children}
    </Box>
  );
};

export const DraftCard: FC<PropsWithChildren<{ text: string }>> = ({
  text,
  children,
}) => {
  const theme = useTheme();
  return (
    <ItemCard color={theme.palette.success.light}>
      <Box display="flex" flexDirection="row">
        <Typography
          variant="caption"
          pb={1}
          color={theme.palette.error.contrastText}
        >
          [Draft]
        </Typography>
        <Box flexGrow={1} />
        <Tooltip title="Edit">
          <Icon variant="edit" size="small" />
        </Tooltip>
      </Box>
      <Typography whiteSpace="pre-line">{text}</Typography>
      {children}
    </ItemCard>
  );
};
