import { FC } from "react";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { timestampToDateTimeString } from "common/dist/feat/date";
import {
  FeedbackCore,
  FeedbackQuestion,
  FeedbackAnswer,
} from "common/types/Feedback";
import { ItemCard, DraftCard } from "../ItemCard";
import Icon from "../Icon";

const BinaryQuestion: FC<{
  question: string;
  answer?: boolean | string | null;
}> = ({ question, answer }) => (
  <Box style={{ display: "flex", alignItems: "center" }} pt={1}>
    <Typography pr={1}>{question}</Typography>
    {answer === undefined || answer === null ? (
      <>
        <Icon variant="thumbUp" />
        <Icon variant="thumbDown" />
      </>
    ) : answer ? (
      <Icon variant="thumbUp" />
    ) : (
      <Icon variant="thumbDown" />
    )}
  </Box>
);

const OpenQuestion: FC<{
  question: string;
  answer?: boolean | string | null;
}> = ({ question, answer }) => (
  <Box pt={1}>
    <Typography style={{ display: "flex", alignItems: "center" }}>
      {question}
    </Typography>
    {answer && (
      <Typography style={{ display: "flex", alignItems: "center" }}>
        Answer: {answer}
      </Typography>
    )}
  </Box>
);

export const FeedbackQuestionBox: FC<{
  id: string;
  questionsWithAnswers: {
    question: FeedbackQuestion;
    answer?: string | boolean | null;
  }[];
}> = ({ id, questionsWithAnswers }) => {
  return (
    <Box pt={2}>
      <Typography variant="caption">Questions</Typography>
      {questionsWithAnswers
        .sort((a, b) => a.question.id - b.question.id)
        .map((q) =>
          q.question.binary ? (
            <BinaryQuestion
              key={`${id}.${q.question.id}`}
              question={q.question.question}
              answer={q.answer}
            />
          ) : (
            <OpenQuestion
              key={`${id}.${q.question.id}`}
              question={q.question.question}
              answer={q.answer}
            />
          )
        )}
    </Box>
  );
};

interface FeedbackDraftCardProps {
  id: string;
  draft: FeedbackCore;
}

const FeedbackDraftCard: FC<FeedbackDraftCardProps> = ({ id, draft }) => {
  return (
    <DraftCard text={draft.description}>
      {draft.questions.length > 0 && (
        <FeedbackQuestionBox
          id={id}
          questionsWithAnswers={draft.questions.map((q) => ({ question: q }))}
        />
      )}
    </DraftCard>
  );
};

interface SendCardProps {
  feedback: FeedbackCore;
  unseen?: boolean;
  answers: FeedbackAnswer[];
  timestamp: Date;
}

const SendCard: FC<SendCardProps> = ({
  feedback,
  unseen,
  answers,
  timestamp,
}) => {
  const questionsWithAnswers = feedback.questions.map((q) => ({
    question: q,
    answer: answers.find((a) => a.questionId === q.id)?.answer,
  }));

  const answered = questionsWithAnswers.find((q) => q.answer);

  return (
    <ItemCard>
      <Box display="flex" flexDirection="row">
        <Typography variant="caption" pb={1}>
          {timestampToDateTimeString(timestamp)}
          <Badge
            badgeContent=" "
            color="error"
            variant="dot"
            overlap="circular"
            invisible={!unseen}
            sx={{ ml: 1, mb: 1 }}
          ></Badge>
        </Typography>
        <Box flexGrow={1} />
        {answered ? (
          <Tooltip title="Answered by user">
            <span>
              <Icon variant="checkboxDone" size="small" />
            </span>
          </Tooltip>
        ) : (
          <Tooltip title="Not yet answered">
            <span>
              <Icon variant="checkboxEmpty" />
            </span>
          </Tooltip>
        )}
      </Box>
      <Typography whiteSpace="pre-line">{feedback.description}</Typography>
      {questionsWithAnswers.length > 0 && (
        <FeedbackQuestionBox
          id={timestampToDateTimeString(timestamp)}
          questionsWithAnswers={questionsWithAnswers}
        />
      )}
    </ItemCard>
  );
};

interface TemplateCardProps {
  template: FeedbackCore;
  id: string;
}

const TemplateCard: FC<TemplateCardProps> = ({ id, template }) => {
  const theme = useTheme();
  return (
    <ItemCard>
      <Box display="flex" flexDirection="row">
        <Typography
          variant="caption"
          pb={1}
          color={theme.palette.error.contrastText}
        >
          [Template]
        </Typography>
        <Box flexGrow={1} />
        <Tooltip title="Edit">
          <Icon variant="edit" size="small" />
        </Tooltip>
      </Box>
      <Typography whiteSpace="pre-line">{template.description}</Typography>

      {template.questions.length > 0 && (
        <FeedbackQuestionBox
          id={id}
          questionsWithAnswers={template.questions.map((q) => ({
            question: q,
          }))}
        />
      )}
    </ItemCard>
  );
};

interface FeedbackCardProps {
  id: string;
  feedback: FeedbackCore;
  variant: "draft" | "send" | "template";
  answers?: FeedbackAnswer[];
  timestamp?: Date;
  onClick?: () => void;
}

const FeedbackCard: FC<FeedbackCardProps> = ({
  id,
  feedback,
  variant,
  answers,
  timestamp,
}) => {
  switch (variant) {
    case "template":
      return <TemplateCard id={id} template={feedback} />;
    case "draft":
      return <FeedbackDraftCard id={id} draft={feedback} />;
    case "send":
      return (
        <SendCard
          feedback={feedback}
          answers={answers ?? []}
          timestamp={timestamp ?? new Date()}
        />
      );
    default:
      return null;
  }
};

export default FeedbackCard;
