import { FC, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { Conversation } from "common/types/Conversations";
import { timestampToDateTimeString } from "common/dist/feat/date";
import { ItemCard } from "../../../../components/ItemCard";
import { EditView } from "../../../../components/EditView";
import { Editor } from "../../../../components/Editor";
import {
  sendDraft,
  deleteDraft,
  updateDraft,
} from "../../../../services/messages";

export const NewMessage: FC<{
  draftId: string;
  assigneeId: string;
  close: () => void;
  messageText?: string;
}> = (props) => {
  const [messageText, setMessageText] = useState(props.messageText);

  const [saving, setSaving] = useState(false);

  const [changes, setChanges] = useState(false);

  useEffect(() => {
    if (props.messageText !== messageText || props.messageText === "") {
      setChanges(true);
    } else {
      setChanges(false);
    }
  }, [props.messageText, messageText]);

  const saveDraft = () => {
    setSaving(true);
    updateDraft(props.draftId, props.assigneeId, messageText ?? "").finally(
      () => setSaving(false)
    );
  };

  return (
    <EditView
      close={props.close}
      send={() => sendDraft(props.draftId)}
      delete={() => deleteDraft(props.draftId)}
      save={saveDraft}
      saving={saving}
      changes={changes}
      sendDisabled={saving || changes}
      sendButtonTooltip={changes ? "Please save draft before sending" : ""}
      type="message"
    >
      <Editor initialText={props.messageText} onChange={setMessageText} />
    </EditView>
  );
};

export const ConversationCard: FC<{ conversation: Conversation }> = ({
  conversation,
}) => {
  const head = conversation.messages[0];
  const { text, timestamp } = head;
  return (
    <ItemCard>
      <Typography variant="caption" pb={1}>
        {timestampToDateTimeString(timestamp)}
      </Typography>
      <Typography whiteSpace="pre-line">{text}</Typography>
    </ItemCard>
  );
};
