import { FC } from "react";
import "./styles.css";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { AutoFocusPlugin } from "@lexical/react/LexicalAutoFocusPlugin";
import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import {
  TRANSFORMERS,
  $convertFromMarkdownString,
  $convertToMarkdownString,
  TextMatchTransformer,
} from "@lexical/markdown";
import {
  $createLineBreakNode,
  $isLineBreakNode,
  LexicalNode,
  LineBreakNode,
} from "lexical";

const editorConfig = {
  namespace: "MyEditor",
  // Handling of errors during update
  onError(error: any) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [
    // HeadingNode,
    // ListNode,
    // ListItemNode,
    // QuoteNode,
    // CodeNode,
    // CodeHighlightNode,
    // TableNode,
    // TableCellNode,
    // TableRowNode,
    // AutoLinkNode,
    LinkNode,
  ],
};

export const LINE_BREAK_FIX: TextMatchTransformer = {
  dependencies: [LineBreakNode],
  export: (node: LexicalNode) => ($isLineBreakNode(node) ? "<br />" : null),
  importRegExp: /<br\s?\/?>/,
  regExp: /<br\s?\/?>/,
  replace: (textNode: LexicalNode) => {
    const br = $createLineBreakNode();
    textNode.replace(br);
  },
  trigger: "",
  type: "text-match",
};

export const Editor: FC<{
  initialText: string | undefined;
  onChange: (s: string) => void;
  placeholder?: string;
}> = (props) => {
  return (
    <LexicalComposer
      initialConfig={{
        ...editorConfig,
        editorState: () => {
          const fromMarkdownString =
            props.initialText?.replaceAll("\n", "<br />") ?? "";
          return $convertFromMarkdownString(fromMarkdownString, [
            LINE_BREAK_FIX,
            ...TRANSFORMERS,
          ]);
        },
      }}
    >
      <div className="editor-container" style={{ zIndex: 100 }}>
        <ToolbarPlugin />
        <div className="editor-inner">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            placeholder={
              <div
                style={{
                  padding: "15px 10px",
                  fontSize: 15,
                  color: "#999",
                  overflow: "hidden",
                  position: "absolute",
                  textOverflow: "ellipsis",
                  top: 0,
                  left: 0,
                  right: 0,
                  userSelect: "none",
                  whiteSpace: "nowrap",
                  display: "inline-block",
                  pointerEvents: "none",
                }}
              >
                {props.placeholder}
              </div>
            }
            ErrorBoundary={LexicalErrorBoundary}
          />
          <AutoFocusPlugin />
          <LinkPlugin />
          <OnChangePlugin
            onChange={(editorState) =>
              editorState.read(() => {
                const markdownString = $convertToMarkdownString([
                  LINE_BREAK_FIX,
                  ...TRANSFORMERS,
                ]);
                const converted = markdownString.replaceAll("<br />", "\n");
                props.onChange(converted);
              })
            }
          />
        </div>
      </div>
    </LexicalComposer>
  );
};
