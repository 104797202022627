import { Card, CardContent, Tooltip, Typography, Box } from "@mui/material";
import { FC } from "react";
import multiavatar from "@multiavatar/multiavatar";
import { Link } from "wouter";
import { haikunateId } from "../words";
import { AssigneeV2 } from "../utils";

interface AssigneeCardProps {
  assignee: AssigneeV2;
}

const AssigneeCard: FC<AssigneeCardProps> = ({ assignee }) => {
  const name = assignee.name ?? haikunateId(assignee.uid);
  const avatarCode = multiavatar(assignee.uid);

  return (
    <Link href={`/users/${assignee.uid}`}>
      <Tooltip title={assignee.name}>
        <Card sx={{ width: 200, height: 200, cursor: "pointer" }}>
          <CardContent
            sx={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              margin: "auto",
              flexGrow: 1,
              flexDirection: "column",
              opacity: 1,
              height: "100%",
            }}
          >
            {assignee.picture ? (
              <img
                alt="Assignee"
                src={assignee.picture}
                style={{
                  height: 80,
                  width: 80,
                  borderRadius: 50,
                  objectFit: "cover",
                }}
              />
            ) : (
              <div
                style={{ width: 80 }}
                dangerouslySetInnerHTML={{ __html: avatarCode }}
              />
            )}
            <Box
              sx={{
                width: "100%",
                overflowWrap: "break-word",
                overflow: "hidden",
              }}
            >
              <Typography
                sx={{
                  maxWidth: "100%",
                  textAlign: "center",
                  textOverflow: "ellipsis",
                }}
              >
                {name}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Tooltip>
    </Link>
  );
};

export default AssigneeCard;
