import { Stack } from "@mui/material";
import { FC } from "react";
import { correlationColors } from "./correlationUtils";
import { THEME } from "../../../../../theme";
import StatsLegend from "../../../../../components/StatsLegend";

export const CorrelationLegend: FC = () => {
  const ranges = Array(10)
    .fill(undefined)
    .map((v, i) => {
      const min = Math.round((-1.0 + i * 0.2) * 10) / 10;
      return {
        color: correlationColors[i],
        min,
        max:
          min === 0
            ? undefined
            : min === -0.2
            ? 0.2
            : Math.round((min + 0.2) * 10) / 10,
      };
    })
    .filter((r) => r.max);

  return (
    <Stack>
      {ranges.reverse().map((range) => (
        <StatsLegend
          key={`correlation-legend-${range.min}`}
          label={`${range.min} ${String.fromCharCode(8212)} ${range.max}`}
          color={range.color}
        />
      ))}
      <StatsLegend
        key={`correlation-legend-NaN`}
        label={
          "Correlation can’t be calculated because at least one of the variables has not changed"
        }
        color={THEME.colors.grey000}
      />
    </Stack>
  );
};
