import React, { FC } from "react";
import Box from "@mui/material/Box";
import multiavatar from "@multiavatar/multiavatar";
import { haikunateId } from "../words";

interface ProfileSummaryProps {
  assigneeName?: string;
  assigneeId: string;
  picture?: string;
}

const ProfileSummary: FC<ProfileSummaryProps> = ({
  assigneeId,
  assigneeName,
  picture,
}) => {
  const name = assigneeName ?? haikunateId(assigneeId);
  const avatarCode = multiavatar(assigneeId);

  const imgSize = 60;

  return (
    <Box display="flex" flexDirection="row" gap={2} alignItems="center">
      {picture ? (
        <img
          alt="Assginee"
          src={picture}
          style={{
            height: imgSize,
            width: imgSize,
            borderRadius: 50,
            objectFit: "cover",
          }}
        />
      ) : (
        <div
          style={{ width: imgSize }}
          dangerouslySetInnerHTML={{ __html: avatarCode }}
        />
      )}
      <h1>{name}</h1>
    </Box>
  );
};

export default ProfileSummary;
