import { FC, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Invitation } from "common/dist/types/Invitation";
import { subscribeToInvitations } from "../../services/invitations";
import { THEME } from "../../theme";
import InfoBox from "../../components/InfoBox";

const getDaysToExpiration = (sentDate: Date) => {
  const expirationDate = new Date(sentDate);
  expirationDate.setDate(expirationDate.getDate() + 7);

  const diffToNow = expirationDate.getTime() - Date.now();
  return Math.max(Math.ceil(diffToNow / (1000 * 3600 * 24)), 0);
};

export const InvitationList: FC = () => {
  const [invitations, setInvitations] = useState<Invitation[] | undefined>(
    undefined
  );

  useEffect(() => subscribeToInvitations(setInvitations), [setInvitations]);

  return (
    <TableContainer sx={{ mt: 5 }} component={Box}>
      <h2>Status of invitations</h2>
      <InfoBox content="To protect patients’ privacy, invitations will expire after 7 days from sending and will be permanently deleted after 30 days. Resending an unexpired invitation will trigger a new email to the recipient with instructions for accepting the invitation." />
      <Table sx={{ mt: 2 }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ background: THEME.colors.grey100 }}>
            <TableCell sx={{ fontWeight: "bold" }}>EMAIL</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>SENT ON</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>EXPIRES IN</TableCell>
            <TableCell sx={{ fontWeight: "bold" }}>STATUS</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invitations?.map((invitation) => (
            <TableRow
              key={invitation.id}
              sx={{
                "&:last-child td, &:last-child th": { border: 0 },
                "&:nth-of-type(even)": { background: THEME.colors.grey100 },
              }}
            >
              <TableCell component="th" scope="row">
                {invitation.patientEmail}
              </TableCell>
              <TableCell>{invitation.timestamp.toLocaleString()}</TableCell>
              <TableCell>
                {invitation.accepted == null && !invitation.expired
                  ? `${getDaysToExpiration(invitation.timestamp)} days`
                  : "-"}
              </TableCell>
              <TableCell>
                {invitation.expired
                  ? "Expired"
                  : invitation.accepted
                  ? "Accepted"
                  : invitation.accepted === null
                  ? "Waiting for response"
                  : "Rejected"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
