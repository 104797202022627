import React, { FC, PropsWithChildren } from "react";
import Box, { BoxProps } from "@mui/material/Box";
import { SxProps } from "@mui/material/styles";
import { THEME } from "../theme";

interface FormCardProps {
  component?: BoxProps["component"];
  minWidth?: string;
  onSubmit?: (event: React.FormEvent<HTMLFormElement>) => void;
  noValidate?: boolean;
}

export const actionContainerStyles: SxProps = {
  display: "flex",
  flexDirection: "row",
  gap: 1,
  marginTop: 2,
  padding: 2,
  justifyContent: "flex-end",
  backgroundColor: THEME.colors.grey050,
  borderTop: 1,
  borderColor: "#dfe3e6",
};

export const contentContainerStyles: SxProps = {
  display: "flex",
  flexDirection: "column",
  gap: 1,
  paddingX: 2,
  paddingTop: 2,
  paddingBottom: 1,
};

const FormCard: FC<PropsWithChildren<FormCardProps>> = ({
  children,
  component,
  minWidth,
  onSubmit,
  noValidate,
}) => {
  if (component === "form") {
    return (
      <Box
        component={component}
        onSubmit={onSubmit}
        noValidate={noValidate}
        sx={{
          backgroundColor: "#FdFdFd",
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          gap: 1,
          boxShadow: "0px 2px 5px 0px #DDD4C5",
          border: 2,
          borderColor: "#dfe3e6",
          minWidth: minWidth,
          overflow: "Hidden",
        }}
      >
        {children}
      </Box>
    );
  }
  return (
    <Box
      component={component}
      sx={{
        backgroundColor: "#FdFdFd",
        borderRadius: 2,
        display: "flex",
        flexDirection: "column",
        gap: 1,
        boxShadow: "0px 2px 5px 0px #DDD4C5",
        border: 2,
        borderColor: "#dfe3e6",
        minWidth: minWidth,
        overflow: "Hidden",
      }}
    >
      {children}
    </Box>
  );
};

export default FormCard;
