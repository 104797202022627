import { useContext } from "react";
import { EntriesContext } from "../stores/EntriesContext";

export const useEntries = () => {
  const context = useContext(EntriesContext);
  if (context === undefined) {
    throw Error("Attempted to use AppStore before initialization");
  }
  return context;
};
