import { EntryLog } from "common/dist/types/LogEntry";
import { getDatesInRange } from "common/dist/feat/date";
import { getStringArrayCounts } from "common/dist/feat/logEntry";
import { emotionColors, emotionToColorIndex } from "./heatmapColorUtils";
import { HeatmapUnit } from "./HeatmapTypes";

const loggedCategory = (categories: string[]) => (d: EntryLog[]) => {
  const loggedCategories = getStringArrayCounts(
    d.flatMap((e) =>
      e.emotions
        .map((em) => em.category)
        .filter((category) => categories.includes(category))
    )
  );

  const topCategory = loggedCategories.filter(
    (c) => c.count === loggedCategories.at(0)?.count
  );

  return topCategory.length === 0
    ? 0
    : topCategory.length === 1
    ? emotionToColorIndex.find(
        (e2c) => e2c.displayName === topCategory.at(0)?.element
      )?.index ?? 0
    : emotionColors.length - 1;
};

const physicalSymptom = (selectedSymptom?: string) => (d: EntryLog[]) =>
  d.filter((e) => e.symptoms.find((s) => s.label === selectedSymptom)).length;

const numberOfEntries = (d: EntryLog[]) => d.length;

export const getDataForHeatmapUnit = (
  rawData: {
    key: Date;
    date: Date;
    data: EntryLog[];
  }[],
  unit: HeatmapUnit,
  selectedCategories: string[],
  selectedSymptom?: string
) =>
  unit === HeatmapUnit.LoggedEmotionCategories
    ? rawData.map((d) => ({
        ...d,
        data: loggedCategory(selectedCategories)(d.data),
      }))
    : unit === HeatmapUnit.PhysicalSymptoms
    ? rawData.map((d) => ({
        ...d,
        data: physicalSymptom(selectedSymptom)(d.data),
      }))
    : rawData.map((d) => ({ ...d, data: numberOfEntries(d.data) }));

export const getDatesForPreviousYear = () => {
  const dateNow = new Date();
  dateNow.setHours(23, 59, 59, 0);

  const dateYearBefore = new Date();
  dateYearBefore.setFullYear(dateNow.getFullYear() - 1);
  dateYearBefore.setDate(1);
  dateYearBefore.setMonth(dateNow.getMonth() + 1);

  return getDatesInRange(dateYearBefore, dateNow);
};

export const getTooltipValue = (unit: HeatmapUnit, value: number) =>
  unit === HeatmapUnit.LoggedEmotionCategories
    ? value < emotionToColorIndex.length
      ? emotionToColorIndex
          .find((e) => e.index === value)!
          .displayName.replace(/^\w/, (c) => c.toUpperCase())
      : "Multiple categories"
    : value;
