import { PrivateProfessionalProfileV2 } from "common/dist/types/Therapist";

export function validateProfile(
  p: Partial<PrivateProfessionalProfileV2>
): PrivateProfessionalProfileV2 | undefined {
  const { name, email, title, number, showEmail, showNumber } = p;
  const experience =
    p.experience && p.experience?.length > 0
      ? p.experience?.every((e) => e && e.company && e.title && e.years)
      : true;

  const education =
    p.education && p.education?.length > 0
      ? p.education?.every((e) => e && e.degree && e.institution)
      : true;

  return name && email && education && experience && title
    ? {
        name,
        email,
        number: number ?? "",
        experience: p.experience ?? [],
        education: p.education ?? [],
        title,
        showEmail: showEmail ?? false,
        showNumber: showNumber ?? false,
      }
    : undefined;
}
