import { FC } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { EntryLog } from "common/dist/types/LogEntry";
import Icon from "../../../../../components/Icon";
import { THEME } from "../../../../../theme";
import { styles } from "../styles";

const Trend: FC<{ current: number; previous: number }> = ({
  current,
  previous,
}) => (
  <Box display="flex" flexDirection="row" ml={1}>
    <Typography sx={styles.statisticDescription}>{"("}</Typography>
    {previous === 0 ? null : current === previous ? (
      <Icon variant="trendingFlat" color={THEME.colors.blue500} />
    ) : current > previous ? (
      <Icon variant="trendingUp" color={THEME.colors.green500} />
    ) : (
      <Icon variant="trendingDown" color={THEME.colors.red400} />
    )}
    <Typography sx={styles.statisticDescription}>
      {previous === 0
        ? "\u00A0- )"
        : `${Math.round((current / previous) * 100) - 100}%)`}
    </Typography>
  </Box>
);

export const DiaryStatistics: FC<{
  entries: EntryLog[];
  range: number;
  previousEntries?: EntryLog[];
}> = ({ entries, range, previousEntries }) => {
  const daysLogged = entries
    .map((e) => e.timestamp.toDateString())
    .filter((v, i, a) => a.indexOf(v) === i).length;
  const previousDaysLogged = previousEntries
    ?.map((e) => e.timestamp.toDateString())
    .filter((v, i, a) => a.indexOf(v) === i).length;

  return (
    <Box
      component="section"
      display="flex"
      flexDirection="row"
      alignItems="baseline"
    >
      <Typography sx={styles.statistic}>{entries.length}</Typography>
      <Typography sx={styles.statisticDescription}>logs</Typography>
      {previousEntries && (
        <Trend current={entries.length} previous={previousEntries.length} />
      )}

      <Typography sx={styles.statisticDescription} ml={4}>
        Logged at least once on
      </Typography>
      <Typography sx={styles.statistic}>{daysLogged}</Typography>
      <Typography sx={styles.statisticDescription}>
        days {range !== 0 && `(${Math.round((daysLogged / range) * 100)}%)`}
      </Typography>
      {previousDaysLogged !== undefined && (
        <Trend current={daysLogged} previous={previousDaysLogged} />
      )}

      <Typography sx={styles.statisticDescription} ml={4}>
        Avg. diary length
      </Typography>
      <Typography sx={styles.statistic}>
        {entries.length === 0
          ? "-"
          : Math.round(
              entries
                .map((e) => e.diary.split(" ").length)
                .reduce((a, b) => a + b) / entries.length
            )}
      </Typography>
      <Typography sx={styles.statisticDescription}>words</Typography>
    </Box>
  );
};
