import {
  getEmotionCategories,
  EntryLog,
  EmotionCategory,
} from "../../types/LogEntry";
import { WEEKDAYS } from "../date";

interface Counter {
  [key: string]: number;
}

export function getStringArrayCounts(array: string[]) {
  return Object.entries(
    array.reduce((counter, element) => {
      counter[element] = (counter[element] || 0) + 1;
      return counter;
    }, {} as Counter)
  )
    .map(([element, count]) => ({ element, count }))
    .sort((a, b) => b.count - a.count);
}

const categoryToValue = (category: string) => {
  switch (category) {
    case "neutral":
      return 0;
    case "happiness":
      return 1;
    case "confusion":
      return 2;
    case "anger":
      return 3;
    case "fear":
      return 4;
    case "sadness":
      return 5;
    default:
      return 100;
  }
};

export interface CategoriesDuringWeek {
  values: {
    label: string;
    category: string;
    value: number;
    color: string;
  }[];
  total: number;
  wdIdx: number;
}

export function categoriesDuringWeek(
  entries: EntryLog[],
  theme: "light" | "dark" = "light"
): CategoriesDuringWeek[] {
  const processedEntries: CategoriesDuringWeek[] = WEEKDAYS.map((wd) => ({
    wdIdx: wd.index,
    values: getStringArrayCounts(
      entries
        .filter((e) => e.timestamp.getDay() === wd.index)
        .flatMap((e) => e.emotions.map((emotion) => emotion.category))
    )
      .map(({ element, count }) => ({
        category: element,
        value: count,
        color:
          getEmotionCategories(theme).find((c) => c.category === element)
            ?.color ?? "",
      }))
      .sort(
        (a, b) => categoryToValue(a.category) - categoryToValue(b.category)
      ),
  }))
    .map((element) => ({
      ...element,
      total: element.values.reduce((sum, value) => sum + value.value, 0),
    }))
    .map((el) => ({
      ...el,
      values: el.values.map((v) => ({
        ...v,
        label: `${Math.round((v.value / el.total) * 100)}%`,
      })),
    }));

  return processedEntries;
}

export function categoriesDuringWeekValues(
  data: CategoriesDuringWeek[],
  wd: number,
  selectedCategories: EmotionCategory[]
) {
  return (
    data
      .find((e) => e.wdIdx === wd)
      ?.values.filter((v) =>
        selectedCategories.find((c) => c.category === v.category)
      ) ?? []
  );
}
