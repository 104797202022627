import { useContext } from "react";
import { AssigneeContext } from "../stores/AssigneeContext";

export const useAssignee = () => {
  const context = useContext(AssigneeContext);
  if (context === undefined) {
    throw new Error("Assignee not initialized yet");
  }
  return context;
};
