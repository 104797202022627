import React from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";

const message = `
We are currently conducting scheduled maintenance on Maind to enhance your experience.
We apologize for any inconvenience this may cause and appreciate your patience and understanding.
If you have any concerns, please contact our team at admin@maind.app.
`;

const UnderMaintenance = () => {
  return (
    <Box
      display="flex"
      flex={1}
      justifyContent="center"
      alignItems="center"
      height="100%"
      paddingBottom={20}
    >
      <Typography maxWidth="550px">{message}</Typography>
    </Box>
  );
};

export default UnderMaintenance;
