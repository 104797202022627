import {
  createContext,
  FC,
  useEffect,
  useState,
  PropsWithChildren,
} from "react";
import { onAuthStateChanged } from "firebase/auth";
import { EntryLog } from "common/dist/types/LogEntry";
import { auth } from "../features/firebase";
import { subscribeToEntries } from "../services/entries";
import { useAssignee } from "../hooks/useAssignee";

interface EntriesContextType {
  entries: EntryLog[] | undefined;
}

export const EntriesContext = createContext<EntriesContextType | undefined>(
  undefined
);

export const EntriesProvider: FC<PropsWithChildren> = ({ children }) => {
  const [entries, setEntries] = useState<EntryLog[] | undefined>(undefined);
  const { assignee } = useAssignee();

  onAuthStateChanged(auth, async (user) => {
    if (user === null) {
      setEntries(undefined);
    }
  });

  useEffect(() => {
    if (assignee) {
      return subscribeToEntries(setEntries, assignee.uid);
    } else {
      setEntries(undefined);
    }
  }, [assignee]);

  const context: EntriesContextType = {
    entries: entries,
  };
  return (
    <EntriesContext.Provider value={context}>
      {children}
    </EntriesContext.Provider>
  );
};
