import { onSnapshot, doc, DocumentReference } from "firebase/firestore";
import { AppSettings } from "common/dist/types/AppSettings";
import { db } from "../../features/firebase";

export const subscribeToAppSettings = (
  onUpdate: (appSettings: AppSettings | undefined) => void
) => {
  const unsubscribe = onSnapshot(
    doc(db, "appSettings", "publicSettings") as DocumentReference<AppSettings>,
    (documentSnapshot) => {
      const settings = documentSnapshot.data();
      onUpdate(settings);
    }
  );
  return unsubscribe;
};
