import { FC, useState } from "react";
import Box from "@mui/material/Box";
import { functionsClient } from "../firebase";
import Button from "../../components/Button";
import Snackbar from "../../components/Snackbar";
import TextInput from "../../components/TextInput";
import FormCard, {
  actionContainerStyles,
  contentContainerStyles,
} from "../../components/FormCard";
import InfoBox from "../../components/InfoBox";

export const EmailInvitation: FC = () => {
  const [email, setEmail] = useState<string>("");

  const [sendResult, setSendResult] = useState<
    { status: boolean; message: string } | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (email) {
      setLoading(true);
      try {
        const result = await functionsClient.sendInvitation({
          patientEmail: email,
        });
        setSendResult({
          status: result.data.status,
          message: result.data.message,
        });
        if (result.data.status) {
          setEmail("");
        }
      } catch (error) {
        setSendResult({
          status: false,
          message:
            "Something went wrong with invitation. Please try again later.",
        });
      }
      setLoading(false);
    }
  };

  return (
    <FormCard component="form" noValidate onSubmit={handleSubmit}>
      <Box sx={contentContainerStyles}>
        <h2>Send invitation via email</h2>
        <TextInput
          id="email"
          label="Client email"
          value={email}
          placeholder="Email address to send the invitation to"
          onChange={setEmail}
        />
        <InfoBox content="To see what the invitation looks like, simply type in your own email address and press “Send”!" />
      </Box>
      <Box sx={actionContainerStyles}>
        <Button
          type="submit"
          variant="primary"
          loading={loading}
          size="medium"
          label="send"
          disabled={!email}
        />
      </Box>
      <Snackbar
        message={sendResult?.message}
        custom={true}
        severity={sendResult?.status ? "success" : "error"}
        duration={3000}
        setVisibility={() => setSendResult(undefined)}
      />
    </FormCard>
  );
};
