import { FC } from "react";
import { Box, SxProps, Typography } from "@mui/material";
import { THEME } from "../../theme";

const barFont: SxProps = {
  fontSize: "14px",
  verticalAlign: "middle",
  textTransform: "capitalize",
};

const labelStyle: SxProps = {
  ...barFont,
  color: THEME.colors.grey600,
};

interface HorizontalBarProps {
  values: {
    color: string;
    value: number;
    label?: string;
  }[];
  total: number;
  label?: string;
  labelWidth?: number;
  showRatio?: boolean;
}

const HorizontalBar: FC<HorizontalBarProps> = ({
  values,
  total,
  label,
  labelWidth,
  showRatio,
}) => {
  const sumValues = values.reduce((sum, v) => sum + v.value, 0);

  return (
    <Box
      width="100%"
      height="26px"
      display="flex"
      flexDirection="row"
      flexWrap="nowrap"
      alignItems="center"
      gap={1}
    >
      {label && (
        <Typography
          width={labelWidth ?? 35}
          sx={{ ...labelStyle, textAlign: "right" }}
          color={THEME.colors.grey600}
          aria-label="horizontal bar label"
        >
          {label}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100%",
          background: THEME.colors.grey100,
          borderRadius: "50px",
        }}
      >
        {values.map((v, i) => (
          <Box
            key={`horizontal-bar-${v.color}-${v.value}`}
            sx={{
              display: "flex",
              background: v.color,
              width: `${(v.value / total) * 100}%`,
              height: "100%",
              borderTopLeftRadius: i === 0 ? 50 : 0,
              borderBottomLeftRadius: i === 0 ? 50 : 0,
              borderTopRightRadius: i === values.length - 1 ? 50 : 0,
              borderBottomRightRadius: i === values.length - 1 ? 50 : 0,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              color={THEME.colors.grey000}
              textAlign="center"
              sx={barFont}
              aria-label={"horizontal bar value"}
            >
              {v.label}
            </Typography>
          </Box>
        ))}
      </Box>
      {showRatio && (
        <Typography sx={labelStyle} textAlign="left" width={labelWidth ?? 70}>
          {`${sumValues}/${total}`}
        </Typography>
      )}
    </Box>
  );
};

export default HorizontalBar;
