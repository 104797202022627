import { FC, useState } from "react";
import { Box } from "@mui/material";
import { useEntries } from "../../../../hooks/useEntries";
import { useHomeworks } from "../../../../hooks/useHomeworks";
import { useAssignee } from "../../../../hooks/useAssignee";
import {
  useAssigneeConversations,
  useAssigneeFeedbacks,
} from "../../../../hooks/useAppData";
import { HomeworkDetails } from "../HomeworkTab/HomeworkDetails";
import TimelineBox, { TimelineItem } from "./TimelineBox";

export const Timeline: FC = () => {
  const { assignee } = useAssignee();
  const assigneeId = assignee?.uid ?? "";
  const { entries } = useEntries();
  const { homeworks } = useHomeworks();
  const { conversations } = useAssigneeConversations(assigneeId);
  const { feedbacks } = useAssigneeFeedbacks(assigneeId);

  const [homeworkId, setHomeworkId] = useState<string>();

  if (!conversations || !feedbacks || !homeworks) {
    return null;
  }

  const mappedEntries: TimelineItem[] = entries
    ? entries.map((e) => ({
        id: e.id,
        timestamp: e.timestamp,
        item: { type: "entryLog", entry: e },
      }))
    : [];

  const items = mappedEntries
    .concat(
      conversations.flatMap((c) =>
        c.conversation.messages.map((message, i) => ({
          id: `${c.id}-${i}`,
          timestamp: message.timestamp,
          item: {
            type: "message",
            message,
            sentByUser: message.sender === assigneeId,
          },
        }))
      )
    )
    .concat(
      feedbacks.map((f) => ({
        id: f.id,
        timestamp: f.feedback.timestamp,
        item: { type: "feedback", feedback: f.feedback },
      }))
    )
    .concat(
      homeworks.map((h) => ({
        id: h.id,
        timestamp: h.timestamp,
        item: { type: "homework", homework: h },
      }))
    )
    .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());

  return homeworkId ? (
    <HomeworkDetails
      homework={homeworks?.find((h) => h.id === homeworkId)!}
      close={() => setHomeworkId(undefined)}
    />
  ) : (
    <Box pt={1} display="flex" flexDirection="column" gap={1}>
      {items?.map((i) => (
        <TimelineBox
          key={i.id}
          timelineItem={i}
          onClick={
            i.item.type === "homework" ? () => setHomeworkId(i.id) : undefined
          }
        />
      ))}
    </Box>
  );
};
