import { FC } from "react";
import { Box, Chip, Grid, Typography } from "@mui/material";
import { DiscreteLegend, DiscreteLegendEntry } from "reaviz";
import { getEmotionCategories } from "common/dist/types/LogEntry";
import "./styles.css";
import { HeatmapDataPoint, HeatmapUnit } from "./HeatmapTypes";
import { styles } from "../styles";
import { THEME } from "../../../../../theme";

const EmotionCategoryLegend: FC<{
  selectedCategories: string[];
  onClick: (displayName: string) => void;
}> = ({ selectedCategories, onClick }) => {
  return (
    <Grid container spacing={2} justifyContent="space-evenly">
      {getEmotionCategories("light").map(({ category, color }) => (
        <Grid
          item
          key={`emotion-category-legend-${category}`}
          sx={{ width: 136, textAlign: "center" }}
        >
          <Chip
            sx={{
              ...styles.chipThin,
              background: selectedCategories.includes(category)
                ? color
                : THEME.colors.grey100,
              mb: 0.1,
              color: selectedCategories.includes(category)
                ? THEME.colors.grey000
                : THEME.colors.grey600,
            }}
            label={category.replace(/^\w/, (c) => c.toUpperCase())}
            onClick={() => onClick(category)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const HeatmapLegend: FC<{
  data: HeatmapDataPoint[];
  unit: HeatmapUnit;
  colorScheme?: string[];
  width: number;
  selectedCategories: string[];
  onClick: (displayName: string) => void;
}> = ({ data, unit, colorScheme, width, selectedCategories, onClick }) => (
  <Box display="flex" flexDirection="column" alignItems="center" width={width}>
    {unit !== HeatmapUnit.LoggedEmotionCategories && (
      <Typography sx={{ pb: 1, color: THEME.colors.grey600 }}>
        Number of occurrences
      </Typography>
    )}
    {unit === HeatmapUnit.LoggedEmotionCategories ? (
      <EmotionCategoryLegend
        selectedCategories={selectedCategories}
        onClick={onClick}
      />
    ) : (
      <DiscreteLegend
        orientation="horizontal"
        entries={data
          ?.map((d) => d.data)
          .filter((v, i, a) => a.indexOf(v) === i)
          .sort()
          .map((value, i) => (
            <DiscreteLegendEntry
              style={{
                flexDirection: "column",
                padding: 0,
                color: THEME.colors.grey600,
              }}
              className="discrete_legend_entry"
              key={`legend-${i}`}
              label={String(value)}
              color={colorScheme?.at(value) ?? THEME.colors.grey000}
            />
          ))}
      />
    )}
  </Box>
);
