import { FC, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";
import Button from "../Button";
import { THEME } from "../../theme";

interface ConfirmationDialogProps {
  title: string;
  content: string;
  approveText: string;
  open: boolean;
  handleClose: () => void;
  approve: () => Promise<void>;
  onError?: string;
  buttonVariant?: "primary" | "danger";
}

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  title,
  content,
  approveText,
  approve,
  open,
  handleClose,
  onError,
  buttonVariant,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const handleApprove = async () => {
    setLoading(true);
    try {
      if (errorMsg) {
        setErrorMsg(undefined);
      }
      await approve();
    } catch {
      if (onError) {
        setErrorMsg(onError);
      } else {
        setErrorMsg("Sorry something went wrong, try again please.");
      }
    } finally {
      setLoading(false);
    }
  };

  const closeAndClearError = () => {
    if (errorMsg) {
      setErrorMsg(undefined);
    }
    handleClose();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={closeAndClearError}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={closeAndClearError}
            autoFocus={true}
            variant="tertiary"
            color={THEME.colors.grey600}
            label="cancel"
            size="medium"
          />
          <Button
            onClick={handleApprove}
            loading={loading}
            label={approveText}
            size="medium"
            variant={buttonVariant ? buttonVariant : "primary"}
          />
        </DialogActions>
        {errorMsg && (
          <DialogContent>
            <DialogContentText>
              <Typography color="red">{errorMsg}</Typography>
            </DialogContentText>
          </DialogContent>
        )}
      </Dialog>
    </div>
  );
};
