export const timestampToDateTimeString = (timestamp: Date): string =>
  `${timestamp.toLocaleDateString()} at ${timestamp.toLocaleTimeString(
    undefined,
    {
      hour: "2-digit",
      minute: "2-digit",
    }
  )}`;

export const timestampToShortDateString = (timestamp: Date): string =>
  timestamp.toLocaleDateString();

export const getDatesInRange = (start: Date, end: Date) => {
  const date = new Date(new Date(start).setHours(23, 59, 59, 0));
  const dates = [];

  while (date <= end) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }

  return dates;
};

export const WEEKDAYS = [
  {
    displayName: "sun",
    index: 0,
    order: 6,
  },
  {
    displayName: "mon",
    index: 1,
    order: 0,
  },
  {
    displayName: "tue",
    index: 2,
    order: 1,
  },
  {
    displayName: "wed",
    index: 3,
    order: 2,
  },
  {
    displayName: "thu",
    index: 4,
    order: 3,
  },
  {
    displayName: "fri",
    index: 5,
    order: 4,
  },
  {
    displayName: "sat",
    index: 6,
    order: 5,
  },
];
