import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { FC } from "react";

export const RadioButtonGroup: FC<{
  values: { value: string; label: string }[];
  selectedValue?: string;
  onChange: (value: string) => void;
}> = ({ values, selectedValue, onChange }) => (
  <RadioGroup
    value={selectedValue}
    onChange={(event) => onChange(event.target.value)}
    sx={{ pb: 1, flexDirection: "row", flexWrap: "wrap" }}
  >
    {values.map(({ value, label }) => (
      <FormControlLabel
        key={`${value}-radio`}
        value={value}
        control={<Radio />}
        label={label}
      />
    ))}
  </RadioGroup>
);
