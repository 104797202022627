import { ClassifierEmotionDescriptions } from "common/dist/types/ClassifierResults";

export const allClassifierEmotions = Object.entries(
  ClassifierEmotionDescriptions
);

export const classifierEmotions = allClassifierEmotions.map((e) => ({
  value: e[0],
  displayName: e[1].displayName,
}));

export const timeRanges = [7, 14, 30, 0];
