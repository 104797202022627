import { useContext } from "react";
import { HomeworksContext } from "../stores/HomeworksContext";

export const useHomeworks = () => {
  const context = useContext(HomeworksContext);
  if (context === undefined) {
    throw Error("Attempted to use AppStore before initialization");
  }
  return context;
};
