import { FC } from "react";
import Box from "@mui/material/Box";
import { SxProps } from "@mui/material";
import Typography from "@mui/material/Typography";
import { EntryLog, EntrySection } from "common/dist/types/LogEntry";
import { ClassifierEmotionDescriptions } from "common/dist/types/ClassifierResults";
import { timestampToDateTimeString } from "common/dist/feat/date";
import Chip from "../../../../../components/Chip";

import { THEME } from "../../../../../theme";

const container: SxProps = {
  display: "flex",
  flexDirection: "column",
  backgroundColor: THEME.colors.grey200,
  border: 0,
  padding: 1,
  flex: 1,
  width: "100%",
  borderRadius: THEME.radius.round10,
};

const contentContainer: SxProps = {
  flexDirection: "row",
  flexWrap: "wrap",
  display: "flex",
  mt: 1,
  gap: 1,
};

interface EntryProps {
  entry: EntryLog;
}

const Entry: FC<EntryProps> = ({ entry }) => {
  const classifiedEmotion = entry.topClassifiedEmotion
    ? ClassifierEmotionDescriptions[entry.topClassifiedEmotion]
    : null;
  const showDiary =
    entry.hidden &&
    !entry.hidden.includes(EntrySection.DiaryEntry) &&
    entry.diary;
  const showEmotions =
    entry.hidden &&
    !entry.hidden.includes(EntrySection.EmotionLog) &&
    entry.emotions.length > 0;
  const showSymptoms =
    entry.hidden &&
    !entry.hidden.includes(EntrySection.SymptomLog) &&
    entry.symptoms.length > 0;

  return (
    <Box component="li" sx={container} maxWidth={900}>
      <Box
        display="flex"
        flex={1}
        flexDirection="row"
        justifyContent="space-between"
      >
        <Box display="flex" flex={1} flexDirection="row" gap={1}>
          <Typography>{timestampToDateTimeString(entry.timestamp)}</Typography>
          {classifiedEmotion && (
            <Chip
              label={classifiedEmotion.displayName}
              variant="custom"
              backgroundColor={classifiedEmotion.color}
              size="medium"
            />
          )}
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          {!entry.seenByTherapist && (
            <Box
              height={20}
              width={20}
              borderRadius={THEME.radius.round32}
              sx={{ backgroundColor: THEME.colors.red400 }}
            />
          )}
        </Box>
      </Box>
      <Box display="flex" flexDirection="column" gap={2}>
        {showEmotions && (
          <Box>
            <Typography fontWeight="bold">Emotions:</Typography>
            <Box sx={contentContainer}>
              {entry.emotions.map((emotion) => (
                <Chip
                  key={`${emotion.category}-${emotion.displayName}-emotion`}
                  label={emotion.displayName.toUpperCase()}
                  variant="custom"
                  backgroundColor={
                    THEME.colors.light[`${emotion.category}Category`]
                  }
                  size="small"
                />
              ))}
            </Box>
          </Box>
        )}
        {showSymptoms && (
          <Box>
            <Typography fontWeight="bold">Symptoms:</Typography>
            <Box sx={contentContainer}>
              {entry.symptoms.map((symptom) => (
                <Chip
                  key={`${symptom.label}-chip`}
                  label={symptom.label.replace(/^\w/, (c) => c.toUpperCase())}
                  variant="secondary"
                  size="small"
                />
              ))}
            </Box>
          </Box>
        )}
        {showDiary && (
          <Box>
            <Typography fontWeight="bold">Diary:</Typography>
            <Typography>{entry.diary}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Entry;
