import { FC, useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useLocation } from "wouter";
import { HomeworkDraft } from "common/types/Homework";
import { useAssignee } from "../../../../hooks/useAssignee";
import Button from "../../../../components/Button";
import {
  createHomeworkDraft,
  subscribeToHomeworkDrafts,
  updateHomeworkAnswersSeen,
} from "../../../../services/homeworks";
import { NewHomework } from "./NewHomework";
import { useHomeworks } from "../../../../hooks/useHomeworks";
import HomeworkCard from "./HomeworkCard";
import DraftCard from "./DraftCard";

export const HomeworkTab: FC = () => {
  const [, setLocation] = useLocation();
  const { assignee } = useAssignee();
  const assigneeId = assignee?.uid ?? "";
  const [newHomeworkId, setNewHomeworkId] = useState<string>();
  const { homeworks } = useHomeworks();

  const homeworkWithUnseen = useMemo(
    () =>
      homeworks?.map((h) => ({
        ...h,
        unseen: !!h.answers.find(
          (answer) =>
            !h.lastSeenByTherapist || answer.timestamp > h.lastSeenByTherapist
        ),
      })),
    [homeworks]
  );

  const [loading, setLoading] = useState(false);

  const [drafts, setDrafts] =
    useState<{ draft: HomeworkDraft; id: string }[]>();

  useEffect(
    () => subscribeToHomeworkDrafts(assigneeId, setDrafts),
    [setDrafts, assigneeId]
  );

  return newHomeworkId ? (
    <NewHomework
      assigneeId={assigneeId}
      draftId={newHomeworkId}
      draft={drafts?.find((d) => d.id === newHomeworkId)?.draft}
      close={() => setNewHomeworkId(undefined)}
    />
  ) : (
    <Box pt={1}>
      <Box py={1} display="flex" gap={2}>
        <Button
          onClick={() => {
            setLoading(true);
            createHomeworkDraft(assigneeId)
              .then(setNewHomeworkId)
              .finally(() => setLoading(false));
          }}
          variant="primary"
          loading={loading}
          label="New homework"
          size="medium"
        />
        <Button
          onClick={() => setLocation("/templates")}
          variant="secondary"
          label="Browse homework templates"
          size="medium"
        />
      </Box>
      <Box display="flex" flexDirection="column" gap={1}>
        {drafts?.map(({ draft, id }) => (
          <Box
            key={id}
            onClick={() => setNewHomeworkId(id)}
            sx={{ cursor: "pointer" }}
          >
            <DraftCard draft={draft} />
          </Box>
        ))}
        {homeworkWithUnseen?.map((h) => (
          <Box
            key={h.id}
            onClick={() => {
              if (h.unseen) {
                updateHomeworkAnswersSeen(h.id);
              }
              setLocation(`/homework/${h.id}`);
            }}
            sx={{ cursor: "pointer" }}
          >
            <HomeworkCard homework={h} unseen={h.unseen} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};
