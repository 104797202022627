import { FC, useState } from "react";
import { Box, Chip, Typography } from "@mui/material";
import { getEmotionCategories } from "common/dist/types/LogEntry";
import { useAssignee } from "../../../../hooks/useAssignee";
import { THEME } from "../../../../theme";
import Overview from "./Overview";
import { styles } from "./styles";
import { CorrelationView } from "./correlations/CorrelationView";
import { classifierEmotions } from "./dataUtils";
import { HeatmapView } from "./heatmap/HeatmapView";
import { HeatmapUnit } from "./heatmap/HeatmapTypes";

enum AnalyticsView {
  Heatmap = "heatmap",
  Overview = "overview",
  Correlations = "correlations",
}

export const AnalyticsTab: FC = () => {
  const { assignee } = useAssignee();
  const [view, setView] = useState<AnalyticsView>(AnalyticsView.Overview);

  const [heatmapUnit, setHeatmapUnit] = useState(
    HeatmapUnit.NumberOfJournalEntries
  );
  const [heatmapSymptom, setHeatmapSymptom] = useState<string>();
  const [selectedCategories, setSelectedCategories] = useState(
    getEmotionCategories("light")
      .slice(0, 2)
      .map((c) => c.category)
  );

  const [correlationCategories, setCorrelationCategories] =
    useState<{ value: string; displayName: string }[]>();
  const [correlationEmotions, setCorrelationEmotions] =
    useState<{ value: string; displayName: string }[]>();
  const [correlationSymptoms, setCorrelationSymptoms] =
    useState<{ value: string; displayName: string }[]>();
  const [correlationClassifierEmotions, setCorrelationClassifierEmotions] =
    useState(classifierEmotions.slice(0, 2));

  return (
    <Box sx={{ mt: 2 }}>
      <Box sx={styles.chipGroup}>
        <Chip
          label={AnalyticsView.Overview.toUpperCase()}
          variant={view === AnalyticsView.Overview ? "filled" : "outlined"}
          color={view === AnalyticsView.Overview ? "primary" : "default"}
          onClick={() => setView(AnalyticsView.Overview)}
          sx={{
            ...styles.chipButton,
            color:
              view === AnalyticsView.Overview
                ? THEME.colors.grey000
                : THEME.colors.grey600,
          }}
        />
        <Chip
          label={AnalyticsView.Heatmap.toUpperCase()}
          variant={view === AnalyticsView.Heatmap ? "filled" : "outlined"}
          color={view === AnalyticsView.Heatmap ? "primary" : "default"}
          onClick={() => setView(AnalyticsView.Heatmap)}
          sx={{
            ...styles.chipButton,
            color:
              view === AnalyticsView.Heatmap
                ? THEME.colors.grey000
                : THEME.colors.grey600,
          }}
        />
        <Chip
          label={AnalyticsView.Correlations.toUpperCase()}
          variant={view === AnalyticsView.Correlations ? "filled" : "outlined"}
          color={view === AnalyticsView.Correlations ? "primary" : "default"}
          onClick={() => setView(AnalyticsView.Correlations)}
          sx={{
            ...styles.chipButton,
            color:
              view === AnalyticsView.Correlations
                ? THEME.colors.grey000
                : THEME.colors.grey600,
          }}
        />
      </Box>

      {assignee &&
        (view === AnalyticsView.Overview ? (
          <Overview assignee={assignee} />
        ) : view === AnalyticsView.Heatmap ? (
          <HeatmapView
            unit={heatmapUnit}
            setUnit={setHeatmapUnit}
            selectedSymptom={heatmapSymptom}
            setSelectedSymptom={setHeatmapSymptom}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
          />
        ) : view === AnalyticsView.Correlations ? (
          <CorrelationView
            selectedCategories={correlationCategories}
            setSelectedCategories={setCorrelationCategories}
            selectedEmotions={correlationEmotions}
            setSelectedEmotions={setCorrelationEmotions}
            selectedSymptoms={correlationSymptoms}
            setSelectedSymptoms={setCorrelationSymptoms}
            selectedClassifierEmotions={correlationClassifierEmotions}
            setSelectedClassifierEmotions={setCorrelationClassifierEmotions}
          />
        ) : (
          <Typography>Information not found for selected profile.</Typography>
        ))}
    </Box>
  );
};
