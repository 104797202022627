import { HomeworkTemplate } from "common/dist/types/Homework";
import { auth } from "../../../firebase";

export const checkTemplateExists = (
  templates: HomeworkTemplate[],
  title: string,
  description: string,
  images: string[],
  labels: string[]
) => {
  const uid = auth.currentUser?.uid;

  return !!templates.find(
    (t) =>
      t.createdBy === uid &&
      t.title === title &&
      t.description === description &&
      (t.images ?? []).every((image) => images.find((i) => image === i)) &&
      images.every((image) => (t.images ?? []).find((i) => image === i)) &&
      (t.labels ?? []).every((label) => labels.find((i) => label === i)) &&
      labels.every((label) => (t.labels ?? []).find((i) => label === i))
  );
};
