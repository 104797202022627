import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { HomeworkAnswer } from "common/types/Homework";
import { timestampToDateTimeString } from "common/dist/feat/date";
import { ItemCard } from "../../../../../../components/ItemCard";
import { ThumbnailGroup } from "../../../../../../components/ThumbnailGroup";

interface AnswerCardProps {
  answer: HomeworkAnswer;
}

const AnswerCard: FC<AnswerCardProps> = ({ answer }) => {
  return (
    <ItemCard>
      <Box display="flex" flexDirection="row">
        <Typography variant="caption" pb={1}>
          {timestampToDateTimeString(answer.timestamp)}
        </Typography>
      </Box>
      <Typography whiteSpace="pre-line">{answer.answer}</Typography>
      {answer.images && <ThumbnailGroup images={answer.images} />}
    </ItemCard>
  );
};

export default AnswerCard;
