import {
  createContext,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";

import { AppSettings } from "common/types/AppSettings";
import { subscribeToAppSettings } from "../services/settings";

export type AppSettingsContextType = AppSettings | undefined;

export const AppSettingsContext = createContext<
  AppSettingsContextType | undefined
>(undefined);

export const AppSettingsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [appSettings, setAppSettings] = useState<AppSettings | undefined>(
    undefined
  );

  useEffect(() => {
    return subscribeToAppSettings(setAppSettings);
  }, []);

  const context: AppSettingsContextType = appSettings
    ? {
        supportedVersion: appSettings.supportedVersion,
        underMaintenance: appSettings.underMaintenance,
        featureFlags: appSettings.featureFlags,
      }
    : undefined;

  return (
    <AppSettingsContext.Provider value={context}>
      {children}
    </AppSettingsContext.Provider>
  );
};
