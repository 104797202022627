import { FC } from "react";
import { Badge, Box, Tooltip, Typography } from "@mui/material";
import { timestampToDateTimeString } from "common/dist/feat/date";
import { Homework } from "common/types/Homework";
import { ItemCard } from "../../../../../components/ItemCard";
import Icon from "../../../../../components/Icon";

interface HomeworkCardProps {
  homework: Homework;
  unseen?: boolean;
}

const HomeworkCard: FC<HomeworkCardProps> = ({ homework, unseen }) => {
  return (
    <ItemCard>
      <Box display="flex" flexDirection="row">
        <Typography variant="caption" pb={1}>
          {timestampToDateTimeString(homework.timestamp)}
          <Badge
            badgeContent=" "
            color="error"
            variant="dot"
            overlap="circular"
            invisible={!unseen}
            sx={{ ml: 1, mb: 1 }}
          ></Badge>
        </Typography>

        <Box flexGrow={1} />
        {homework.completed ? (
          <Tooltip title="Marked completed by user">
            <Icon variant="checkboxDone" size="small" />
          </Tooltip>
        ) : (
          <Tooltip title="Not yet completed">
            <Icon variant="checkboxEmpty" size="small" />
          </Tooltip>
        )}
        <Icon variant="externalLink" size="small" />
      </Box>
      {homework.title && (
        <Typography variant="subtitle2" whiteSpace="pre-line">
          {homework.title}
        </Typography>
      )}
      <Typography whiteSpace="pre-line">{homework.description}</Typography>
    </ItemCard>
  );
};

export default HomeworkCard;
