import { commonColors } from "common/dist/theme";

const colors = {
  ...commonColors,
};

export type Colors = keyof typeof colors;

const radius = {
  round10: "10px",
  round32: "32px",
};

export const THEME = {
  colors: colors,
  radius: radius,
};
