import {
  createContext,
  FC,
  useEffect,
  useState,
  PropsWithChildren,
} from "react";
import { ProfessionalAttributes } from "common/dist/types/Therapist";
import { Conversation } from "common/dist/types/Conversations";
import { Feedback, FeedbackTemplate } from "common/dist/types/Feedback";
import { HomeworkTemplate } from "common/dist/types/Homework";
import { useAuth } from "../hooks/useAuth";
import { subscribeToProfessionalAttributes } from "../services/user";
import { subscribeToConversations } from "../services/messages";
import {
  subscribeToFeedbacks,
  subscribeToTemplates,
} from "../services/feedback";
import { subscribeToPublicHomeworkTemplates } from "../services/homeworks";

export interface AppContextType {
  userAttributes: ProfessionalAttributes | undefined | null;
  conversations:
    | {
        conversation: Conversation;
        id: string;
      }[]
    | undefined;
  feedbacks: { feedback: Feedback; id: string }[] | undefined;
  templates: FeedbackTemplate[] | undefined;
  publicHomeworkTemplates: HomeworkTemplate[] | undefined;
}

export const AppContext = createContext<AppContextType | undefined>(undefined);

export const AppDataProvider: FC<PropsWithChildren> = ({ children }) => {
  const { userAuth } = useAuth();

  const [userAttributes, setUserAttributes] = useState<
    ProfessionalAttributes | undefined | null
  >(null);
  const [templates, setTemplates] = useState<FeedbackTemplate[]>();
  const [feedbacks, setFeedbacks] = useState<
    {
      feedback: Feedback;
      id: string;
    }[]
  >();
  const [conversations, setConversations] = useState<
    {
      conversation: Conversation;
      id: string;
    }[]
  >();
  const [publicHomeworkTemplates, setPublicHomeworkTemplates] =
    useState<HomeworkTemplate[]>();

  useEffect(
    () => subscribeToProfessionalAttributes(setUserAttributes, userAuth.uid),
    [setUserAttributes, userAuth.uid]
  );

  useEffect(
    () => subscribeToConversations(setConversations),
    [setConversations]
  );

  useEffect(() => subscribeToFeedbacks(setFeedbacks), [setFeedbacks]);

  useEffect(() => subscribeToTemplates(setTemplates), [setTemplates]);

  useEffect(
    () => subscribeToPublicHomeworkTemplates(setPublicHomeworkTemplates),
    []
  );

  const context: AppContextType = {
    userAttributes,
    conversations,
    feedbacks,
    templates,
    publicHomeworkTemplates,
  };

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};
