import { FC } from "react";
import Box from "@mui/material/Box";
import Icon from "./Icon";
import Button from "./Button";

interface AddRemoveButtonGroupProps {
  label: string;
  add: () => void;
  remove?: () => void;
}

const AddRemoveButtonGroup: FC<AddRemoveButtonGroupProps> = ({
  label,
  add,
  remove,
}) => (
  <Box display="flex" flexDirection="column">
    {remove && (
      <Box>
        <Button
          label={`Remove ${label}`}
          size="medium"
          variant="tertiary"
          onClick={remove}
          leftIcon={<Icon variant="circleMinus" />}
          aria-label={`Remove ${label}`}
        />
      </Box>
    )}
    <Button
      label={`Add ${label}`}
      size="medium"
      variant="tertiary"
      onClick={add}
      leftIcon={<Icon variant="circlePlus" />}
      aria-label={`Add ${label}`}
    />
  </Box>
);

export default AddRemoveButtonGroup;
