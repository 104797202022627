import { FC } from "react";
import { Box, Typography } from "@mui/material";
import { useAssignee } from "../../../../hooks/useAssignee";
import { useEntries } from "../../../../hooks/useEntries";

import Entry from "./EntryLog";

const Entries: FC = () => {
  const { assignee } = useAssignee();
  const entries = useEntries();

  return (
    <Box sx={{ flexGrow: 1, py: 3 }}>
      {assignee && entries && entries.entries ? (
        <Box display="flex" justifyContent="space-between">
          <Box
            component="ul"
            display="flex"
            flex={1}
            flexDirection="column"
            gap={2}
            p={0}
          >
            {entries.entries.map((e) => (
              <Entry key={e.id} entry={e} />
            ))}
          </Box>
        </Box>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </Box>
  );
};

export default Entries;
