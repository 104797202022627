import { SymptomV2 } from "common/dist/types/LogEntry";
import { FC } from "react";
import { Box, Typography } from "@mui/material";
import Chip from "../../../../../components/Chip";
import { styles } from "../styles";
import { THEME } from "../../../../../theme";

export const SymptomsTotal: FC<{
  symptoms: { symptom: SymptomV2; count: number }[];
}> = ({ symptoms }) => (
  <Box display="flex" flexDirection="column" mb={5}>
    <Typography sx={styles.statisticHeader}>symptoms total</Typography>
    {symptoms.map((s) => (
      <Box
        key={`symptom-${s.symptom.label}`}
        display="flex"
        flexDirection="row"
        mt={2}
        color={THEME.colors.grey600}
        alignItems="center"
      >
        <Typography sx={styles.statisticSmall} mr={1}>
          {s.count}
        </Typography>
        <Chip
          label={s.symptom.label.replace(/^\w/, (c) => c.toUpperCase())}
          size="small"
          variant="secondary"
        />
      </Box>
    ))}
  </Box>
);
