import { FC } from "react";
import Box from "@mui/material/Box";

import { useProfile } from "../../hooks/useProfile";
import PersonalInfo from "./PersonalInfo";
import ChangePwd from "./ChangePwd";
import Connections from "./Connections";
import ChangeEmail from "./ChangeEmail";
import { auth } from "../firebase";

import "./profile.css";

const Profile: FC = () => {
  const { profile, providers } = useProfile();
  const primaryEmail = auth.currentUser?.email ?? "";
  const emailVerified = auth.currentUser?.emailVerified ?? false;

  if (profile === undefined) {
    return null;
  }

  return (
    <>
      <div className="headerBox">
        <h1>{"Profile"}</h1>
      </div>
      <Box display="flex" flexDirection="column" gap={4} paddingY={3}>
        <PersonalInfo initProfile={profile} />
        <ChangeEmail
          primaryEmail={primaryEmail}
          emailVerified={emailVerified}
        />
        <ChangePwd providers={providers} />
        <Connections providers={providers} primaryEmail={profile.email ?? ""} />
      </Box>
    </>
  );
};

export default Profile;
